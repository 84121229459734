<template>
  <div>
    <v-row v-if="edit" no-gutters class="align-center">
      <v-col cols="12" lg="8">
        <div class="info_top my-5">
          Personnes à prévenir en cas d'incident ou d'accident.
        </div>
      </v-col>
      <!-- <v-col cols="12" lg="4" class="text-right">
        <v-btn @click="modalIsOpen = true" class="btn btn-primary my-5"
          >Ajouter une personne</v-btn
        >
      </v-col> -->
    </v-row>

    <v-row v-if="!mounted">
      <v-col>
        <v-skeleton-loader
          :loading="true"
          :boilerplate="false"
          type="image, actions"
          style="width: 100%;"
          class="w-full"
        />
      </v-col>
      <v-col>
        <v-skeleton-loader
          :loading="true"
          :boilerplate="false"
          type="image, actions"
          style="width: 100%;"
          class="w-full"
        />
      </v-col>
      <v-col>
        <v-skeleton-loader
          :loading="true"
          :boilerplate="false"
          type="image, actions"
          style="width: 100%;"
          class="w-full"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col
        v-for="(utilisateur, index) in utilisateurs"
        :key="index"
        cols="12"
        md="6"
        lg="4"
      >
        <UserCardPap
          :context="{ ...context, userPAP: utilisateur }"
          @onUpdated="onUpdated"
          @onDeleted="onDeleted"
          edit
        />
      </v-col>
    </v-row>

    <v-btn @click="modalIsOpen = true" class="btn btn-primary my-5"
      >Ajouter une personne</v-btn
    >
    <ModalEditUserPap
      v-if="modalIsOpen"
      :isOpen="modalIsOpen"
      :context="context"
      @onClose="modalIsOpen = !modalIsOpen"
      @onUpdated="onUpdated"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    UserCardPap: () =>
      import(/* cardUserPAP */ "@/components/Pap/PAP__Card.vue"),
    ModalEditUserPap: () =>
      import(
        /* modalEditUserPAP */ "@/components/utils/modals/Pap/PAP__Modal.vue"
      ),
  },

  data: () => ({
    utilisateurs: null,
    mounted: false,
    modalIsOpen: false,
  }),

  async created() {
    this.$emit("setTitle", "Personne à prévenir en cas d’accident");
    await this.setData();
    this.mounted = true;
  },

  methods: {
    ...mapActions("utilisateurs", ["getUtilisateursPAP"]),

    async setData() {
      this.mounted = false;
      this.utilisateurs = await this.getUtilisateursPAP(this.context);
      return;
    },

    async onUpdated(/*user*/) {
      await this.setData();
      this.modalIsOpen = false;
      this.mounted = true;
    },

    onDeleted(user) {
      let index = this.utilisateurs.indexOf(user);
      this.utilisateurs.splice(index, 1);
    },
  },

  props: {
    context: {
      type: Object,
    },

    edit: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.info_top {
  font-family: "Roboto";
  font-size: 16px !important;
  margin: 0;
}
</style>
