<template>
  <div v-if="item.UTI_Utilisateur.UTI_LicenceAssurances[0]">
    <v-chip
      class="mr-1"
      small
      outlined
      color="blue"
      v-for="(assu, index) in assurance"
      :key="index"
    >
      {{ assu.ASSU_AssuranceType.NomTypeAssurance }}
    </v-chip>
  </div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    assurance() {
      if (!this.item.UTI_Utilisateur.UTI_LicenceAssurances) return false;
      return this.item.UTI_Utilisateur.UTI_LicenceAssurances;
    },
  },

  props: {
    item: {
      type: Object,
    },

    context: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    lastOne: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
