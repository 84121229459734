var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',_vm._g({attrs:{"headerText":"Ajout de participants","is-open":_vm.isOpen,"btnValidationText":"Ajouter les participants selectionnés"},on:{"validate":function($event){return _vm.setNewParticipant()}},scopedSlots:_vm._u([{key:"modal-content",fn:function(){return [_c('div',{staticClass:"content-modal d-flex flex-column px-1"},[_c('div',{staticClass:"d-flex align-center my-4"},[_c('span',{staticClass:"text-subtitle",domProps:{"textContent":_vm._s(
            'Liste des utilisateurs avec une fonction dans la structure'
          )}}),_c('v-divider',{staticClass:"ml-4",attrs:{"color":"#dfe5f9"}})],1),_c('div',{staticClass:"table-container"},[_c('v-data-table',{staticClass:"id-fuse-table",attrs:{"headers":_vm.headers,"items":_vm.userSelectable,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
          var item = ref.item;
return [(item.UTI_Utilisateur)?_c('span',{domProps:{"textContent":_vm._s(
                ((item.UTI_Utilisateur.CT_Nom) + " " + (item.UTI_Utilisateur.CT_Prenom))
              )}}):_vm._e()]}},{key:"item.use",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('v-checkbox',{staticStyle:{"margin-top":"4px !important"},on:{"change":function (event) { return _vm.setUser(event, item); }}})],1)]}}])})],1)])]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }