<template>
  <div v-if="mounted">
    <v-row>
      <v-col cols="12">
        <h4>Rôles</h4>
      </v-col>
      <v-col cols="8">
        <selectInput
          v-model="currentRole"
          :items="allRoles"
          @onChange="changeRole"
          item_value="id"
          item_text="NomRole"
        />
      </v-col>
      <v-col cols="4" class="mt-3"
        ><a
          href="#"
          v-if="context.ID_Utilisateur !== user.UTI_Utilisateur.id"
          @click.prevent="removeRole"
          >Retirer le rôle</a
        ></v-col
      >

      <v-col cols="4" class="">
        <v-btn
          @click="submit"
          :disabled="!this.currentRole"
          rounded
          color="blue-dark text-white font-weight-bold"
          >Sauvegarder le rôle</v-btn
        >
      </v-col>
    </v-row>

    <v-row class="justify-content-end">
      <v-col cols="12">
        <h6>Donne accès aux modules suivants:</h6>
        <v-card outlined>
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item v-for="(item, index) in moduleAccess" :key="index">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.NomModule"
                    :selected="false"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import selectInput from '@/components/utils/select.vue';
import { mapActions } from 'vuex';

import {
  success_notification,
  error_notification,
} from '@/plugins/notifications';
import { send_email } from '@/plugins/utils';
import { bus } from '@/main';

export default {
  components: { selectInput },

  data: () => ({
    mounted: false,
    currentRole: null,
    curentRoleSelected: null,
    currentUserStructure: null,
    allRoles: [],
  }),

  async created() {
    this.moduleAccess = [];
    this.setData();
    this.mounted = true;
  },

  computed: {},

  methods: {
    ...mapActions('admin', [
      'getRolesList',
      'editStrucutreUtilisateurRole',
      'removeAllUserModule',
    ]),
    ...mapActions('structure', ['getStructureByID']),

    setModuleAccess() {
      if (!this.currentRole && this.currentRole.ADM_RoleType) return [];
      this.moduleAccess = this.currentRole.ADM_RoleModule.map((mod) => {
        return mod.STR_Module;
      });
    },

    async setData() {
      const roles = await this.getRolesList();
      const dataStructure = await this.getStructureByID(this.user.ID_Structure);

      if (this.user.ID_Role !== null) {
        this.currentRole = { ...this.user.ADM_RoleType };
        this.setModuleAccess();
      }

      this.currentUserStructure = dataStructure.LIST_StructureType?.id;

      if (this.idTypeStructure === 7) {
        this.allRoles = roles.filter(
          (role) => role.ID_TypeStructure === this.currentUserStructure
        );
      } else {
        this.allRoles = roles.filter(
          (role) =>
            role.ID_TypeStructure === this.idTypeStructure &&
            role.SlugRole !== 'club_president' &&
            role.SlugRole !== 'club_gestion_infos' &&
            role.SlugRole !== 'ct_gestion_infos' &&
            role.SlugRole !== 'ligue_president' &&
            role.SlugRole !== 'ligue_gestion_infos' &&
            role.SlugRole !== 'ea_gestion_infos'
        );
      }
      console.log('roleeeeee', this.allRoles);
    },

    async submit() {
      if (typeof this.currentRole == 'object')
        this.currentRole = this.currentRole.id;
      try {
        let payload = {
          role: {
            ID_Structure: this.context.ID_Structure,
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Role: this.currentRole,
            id: this.user.id,
          },
          user: {
            ID_Structure: this.user.ID_Structure,
            ID_Utilisateur: this.user.ID_Utilisateur,
          },
        };
        await this.editStrucutreUtilisateurRole(payload);

        //NOTIFICATION "Ajout d'un rôle à un utilisateur"
        await this.sendEmail(payload);

        success_notification(
          "L'opération a été effectuée avec succès, veuillez recharger la page pour voir les changements."
        );
        bus.$emit('onUserRoleModuleUpdated');
      } catch (e) {
        console.log(e);
      }
    },

    async sendEmail() {
      const payloadEmail = {
        User: this.user.ID_Utilisateur,
        Structure: this.user.STR_Structure.NomStructure,
        Context: 'userAddedAsFedeToStructure',
        SlugModule: 'gestion_des_utilisateurs',
        Email: this.user.UTI_Utilisateur.CT_Email,
        Role: this.curentRoleSelected,
      };

      try {
        // payload email
        let email = await send_email(payloadEmail);
        if (!email.ok) {
          error_notification("Une erreur est survenue lors de l'envoi du mail");
        }
      } catch (e) {
        console.log('error on email', e);
      }
      // ...
    },

    async removeRole() {
      if (!this.currentRole) return;
      if (typeof this.currentRole == 'object')
        this.currentRole = this.currentRole.id;
      try {
        let payload = {
          role: {
            ID_Structure: this.context.ID_Structure,
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Role: null,
            id: this.user.id,
          },
          user: {
            ID_Structure: this.user.ID_Structure,
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Role: this.user.ID_Role,
          },
        };

        await this.editStrucutreUtilisateurRole(payload);
        await this.removeAllUserModule(payload.user);
        success_notification(
          "L'opération a été effectuée avec succès, veuillez recharger la page pour voir les changements."
        );
        bus.$emit('onUserRoleModuleUpdated');
        this.currentRole = null;
        this.moduleAccess = [];
      } catch (e) {
        console.log(e);
      }
    },

    changeRole(item_id) {
      this.currentRole = this.allRoles.find((role) => role.id == item_id);
      this.curentRoleSelected = this.currentRole.NomRole;
      this.setModuleAccess();
    },
  },

  props: {
    user: {
      type: Object,
    },

    context: {
      type: Object,
    },
    idTypeStructure: {
      type: Number,
    },
  },
};
</script>
