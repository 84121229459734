var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('LoadingIndicator',{attrs:{"loading":!_vm.mounted,"progress":false}}),(_vm.modalIsOpen && _vm.mounted && _vm.user)?_c('modalUserDetail',{attrs:{"modalIsOpen":_vm.modalIsOpen,"context":{
      ID_Utilisateur: _vm.currentUserId,
      ID_UtilisateurDemande: _vm.currentAuthUserId,
      ID_Structure: _vm.currentStructureId,
      ID_Saison: _vm.selectedSaison.id,
      hasCodeFederal: _vm.hasCodeFederal,
      user: _vm.user,
      fromModal: true
     },"user":_vm.user,"areNextPrevDisabled":true,"showFusion":true},on:{"onClose":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }