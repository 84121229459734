²<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="600px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          Donner accès à un module
        </span>
        <v-icon
          class="close-icon"
          style="margin-top:-23px !important; margin-left:auto "
          @click="closeModal"
          large
          >mdi-close</v-icon
        >
      </v-tab>
    </v-tabs>

    <div class="modal__content" style="overflow: hidden" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="px-6 py-10">
          <div>
            <inputSelect
              v-model="currentModule"
              :items="differenceModule"
              item_text="NomModule"
              item_value="id"
              label="Choisir un module"
              @onChange="moduleSelected"
            ></inputSelect>
          </div>

          <v-card-actions class="px-10 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeModal"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Annuler</v-btn
            >
            <v-btn
              @click="submit"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Valider</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { success_notification } from "@/plugins/notifications";
import inputSelect from "@/components/utils/select.vue";
import { bus } from "@/main";

export default {
  components: { inputSelect },

  data: () => ({
    loading: false,
    mounted: false,
    allModules: [],
    currentModule: null,
    structure: null,
  }),

  async created() {
    this.allModules = this.getModules;
    this.mounted = true;
  },

  computed: {
    ...mapGetters("modules", ["getModules"]),
    differenceModule() {
      return this.allModules.filter((mod) => {
        let isFiltered = true;
        this.user.ADM_RoleType.ADM_RoleModule.forEach((ownedMod) => {
          if (mod.id === ownedMod.ID_Module) isFiltered = false;
        });
        return isFiltered;
      });
    },
  },

  methods: {
    ...mapActions("admin", ["addModuleToUser"]),

    closeModal() {
      this.$emit("onClose");
    },

    moduleSelected() {
      // console.log(module_id)
    },

    async submit() {
      let selectedModule = this.allModules.find(
        (mod) => mod.id == this.currentModule
      );
      try {
        let payload = {
          module: {
            ID_Module: selectedModule.id,
            ID_Structure: this.user.ID_Structure,
            EST_Actif: true,
            ID_Utilisateur: this.user.ID_Utilisateur,
            Actions: "*",
          },
          user: {
            ID_Structure: this.user.ID_Structure,
            ID_Role: this.user.ID_Role,
            ID_Utilisateur: this.user.ID_Utilisateur,
          },
        };

        await this.addModuleToUser(payload);
        success_notification("Module ajouté");
        bus.$emit("onUserRoleModuleUpdated");
      } catch (e) {
        console.log(e);
      }
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
    },

    context: {
      type: Object,
    },
  },
};
</script>
