<template>
  <div>
    <div class="px-6" style="background: #f0f0f0 ">
      <ValidateForm
        @validated="submit"
        :showFieldObl="false"
        :key="refreshForm"
      >
        <v-row>
          <v-col cols="4" lg="3" style="padding-top: 0; padding-bottom: 0">
            <inputText
              v-model="search.CT_Nom"
              rules="min:2"
              placeholder="Nom"
            ></inputText>
          </v-col>

          <v-col cols="4" lg="3" style="padding-top: 0; padding-bottom: 0">
            <inputText
              v-model="search.CT_Prenom"
              rules="min:2"
              placeholder="Prénom"
            ></inputText>
          </v-col>

          <v-col cols="4" lg="3" style="padding-top: 0; padding-bottom: 0">
            <inputText
              v-model="search.CT_Email"
              rules="min:3"
              placeholder="Email"
            ></inputText>
          </v-col>

          <v-col
            cols="12"
            class="pb-3"
            style="padding-top: 0; padding-bottom: 0"
          >
            <v-btn
              class="btn btn-primary"
              type="submit"
              :disabled="disabledSearch || !hasCodeFederal"
              >Rechercher</v-btn
            >
          </v-col>
        </v-row>
      </ValidateForm>
    </div>

    <v-row no-gutters="">
      <v-col>
        <userList
          :context="context"
          :headers="headers"
          :actions="actions"
          :items="items"
          hasItems
          @hasToReload="reloadQuery"
          :show_select="show_select"
          isModule="MembresFederaux"
          ref="userList"
          :key="userListKey"
        ></userList>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import inputText from "@/components/utils/input";
// import DatePicker from "@/components/utils/datePicker"
import userList from "@/components/User/List/User__List.vue";
import { mapGetters, mapActions } from "vuex";
import ValidateForm from "@/components/utils/form";
import { bus } from "@/main";
import { sleep } from "@/plugins/utils";
// import RadioGroup from "@/components/utils/radioGroup.vue";

export default {
  components: { inputText, userList, ValidateForm },

  data: () => ({
    search: {},
    headers: [
      // { text: "Structure", value: "NomStructure" },
      { text: "Prénom", value: "CT_Prenom" },
      { text: "Nom", value: "CT_Nom" },
      { text: "Role", value: "ID_Role" },
      { text: "Actions", value: "actions" },
    ],
    items: [],
    userListKey: 0,
    loading: false,
    refreshForm: 0,
  }),

  async created() {
    let type = {};

    //Only active users on the structure
    bus.$on("onUserListStructureUpdated", async () => {
      // console.log("list updated");
      //return await this.setData();
      this.$emit("hasToReload");
    });

    bus.$on("reloadListStrUti", async () => {
      //return await this.setData();
      this.reloadQuery();
    });
    await sleep(200);
    await this.submit();
    return;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureType",
      "currentStructure",
      "hasCodeFederal",
    ]),

    disabledSearch() {
      if (this.search.CT_Nom) {
        return false;
      }
      if (this.search.CT_Prenom) {
        return false;
      }
      if (this.search.CT_Telephone) {
        return false;
      }
      if (this.search.CT_Email) {
        return false;
      }
      if (this.search.LicenceNumero) {
        return false;
      }
      if (this.search.DateNaissanceStart) {
        return false;
      }
      if (this.search.DateNaissanceEnd) {
        return false;
      }

      return true;
    },

    selectStrListIsDisabled() {
      if (this.structuresList.length == 1) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["searchUtlisateur"]),
    ...mapActions("structure", ["getStructureList"]),

    erraseFilter() {
      this.search = {};
      this.items = [];
      this.userListKey += 1;

      this.submit();

      //console.log(this.$refs.userList.setData)
    },

    UpdateDateEnd(date) {
      this.search.DateNaissanceEnd = date;
      this.refreshForm++;
    },

    UpdateDateStart(date) {
      console.log("date", date);
      this.search.DateNaissanceStart = date;
      this.refreshForm++;
    },

    async submit() {
      this.$refs.userList.loading = true;
      let response = await this.searchUtlisateur({
        where: this.getWhereClause(),
        // ID_Saison: this.context.ID_Saison
      });

      this.items = response.sort((a, b) => {
        return b.ADM_RoleType ? 1 : -1;
      });
      this.userListKey += 1;
      this.$refs.userList.loading = false;
      return;
    },

    async reloadQuery() {
      //console.log('here')
      await sleep(1000);
      await this.submit();
    },

    getWhereClause() {
      let arrayClause = [];

      //Only active users on the structure
      // arrayClause.push({ EST_Actif: { _eq: true } } )

      // Get the good wrap of user
      // if(this.currentStructureType.SlugTypeStructure == "ligue") {
      //     arrayClause.push({ STR_Structure: {ID_Ligue: { _eq: this.context.ID_Structure } } } )
      // }

      // else if(this.currentStructureType.SlugTypeStructure == "comite_territorial") {
      //     arrayClause.push({ STR_Structure: {ID_ComiteTerritorial: { _eq: this.context.ID_Structure } } } )
      // }

      // else if(this.currentStructureType.SlugTypeStructure == "service_federal_ffme") {
      //     arrayClause.push({ STR_Structure: {ID_Federation: { _eq: this.context.ID_Structure } } } )
      // }

      arrayClause.push({ ID_Structure: { _eq: this.context.ID_Structure } });

      // ENd -- Get the good wrap of user

      // Setup the field restriction
      arrayClause.push({ EST_RoleFederal: { _eq: true } });

      if (this.search.CT_Nom) {
        arrayClause.push({
          UTI_Utilisateur: { CT_Nom: { _ilike: this.search.CT_Nom + "%" } },
        });
      }

      if (this.search.CT_Prenom) {
        arrayClause.push({
          UTI_Utilisateur: {
            CT_Prenom: { _ilike: this.search.CT_Prenom + "%" },
          },
        });
      }

      if (this.search.DateNaissanceStart) {
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: {
              _gt: "%" + this.search.DateNaissanceStart + "%",
            },
          },
        });
      }

      if (this.search.DateNaissanceEnd) {
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: { _lt: "%" + this.search.DateNaissanceEnd + "%" },
          },
        });
      }

      if (this.search.CT_Telephone) {
        let phoneClause = {
          _or: [
            {
              UTI_Utilisateur: {
                CT_TelephoneFixe: {
                  _ilike: "%" + this.search.CT_Telephone + "%",
                },
              },
            },
            {
              UTI_Utilisateur: {
                CT_TelephoneMobile: {
                  _ilike: "%" + this.search.CT_Telephone + "%",
                },
              },
            },
          ],
        };
        arrayClause.push(phoneClause);
      }

      if (this.search.CT_Email) {
        let emailClause = {
          _or: [
            {
              UTI_Utilisateur: {
                CT_Email: { _ilike: "%" + this.search.CT_Email + "%" },
              },
            },
            {
              UTI_Utilisateur: {
                CT_Email2: { _ilike: "%" + this.search.CT_Email + "%" },
              },
            },
          ],
        };
        arrayClause.push(emailClause);
      }

      if (this.search.LicenceNumero) {
        arrayClause.push({
          UTI_Utilisateur: {
            LicenceNumero: { _eq: this.search.LicenceNumero },
          },
        });
      }

      let where = {
        _and: arrayClause,
      };
      // End --  Setup the field restriction
      console.log(where);
      return where;
    },
  },

  props: {
    context: {
      type: Object,
    },
    actions: {
      type: String,
      default: "show",
    },
    show_select: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss" scoped>
.v-input--switch {
  padding: 0;
}
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
    height: 29px;
  }

  // CHIPS
  .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
    padding-left: 10px !important;
  }
  input[type="text"]:disabled {
    background: transparent !important;
  }
  .v-text-field__details {
    display: none;
  }
}
</style>
