<template>
  <div>
    <div style="background: #f0f0f0;">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>
            <div class="d-flex align-center">
              <span class="diffusion-item" v-if="panel === 0">
                <v-icon large color="#082B6E"> mdi-chevron-up </v-icon>Cacher
                les filtres</span
              >
              <span class="diffusion-item" v-else>
                <v-icon large color="#082B6E"> mdi-chevron-down </v-icon
                >Afficher les filtres</span
              >
              <!-- <span
                class="diffusion-item ml-auto"
                @click.stop="idFuseList()"
                :class="{ isActive: !isIDFuse }"
              >
                [ Gérer les listes de diffusions ]
              </span> -->
              <span
                style="height: 35px;"
                class="diffusion-item ml-auto"
                :class="{ isActive: isIDFuse }"
                @click.stop="goToMemberGestion()"
              >
                [ Gérer les adhérents ]
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f0f0f0" @keydown.enter="test">
            <ValidateForm
              @validated="submit"
              :showFieldObl="false"
              :key="refreshForm"
              v-on="$listeners"
            >
              <div class="d-flex align-center m-2" style="width: 100%;">
                <div class="filter-item">
                  <inputText
                    v-model="search.CT_Nom"
                    rules="min:2"
                    placeholder="Nom"
                  ></inputText>
                </div>
                <div class="filter-item">
                  <inputText
                    v-model="search.CT_Prenom"
                    rules="min:2"
                    placeholder="Prénom"
                  ></inputText>
                </div>
                <div class="filter-item">
                  <inputText
                    v-model="search.CT_Email"
                    rules="min:3"
                    placeholder="Email"
                  ></inputText>
                </div>

                <inputText
                  v-model="search.LicenceNumero"
                  rules="min:3"
                  type="number"
                  placeholder="N° Licence"
                  class="input-id-fuse"
                ></inputText>

                <div class="filter-item3">
                  <v-autocomplete
                    class="ffme-form__select"
                    v-model="search.structureSelected"
                    :items="structuresList"
                    item-text="NomStructure"
                    item-value="id"
                    dense
                    label="Structures"
                    solo
                    :disabled="selectStrListIsDisabled"
                    :loading="strucutreListLoading"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content style="padding: 0;">
                        <v-list-item-title class="d-flex align-center">
                          {{ item.NomStructure }}
                          <span v-if="item.ID_CodeFederal">
                            - {{ item.ID_CodeFederal }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
                <div class="filter-item3">
                  <DatePicker
                    v-model="search.DateNaissanceStart"
                    rules="min:3"
                    :maxToday="true"
                    placeholder="Date de naissance du"
                  ></DatePicker>
                </div>
                <div class="filter-item3">
                  <DatePicker
                    v-model="search.DateNaissanceEnd"
                    rules="min:3"
                    :maxToday="true"
                    placeholder="Au"
                  ></DatePicker>
                </div>

                <div class="filter-item3 position-relative">
                  <label
                    for=""
                    style="
                      font-size: 10px;
                      margin-bottom: 0;
                      position: absolute;
                      top: -2px;
                    "
                    >Saison</label
                  >
                  <InputSelect
                    v-model="SelectedSaison"
                    :items="saisonList"
                    item_text="title"
                    item_value="value"
                    key="Saison"
                    placeholder="Saison"
                    @input="saisonSelected = true"
                  />
                </div>
              </div>
              <div class="d-flex align-center">
                <!-- filter when id fuse -->
                <div
                  v-if="isIDFuse"
                  class="position-relative input-id-fuse ml-2"
                >
                  <label
                    for=""
                    style="
                      font-size: 10px;
                      margin-bottom: 0;
                      position: absolute;
                      top: -2px;
                    "
                    >Sexe</label
                  >
                  <InputSelect
                    v-model="civiliteSelected"
                    :items="listCivilite"
                    item_text="text"
                    item_value="value"
                    placeholder="sexe"
                    class="mt-1"
                  />
                </div>

                <inputText
                  v-if="isIDFuse"
                  v-model="search.CodePostal"
                  placeholder="Code postal"
                  class="input-id-fuse"
                ></inputText>
                <inputText
                  v-if="isIDFuse"
                  v-model="search.ageMin"
                  type="number"
                  placeholder="Age min"
                  class="input-id-fuse"
                ></inputText>
                <inputText
                  v-if="isIDFuse"
                  v-model="search.ageMax"
                  type="number"
                  placeholder="Age max"
                  class="input-id-fuse"
                ></inputText>

                <div class="d-flex align-center ml-2">
                  <v-switch
                    style="margin-top: 9px;"
                    v-model="search.EST_RoleFederal"
                  ></v-switch>
                  <span class="switch-label" v-text="'Accès espace fédéral'" />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-2"
                        aria-hidden="false"
                        color="#082B6E"
                        v-bind="attrs"
                        v-on="on"
                      >
                        help
                      </v-icon>
                    </template>
                    <span
                      >Filtrer les utilisateurs attachés à une structure et
                      ayant des droits d'accès à l'espace fédéral</span
                    >
                  </v-tooltip>
                </div>
                <div
                  v-if="!isClub && hasCodeFederal"
                  class="d-flex align-center ml-4"
                >
                  <v-switch v-model="search.EST_LicenceSiege"></v-switch>
                  <span class="switch-label mt-1" v-text="'Licence siège'" />
                </div>
              </div>
              <div
                class="d-flex align-center pt-2"
                style="position: relative !important;"
              >
                <label
                  v-if="userToModifyCM.length > 0"
                  style="
                    font-size: 10px;
                    margin-bottom: 0;
                    position: absolute;
                    top: 2px;
                    left: 8px;
                  "
                  v-text="`Séléctionné : ${userToModifyCM.length}/10`"
                />
                <InputSelect
                  v-if="!isIDFuse && !this.actionsContains('editLicenceMultiple')"
                  v-model="selectedAction"
                  :items="possibleActions"
                  item_text="text"
                  item_value="value"
                  placeholder="Action multiples"
                  @input="activateActionMultiple"
                  :disabled="isActionMultipleDisabled"
                  class="select-item1 ml-2"
                />
                <!-- </div> -->

                <InputSelect
                  v-if="isIDFuse"
                  v-model="selectedListe"
                  :items="listeStructureAndCategorie"
                  item_text="structureCategorieName"
                  item_value="id"
                  placeholder="Sélectionner une liste"
                  class="select-item ml-2"
                  :loading="isFetchingStructureListe"
                />
                <Button
                  :color="'#082B6E'"
                  class="btn-validation mx-5"
                  :class="{ disabled: disabledSearch }"
                  @click="disabledSearch ? null : submit"
                  rounded
                  height="35"
                  type="primary"
                >
                  Rechercher
                </Button>
                <Button
                  :color="'#082B6E'"
                  class="btn-validation"
                  @click="erraseFilter"
                  rounded
                  outlined
                  height="35"
                  type="primary"
                >
                  Effacer les filtres
                </Button>

                <div class="d-flex align-center ml-auto">
                  <Button
                    v-if="isIDFuse"
                    :color="'#082B6E'"
                    class="btn-validation mr-5"
                    @click="openCreateModalList"
                    rounded
                    height="35"
                    type="primary"
                  >
                    Sauvegarder la liste
                  </Button>
                  <Button
                    :color="'#082B6E'"
                    class="btn-validation"
                    :class="{
                      disabled: items.length < 1,
                    }"
                    @click="exportResult"
                    rounded
                    height="35"
                    type="primary"
                    :loading="isExporting"
                  >
                    Exporter la liste
                  </Button>
                </div>

                <!-- <JsonCSV class= "d-none" ref="csvBtn" :data="json_data" name="filename.csv" v-if="context.hasCodeFederal">Exporter</JsonCSV> -->
                <JsonExcel
                  class="d-none"
                  ref="csvBtn"
                  :data="json_data"
                  :fields="json_fields"
                  name="filename.xls"
                  v-if="context.hasCodeFederal"
                  >Exporter</JsonExcel
                >
              </div>
            </ValidateForm>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <v-row no-gutters="">
        <v-col>
          <userList
            :areFiltersOpened="panel === 0"
            :saisonInput="SelectedSaison"
            :context="context"
            :headers="headers"
            :disable-sort="false"
            :actions="actions"
            :panel="panel"
            :items="items"
            hasItems
            @hasToReload="reloadQuery"
            :show_select="show_select"
            ref="userList"
            :key="`list-${userListKey}`"
            @updateCM="updateUserToCM"
            @banUser="deSelectUser"
            :user-list-selected="userToModifyCM"
          ></userList>
        </v-col>
      </v-row>
    </div>
    <UserMultipleValidationCertificatMedicalModal
      v-if="openMultipleCertificatMedicalModal"
      :isOpen="openMultipleCertificatMedicalModal"
      :context="context"
      :user-list="userToModifyCM"
      @close="closeModal"
    />

    <!-- modal id fuse -->
    <IdFuseEditCreateListModal
      v-if="isEditListModalOpen"
      :is-open="isEditListModalOpen"
      :is-edited="isEditingList"
      create-with-list
      @close="
        {
          isEditListModalOpen = false;
          isEditingList = false;
          deSelectedUser = [];
        }
      "
      @open-participant-list="isParticipantListModalOpen = true"
    />
    <IdFuseParticipantListModal
      v-if="isParticipantListModalOpen"
      :is-open="isParticipantListModalOpen"
      @close="isParticipantListModalOpen = false"
    />
  </div>
</template>

<script>
import inputText from "@/components/utils/input";
import InputSelect from "@/components/utils/select.vue";
import userList from "@/components/User/List/User__List.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications.js";
import ValidateForm from "@/components/utils/form";
import { bus } from "@/main";
import { sleep } from "@/plugins/utils";
import JsonExcel from "vue-json-excel";
import { listDeCivilite } from "@/plugins/utils";
import UserMultipleValidationCertificatMedicalModal from "./UserMultipleValidationCertificatMedicalModal.vue";
import IdFuseEditCreateListModal from "../../../modules/GestionDeLaStructure/IdFuseEmailing/modals/IdFuseEditCreateListModal.vue";
import IdFuseParticipantListModal from "../../../modules/GestionDeLaStructure/IdFuseEmailing/modals/IdFuseParticipantListModal.vue";
import Button from "../../utils/button.vue";
import { validate } from "@/constants/doc_status";

export default {
  components: {
    InputSelect,
    inputText,
    userList,
    ValidateForm,
    JsonExcel,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    UserMultipleValidationCertificatMedicalModal,
    IdFuseEditCreateListModal,
    IdFuseParticipantListModal,
    Button,
  },

  data: () => ({
    search: {},
    openMultipleCertificatMedicalModal: false,
    userToModifyCM: [],
    saisonSelected: false,
    SelectedSaison: null,
    selectedAction: null,
    isExporting: false,
    possibleActions: [
      { text: "Valider les questionnaires santé", value: 1 },
      { text: "Téléverser des certificats médicaux", value: 2 },
    ],
    items: [],
    userListKey: 0,
    loading: false,
    structuresList: [],
    strucutreListLoading: true,
    refreshForm: 0,
    json_data: [],
    listCivilite: [],
    civiliteSelected: null,
    json_fields: {
      Nom: "lastname",
      Prénom: "firstname",
      "Date de naissance": "birthday",
      Civilité: "sex",
      "N° de licence": "num_licence",
      "Nom de la structure": "structure_name",
      Adresse: "adr",
      "Adresse complément": "adr_completement",
      "Code postal": "adr_cedex",
      Ville: "ville",
      Pays: "pays",
      Nationalité: "nationality",
      Email: "email",
      "Tel portable": "portable",
      "Tel Fixe": "fix",
      "Date de création licence": "licence_creation",
      "Type de licence": "licence_type",
      "Type Assurance": "assurance_type",
      "Options d'assurance": "options",
      "Type de certificat médical": "cm_type",
      "Date de fin de validité certificat": "cm_end",
      "PAP nom": "pap_lastname",
      "PAP prénom": "pap_firstname",
      "PAP email": "pap_email",
      "PAP mobile": "pap_mobile",
      "Dernière Saison (année)": "last_season",
      // "Fonction": "function",
      "Référence Club": "reference_club",
    },
    panel: 0,
    filterSaison: 2,
    idRoleAdmin: 1,

    // id fuse data
    isIDFuse: false,
    selectedListe: null,
    isFetchingStructureListe: false,
    isEditListModalOpen: false,
    isParticipantListModalOpen: false,
    isEditingList: false,
    deSelectedUser: [],
  }),

  async created() {
    if (this.$route.params.IDListe != null) {
      this.idFuseList();
    }
    this.SelectedSaison = this.actionsContains("editLicenceMultiple")
      ? this.currentSaison.id - 1
      : this.currentSaison.id;

    this.listCivilite = listDeCivilite();
    let type = {};
    //Only active users on the structure
    bus.$on("onUserListStructureUpdated", async () => {
      this.$emit("hasToReload");
    });

    bus.$on("reloadListStrUti", async () => {
      this.reloadQuery();
    });

    // Get the good wrap of user
    if (this.currentStructureType.SlugTypeStructure == "ligue") {
      type.ID_Ligue = { _eq: this.context.ID_Structure };
    }
    if (this.currentStructureType.SlugTypeStructure == "comite_territorial") {
      type.ID_ComiteTerritorial = { _eq: this.context.ID_Structure };
    }
    if (this.currentStructureType.SlugTypeStructure == "service_federal_ffme") {
      type.ID_Federation = { _eq: this.context.ID_Structure };
    } else {
      type.id = { _eq: this.context.ID_Structure };
    }
    this.structuresList = await this.getStructureList({ whereClause: type });
    this.strucutreListLoading = false;

    if (this.structuresList.length == 1) {
      this.search.structureSelected = this.structuresList[0].id;
      await this.submit();
    }
    if (this.$route.query.id_utilisateur) {
      let urlUser = this.items.find(
        (user) => user.ID_Utilisateur === this.$route.query.id_utilisateur
      );
      if (urlUser) this.items = [urlUser];
      else this.items = [];
    }
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureType",
      "currentStructure",
      "hasCodeFederal",
    ]),
    ...mapGetters("user", ["currentUser", "getRole"]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapState({
      structureCategorieList: (state) => state.structure.structureCategorieList,
      structureListes: (state) => state.structure.structureList,
    }),
    headers() {
      if (this.isIDFuse) {
        return [
          { text: "", value: "Select", sort: false },
          { text: "Numéro de licence", value: "LicenceNumero" },
          { text: "Nom", value: "CT_Nom" },
          { text: "Prénom", value: "CT_Prenom" },
          { text: "Structure", value: "NomStructure" },

          { text: "Date de naissance", value: "DN_DateNaissance" },
          { text: "Licence type", value: "ID_LicenceType", align: "left" },
        ];
      }
      return [
        this.show_select ? null : { text: "", value: "Select", sortable: false },
        { text: "Numéro de licence", value: "LicenceNumero", sortable: true },
        { text: "Nom", value: "CT_Nom", sortable: true },
        { text: "Prénom", value: "CT_Prenom", sortable: true },
        { text: "Structure", value: "NomStructure", sortable: true },

        {
          text: "Date de naissance",
          value: "DN_DateNaissance",
          sort: (a, b) => {
            const S = a.split("/");
            const B = b.split("/");
            return new Date(S[2], S[1], S[0]) - new Date(B[2], B[1], B[0]);
          },
        },
        { text: "Licence type", value: "ID_LicenceType", sortable: true },
        { text: "Actions", value: "actions", align: "left", sortable: false },
      ].filter(item => item !== null);
    },
    isActionMultipleDisabled() {
      if (this.userToModifyCM.length < 1) return true;
      return false;
    },
    listeStructureAndCategorie() {
      if (this.isFetchingStructureListe) return [];
      return this.structureListes.map((liste) => ({
        ...liste,
        structureCategorieName: `${liste.STR_StructureListeCategorie?.Nom_ListeCategorie} / ${liste.NomListe}`,
      }));
    },
    saisonList() {
      let saisons = [{ title: "Aucun filtre", value: 2 }];
      for (
        let saison = this.actionsContains("editLicenceMultiple")
          ? this.currentSaison.id - 1
          : this.currentSaison.id;
        saison > +this.currentSaison.id - 3;
        saison--
      ) {
        saisons = [...saisons, { title: `${saison}`, value: saison }];
      }
      return [...saisons, { title: `<${this.currentSaison.id - 2}`, value: 1 }];
    },
    isClub() {
      return this.currentStructureType.EST_Club;
    },

    isAdmin() {
      if (this.getRole?.id === this.idRoleAdmin) {
        return true;
      }
      return false;
    },

    disabledSearch() {
      if (
        this.saisonSelected ||
        this.search.CT_Nom ||
        this.search.CT_Prenom ||
        this.search.CT_Telephone ||
        this.search.CT_Email ||
        this.search.LicenceNumero ||
        this.search.structureSelected ||
        this.search.ID_CodeFederal ||
        this.search.DateNaissanceStart ||
        this.search.DateNaissanceEnd ||
        this.search.EST_RoleFederal ||
        this.search.EST_LicenceSiege
      ) {
        return false;
      }

      return true;
    },

    isSearchByOther() {
      if (
        this.search.CT_Email ||
        this.search.LicenceNumero ||
        this.search.DateNaissanceStart ||
        this.search.DateNaissanceEnd ||
        this.search.EST_RoleFederal ||
        this.search.structureSelected
      ) {
        return true;
      }

      return false;
    },

    isSearchByName() {
      if (this.search.CT_Nom || this.search.CT_Prenom) {
        return true;
      }
      return false;
    },
    selectStrListIsDisabled() {
      if (this.structuresList.length == 1) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("utilisateurs", [
      "searchUtlisateur",
      "exportUtilisateurStructure",
      "searchUserByName",
      "exportUtilisateurStructureByName",
      "searchUserByFirstName",
      "searchUserByNameAll",
      "searchUserByFirstNameAll",
      "exportUtilisateurStructureByFirstName",
      "searchUserByNameAndFirstName",
      "exportUtilisateurStructureByNameAndFirstName",
      "searchUserByNameAndFirstNameAll",
    ]),
    ...mapActions("basics", ["insertDocument", "updateDocumentStatus"]),
    ...mapActions("structure", [
      "getStructureList",
      "getStructureListCategorie",
      "getStructureListeBySeason",
    ]),
    test() {
      console.log("object456 :>> ");
    },

    closeModal() {
      this.$store.commit("structure/SET_INITIAL_CHECKBOX", false);
      setTimeout(() => {
        this.$store.commit("structure/SET_INITIAL_CHECKBOX", true);
      }, 250);
      this.userToModifyCM = [];
      this.openMultipleCertificatMedicalModal = false;
      this.selectedAction = null;
    },
    updateUserToCM(item) {
      if (this.userToModifyCM.some((user) => user.id === item.id)) {
        return this.userToModifyCM.splice(
          this.userToModifyCM.findIndex((user) => user.id === item.id),
          1
        );
      }
      return this.userToModifyCM.push(item);
    },
    deSelectUser(item) {
      if (this.deSelectedUser.some((user) => user.id === item.id)) {
        return this.deSelectedUser.splice(
          this.deSelectedUser.findIndex((user) => user.id === item.id),
          1
        );
      }
      return this.deSelectedUser.push(item);
    },
    activateActionMultiple(event) {
      if (event === 2) {
        return (this.openMultipleCertificatMedicalModal = true);
      } else if (event === 1) return this.validateMultipleQS();
    },
    getLastDateOfSeason() {
      var today = new Date();
      var lastDayOfMonth = new Date(today.getFullYear(), 8, 0);
      return lastDayOfMonth;
    },
    async validateMultipleQS() {
      let qsPromise = [];
      this.userToModifyCM.forEach((user) => {
        return qsPromise.push(this.validateQS(user));
      });
      await Promise.all(qsPromise).then(() => {
        this.selectedAction = null;
        success_notification("Questionnaires santé validés");
      });
    },
    async validateQS(data) {
      let payload = {
        CheminDocument: "",
        NomDocument: "Gestion des certificats médicaux / Questionnaire santé",
        ID_Type_Document: 60,
        ID_Structure: data.ID_Structure,
        ID_Utilisateur: data.ID_Utilisateur,
        ID_News: null,
        DateFinValiditeDocument: new Date(`08/31/${this.currentSaison.id}`),
        DateValidation: this.$moment(new Date()).toISOString(),
        EST_Actif: true,
        EST_DocumentValide: true,
        Categorie: "Questionnaire santé",
        ID_Saison: this.currentSaison.id,
        status: validate,
      };
      try {
        let doc = await this.insertDocument(payload);
        await this.updateDocumentStatus({id: doc.id, status: validate});
      } catch (error) {
        error_notification("Oups, une erreur est survenue!");
      }
    },
    actionsContains(act) {
      let actions = this.actions.split("|");
      return actions.find((action) => action == act);
    },
    erraseFilter() {
      this.search = {};
      this.civiliteSelected = null;
      this.search.DateNaissanceStart = "";
      this.search.DateNaissanceEnd = "";
      this.UpdateDateEnd(this.search.DateNaissanceEnd);
      this.UpdateDateStart(this.search.DateNaissanceStart);
      this.items = [];
      this.userListKey += 1;
      this.SelectedSaison = this.currentSaison.id;
      if (this.structuresList.length == 1) {
        this.search.structureSelected = this.structuresList[0].id;
        this.submit();
      }
      this.userToModifyCM = [];
      this.selectedListe = null;
      this.deSelectedUser = [];
    },

    UpdateDateEnd(date) {
      this.search.DateNaissanceEnd = date;
      this.refreshForm++;
    },

    UpdateDateStart(date) {
      this.search.DateNaissanceStart = date;
      this.refreshForm++;
    },

    async submit() {
      let userList = [];
      let response = [];
      this.$refs.userList.loading = true;
      if (
        this.search.CT_Nom !== undefined &&
        this.search.CT_Prenom === undefined
      ) {
        let saisonId = null;
        if (this.SelectedSaison !== this.filterSaison) {
          saisonId = this.SelectedSaison !== 1 ? this.SelectedSaison : 2019;
        }
        let payload = {
          CT_Name: this.search.CT_Nom,
          saisonId: saisonId,
          structureId: this.isAdmin ? null : this.context.ID_Structure,
        };
        userList = await this.searchUserByName(payload);
        userList?.forEach((data, i) => {
          let user = data.UTI_Utilisateur;
          if (
            user &&
            user.LicenceNumero &&
            user.LicenceNumero.toString().length < 6
          ) {
            let toPrepend = 6 - user.LicenceNumero.toString().length;
            for (let i = 0; i < toPrepend; i++) {
              user.LicenceNumero = "0" + user.LicenceNumero;
            }
          }
        });
      } else if (
        this.search.CT_Prenom !== undefined &&
        this.search.CT_Nom === undefined
      ) {
        let saisonId = null;
        if (this.SelectedSaison !== this.filterSaison) {
          saisonId = this.SelectedSaison !== 1 ? this.SelectedSaison : 2019;
        }
        let payload = {
          CT_Name: this.search.CT_Prenom,
          saisonId: saisonId,
          structureId: this.isAdmin ? null : this.context.ID_Structure,
        };
        userList = await this.searchUserByFirstName(payload);
        userList?.forEach((data, i) => {
          let user = data.UTI_Utilisateur;
          if (
            user &&
            user.LicenceNumero &&
            user.LicenceNumero.toString().length < 6
          ) {
            let toPrepend = 6 - user.LicenceNumero.toString().length;

            for (let i = 0; i < toPrepend; i++) {
              user.LicenceNumero = "0" + user.LicenceNumero;
            }
          }
        });
      } else if (
        this.search.CT_Prenom !== undefined &&
        this.search.CT_Nom !== undefined
      ) {
        let saisonId = null;
        if (this.SelectedSaison !== this.filterSaison) {
          saisonId = this.SelectedSaison !== 1 ? this.SelectedSaison : 2019;
        }
        let payload = {
          CT_Name: this.search.CT_Nom,
          CT_Firstname: this.search.CT_Prenom,
          saisonId: saisonId,
          structureId: this.isAdmin ? null : this.context.ID_Structure,
        };
        userList = await this.searchUserByNameAndFirstName(payload);
        userList?.forEach((data, i) => {
          let user = data.UTI_Utilisateur;
          if (
            user &&
            user.LicenceNumero &&
            user.LicenceNumero.toString().length < 6
          ) {
            let toPrepend = 6 - user.LicenceNumero.toString().length;

            for (let i = 0; i < toPrepend; i++) {
              user.LicenceNumero = "0" + user.LicenceNumero;
            }
          }
        });
      }
      if (
        this.SelectedSaison !== this.filterSaison ||
        this.isSearchByOther ||
        (!this.isSearchByOther &&
          this.SelectedSaison === this.filterSaison &&
          !this.isSearchByName) ||
        (this.isSearchByName &&
          this.isSearchByOther &&
          this.SelectedSaison === this.filterSaison)
      ) {
        response = await this.searchUtlisateur({
          where: this.getWhereClause(),
        });
        console.log("response :>> ", response);

        // preprend 0 to licences numbers if licence number < 100 000
        response?.forEach((data, i) => {
          let user = data.UTI_Utilisateur;
          if (
            user &&
            user.LicenceNumero &&
            user.LicenceNumero.toString().length < 6
          ) {
            let toPrepend = 6 - user.LicenceNumero.toString().length;

            for (let i = 0; i < toPrepend; i++) {
              user.LicenceNumero = "0" + user.LicenceNumero;
            }
          }
        });
      }
      if (
        (userList.length === 0 && response.length === 0) ||
        (this.isSearchByName &&
          this.isSearchByOther &&
          userList.length !== 0 &&
          response.length === 0) ||
        (this.isSearchByName && userList.length === 0 && response.length !== 0)
      ) {
        this.items = [...[]];
        this.userListKey += 1;
        this.$refs.userList.loading = false;
      } else if (
        this.isSearchByName &&
        response.length !== 0 &&
        userList.length !== 0
      ) {
        let userStructID;
        let TABLIST = [];
        let responsefilter = [];
        for (let i = 0; i < userList.length; i++) {
          userStructID = userList[i].id;
          TABLIST = response.filter((res) => res.id === userStructID);
          responsefilter.push(...TABLIST);
          this.items = [...new Set(responsefilter)];
          this.userListKey += 1;
          this.$refs.userList.loading = false;
        }
      } else if (
        (!this.isSearchByName &&
          userList.length === 0 &&
          response.length !== 0) ||
        (!this.isSearchByName &&
          this.isSearchByOther &&
          userList.length === 0 &&
          response.length !== 0)
      ) {
        let responsefilter = [...new Set(response)];
        this.items = responsefilter;
        this.userListKey += 1;
        this.$refs.userList.loading = false;
      } else if (
        (!this.isSearchByOther &&
          userList.length !== 0 &&
          response.length === 0) ||
        (this.isSearchByName &&
          !this.isSearchByOther &&
          userList.length !== 0 &&
          response.length === 0)
      ) {
        let responsefilter = [...new Set(userList)];
        this.items = responsefilter;
        this.userListKey += 1;
        this.$refs.userList.loading = false;
      }
      return;
    },

    async reloadQuery() {
      await sleep(1000);
      await this.submit();
    },

    getWhereClause(isExport) {
      let arrayClause = [];

      //Only active users on the structure
      arrayClause.push({ EST_Actif: { _eq: true } });

      // Get the good wrap of user
      if (this.currentStructureType.SlugTypeStructure == "ligue") {
        arrayClause.push({
          STR_Structure: { ID_Ligue: { _eq: this.context.ID_Structure } },
        });
      } else if (
        this.currentStructureType.SlugTypeStructure == "comite_territorial"
      ) {
        arrayClause.push({
          STR_Structure: {
            ID_ComiteTerritorial: { _eq: this.context.ID_Structure },
          },
        });
      } else if (
        this.currentStructureType.SlugTypeStructure == "service_federal_ffme"
      ) {
        arrayClause.push({
          STR_Structure: { ID_Federation: { _eq: this.context.ID_Structure } },
        });
      } else {
        arrayClause.push({ ID_Structure: { _eq: this.context.ID_Structure } });
      }
      // ENd -- Get the good wrap of user

      // Setup the field restriction
      if (this.search.EST_RoleFederal) {
        arrayClause.push({ EST_RoleFederal: { _eq: true } });
      }

      // if (this.search.structureSelected) {
      //   arrayClause.push({
      //     STR_Structure: { id: { _eq: this.search.structureSelected } },
      //   });
      // }
      if (this.search.ID_CodeFederal) {
        arrayClause.push({
          STR_Structure: {
            ID_CodeFederal: { _ilike: "%" + this.search.ID_CodeFederal + "%" },
          },
        });
      }
      if (this.SelectedSaison !== this.filterSaison) {
        arrayClause.push({
          UTI_Licence: {
            ID_Saison:
              this.SelectedSaison !== 1
                ? { _eq: this.SelectedSaison }
                : { _lt: 2019 },
            EST_Actif: { _eq: true },
          },
        });
      } else if (this.SelectedSaison === 2) {
        arrayClause.push({
          UTI_Licence: {
            ID_Saison: { _lt: this.currentSaison.id },
          },
        });
      }

      if (this.search.DateNaissanceStart) {
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: {
              _gt: "%" + this.search.DateNaissanceStart + "%",
            },
          },
        });
      }

      if (this.search.DateNaissanceEnd) {
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: { _lt: "%" + this.search.DateNaissanceEnd + "%" },
          },
        });
      }

      if (this.search.CT_Telephone) {
        let phoneClause = {
          _or: [
            {
              UTI_Utilisateur: {
                CT_TelephoneFixe: {
                  _ilike: "%" + this.search.CT_Telephone + "%",
                },
              },
            },
            {
              UTI_Utilisateur: {
                CT_TelephoneMobile: {
                  _ilike: "%" + this.search.CT_Telephone + "%",
                },
              },
            },
          ],
        };
        arrayClause.push(phoneClause);
      }

      if (this.search.CT_Email) {
        let emailClause = {
          _or: [
            {
              UTI_Utilisateur: {
                CT_Email: { _ilike: "%" + this.search.CT_Email + "%" },
              },
            },
            {
              UTI_Utilisateur: {
                CT_Email2: { _ilike: "%" + this.search.CT_Email + "%" },
              },
            },
          ],
        };
        arrayClause.push(emailClause);
      }

      if (this.search.LicenceNumero) {
        arrayClause.push({
          UTI_Utilisateur: {
            LicenceNumero: { _eq: this.search.LicenceNumero },
          },
        });
      }
      if (this.search.EST_LicenceSiege) {
        arrayClause.push({
          UTI_Licence: {
            EST_LicenceSiege: { _eq: this.search.EST_LicenceSiege },
          },
        });
      }
      if (this.civiliteSelected) {
        arrayClause.push({
          UTI_Utilisateur: {
            CT_EST_Civilite: { _eq: this.civiliteSelected },
          },
        });
      }
      if (this.search.CodePostal) {
        arrayClause.push({
          UTI_Utilisateur: {
            ADR_Adresse: { CodePostal: { _eq: this.search.CodePostal } },
          },
        });
      }
      if (this.search.ageMin) {
        const today = new Date().getFullYear();
        const age = this.$moment(
          new Date().setFullYear(+today - this.search.ageMin)
        ).toISOString();
        console.log("age :>> ", age);
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: { _lte: age },
          },
        });
      }
      if (this.search.ageMax) {
        const today = new Date().getFullYear();
        const age = this.$moment(
          new Date().setFullYear(+today - this.search.ageMax)
        ).toISOString();
        arrayClause.push({
          UTI_Utilisateur: {
            DN_DateNaissance: { _gte: age },
          },
        });
      }

      let where = {
        _and: arrayClause,
      };
      // End --  Setup the field restriction
      return where;
    },
    async exportResult() {
      this.isExporting = true;
      this.json_data = [];
      this.$refs.userList.loading = true;
      this.loading = true;
      let structUser_ids = this.items.map((user) => user.id);
      let user_ids = this.items.map((user) => user.ID_Utilisateur);
      let response = [];
      response = await this.exportUtilisateurStructure({
        structUserIDS: structUser_ids,
        ID_Saison: this.context.ID_Saison,
        ID_Structure: this.isAdmin ? null : this.context.ID_Structure,
        userIDS: user_ids,
      });

      let {
        STR_StructureUtilisateur,
        ADR_Adresse,
        UTI_PersonnesAPrevenir,
      } = response;

      STR_StructureUtilisateur.forEach((item) => {
        let civilite = this.listCivilite.find(
          (c) => c.value == item.UTI_Utilisateur.CT_EST_Civilite
        );
        let csvData = {
          structure_name: item.STR_Structure.NomStructure
            ? item.STR_Structure.NomStructure
            : "",
          num_licence: item.UTI_Utilisateur.EST_Licencie
            ? item.UTI_Utilisateur.LicenceNumero
            : "",
          sex: civilite ? civilite.text : "",
          firstname: item.UTI_Utilisateur.CT_Prenom
            ? item.UTI_Utilisateur.CT_Prenom
            : "",
          lastname: item.UTI_Utilisateur.CT_Nom
            ? item.UTI_Utilisateur.CT_Nom
            : "",
          adr: "",
          adr_completement: "",
          adr_cedex: "",
          ville: "",
          nationality: item.UTI_Utilisateur.nationalite
            ? item.UTI_Utilisateur.nationalite?.NomPays
            : "",
          // function: item.LIST_MissionSalarie ? item.LIST_MissionSalarie?.NomMission : "",
          email: item.UTI_Utilisateur.CT_Email,
          portable: `${item.UTI_Utilisateur.CT_TelephoneMobile || ""}`,
          fix: `${item.UTI_Utilisateur.CT_TelephoneFixe || ""}`,
          birthday: this.$moment(item.UTI_Utilisateur.DN_DateNaissance).format(
            "DD/MM/YYYY"
          ),
          // last_season: item.UTI_Utilisateur.UTI_Licences[0] ? item.UTI_Utilisateur.UTI_Licences[0].ID_Saison : "",
          licence_type:
            item.UTI_Utilisateur.UTI_Licences[0]?.LIST_LicenceType
              .NomTypeLicence,
          cm_end: item.UTI_Utilisateur.DOC_Documents[0]?.DateFinValiditeDocument
            ? this.$moment(
                item.UTI_Utilisateur.DOC_Documents[0]?.DateFinValiditeDocument
              ).format("DD/MM/YYYY")
            : null,
          pays: item.UTI_Utilisateur.ADR_Pays
            ? item.UTI_Utilisateur.ADR_Pays.NomPays
            : null,
          reference_club: item.UTI_Utilisateur?.UTI_Licences[0].ReferenceClub,
        };
        const seasonList = item.UTI_Utilisateur.UTI_Licences.map(
          (licence) => licence.ID_Saison
        );
        if (seasonList.length != 0) {
          const lastseason = Math.max(...seasonList);
          let datevalidation = item.UTI_Utilisateur.UTI_Licences.find(
            (licence) => licence.ID_Saison === lastseason
          );

          csvData["last_season"] = lastseason;
          csvData["licence_creation"] = datevalidation.DateLicenceValidation
            ? this.$moment(datevalidation.DateLicenceValidation).format(
                "DD/MM/YYYY"
              )
            : null;
        } else {
          csvData["last_season"] = "NC";
          csvData["licence_creation"] = "NC";
        }
        const certificatType =
          item.UTI_Utilisateur.DOC_Documents[0]?.LIST_DocumentType
            .NomTypeDocument;
        csvData["cm_type"] = certificatType ? certificatType.split(":")[1] : "";

        ADR_Adresse.forEach((adresse) => {
          if (adresse.ID_Utilisateur === item.ID_Utilisateur) {
            csvData["adr"] = adresse.Adresse1;
            csvData["adr_completement"] = adresse.Adresse2;
            csvData["adr_cedex"] = adresse.CodePostal;
            csvData["ville"] = adresse.Ville;
          }
        });
        UTI_PersonnesAPrevenir.forEach((pap) => {
          if (pap.ID_Utilisateur === item.ID_Utilisateur) {
            csvData["pap_lastname"] = pap.Nom;
            csvData["pap_firstname"] = pap.Prenom;
            csvData["pap_email"] = pap.Email;
            csvData["pap_mobile"] = pap.Tel_Mobile;
          }
        });
        let assurances = [];
        let options = [];
        item.UTI_Utilisateur.UTI_Licences[0]?.UTI_LicenceAssurance?.forEach(
          (assurance) => {
            if (assurance.ASSU_AssuranceType.EST_Option) {
              options.push(assurance.ASSU_AssuranceType.NomTypeAssurance);
            } else {
              assurances.push(assurance.ASSU_AssuranceType.NomTypeAssurance);
            }
          }
        );
        csvData["assurance_type"] = assurances.join(", ");
        csvData["options"] = options.join(", ");
        this.json_data.push(csvData);
      });
      setTimeout(() => {
        this.$refs.csvBtn.$el.click();
      }, 100);
      this.$refs.userList.loading = false;
      this.loading = false;
      this.isExporting = false;
    },

    // idfuse methods

    idFuseList() {
      this.isIDFuse = true;
      this.$store.commit("utilisateurs/SET_IS_IN_ID_FUSE_STATE", true);
    },
    goToMemberGestion() {
      this.isIDFuse = false;
      this.$store.commit("utilisateurs/SET_IS_IN_ID_FUSE_STATE", false);
    },
    async getListStructure() {
      this.isFetchingStructureListe = true;
      Promise.all([
        await this.getStructureListCategorie({
          ID_Structure: this.currentStructure.STR_Structure.id,
        }),
        await this.getStructureListeBySeason({
          ID_Structure: this.currentStructure.STR_Structure.id,
          ID_Saison: this.currentSaison.id,
        }),
      ]);
      if (this.$route.params.IDListe && +this.$route.params.IDListe !== 0) {
        this.selectedListe = +this.$route.params.IDListe;
      }
      this.isFetchingStructureListe = false;
    },
    openCreateModalList() {
      if (this.selectedListe) {
        const item = this.listeStructureAndCategorie.find(
          (liste) => liste.id === this.selectedListe
        );
        this.isEditingList = true;
        this.$store.commit("structure/SET_ACTIVE_LIST_STRUCTURE", item);
      }
      this.isEditListModalOpen = true;

      const userToAddList = this.items.filter(
        (item) =>
          !this.deSelectedUser.some(
            (user) => user.UTI_Utilisateur.id === item.UTI_Utilisateur.id
          )
      );
      this.$store.commit("utilisateurs/SET_USER_TO_ADD_LIST", userToAddList);
    },
  },
  watch: {
    isIDFuse: {
      async handler() {
        if (!this.isIDFuse) return;
        await this.getListStructure();
      },
    },
  },
  beforeDestroy() {
    this.isIDFuse = false;
    this.$store.commit("utilisateurs/SET_IS_IN_ID_FUSE_STATE", false);
  },
  props: {
    context: {
      type: Object,
    },
    actions: {
      type: String,
      default: "show",
    },
    show_select: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss">
// gestion liste css
.diffusion-item {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #082b6e;
  display: flex;
  height: 35px;
  align-items: center;
  padding: 5px;
  &:hover {
    background: lightgrey;
  }
}
.v-input--switch {
  padding: 0;
}
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
    height: 29px;
  }

  // CHIPS
  .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
    padding-left: 10px !important;
  }
  input[type="text"]:disabled {
    background: transparent !important;
  }
  .v-text-field__details {
    display: none;
  }
}

.filters-opened {
  height: calc(100vh - 440px);
}
// filter item

.filter-item {
  max-width: 11% !important;
  transition: 0.2s ease-in;
  margin-right: 14px;
}
.filter-item2 {
  max-width: 150px !important;
  transition: 0.2s ease-in;
  margin-right: 14px;
}
.filter-item3 {
  max-width: 350px !important;
  transition: 0.2s ease-in;
  margin-right: 14px;
}

.filter-item:focus-within {
  min-width: 250px !important;
}
.switch-label {
  font-family: "Roboto";
  font-style: normal;
  // font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}
.input-id-fuse {
  max-width: 11% !important;
  margin-right: 14px;
}
.select-item1 {
  max-width: 11% !important;
}
.isActive {
  color: #bac4d7 !important;
}
</style>
