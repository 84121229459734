<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    width="1000"
    @keydown.esc="close"
    @click:outside="close"
  >
    <Loading :loading="loading" title="Télécharger" />
    <v-card>
      <v-card-title
        class="header-container d-flex justify-content-center align-center"
      >
        <div class="d-flex align-center close-btn" text @click="close">
          <v-icon color="#082B6E" large> mdi-chevron-left </v-icon>
          <span
            class="text-header-title"
            style="font-weight: 500; font-size: 10px;"
          >
            Fermer
          </span>
        </div>
        <span class="text-header-title">
          Televerser un certificat medical
        </span>
      </v-card-title>

      <v-card-text>
        <div
          class="d-flex flex-column"
          style="padding: 0 12px;"
          v-for="(user, index) in userList"
          :key="index"
        >
          <!-- component multiple start -->

          <div class="d-flex justify-space-around align-center header-panel">
            <v-chip
              outlined
              color="#082B6E"
              style="width: 10%;"
              class="d-flex align-center justify-content-center"
            >
              <span v-text="getLicenceNumero(user)" />
            </v-chip>
            <span
              class="text-span text-span1 text-center"
              v-text="user.UTI_Utilisateur.CT_Nom"
              style="width: 20%;"
            />
            <span
              class="text-span text-span1 text-center"
              style="width: 20%;"
              v-text="user.UTI_Utilisateur.CT_Prenom"
            />
            <span
              class="text-span text-span1 text-span4 text-center"
              style="width: 20%;"
              v-text="user.STR_Structure.NomStructure"
            />
            <span
              class="text-span text-span1 text-center"
              style="width: 15%;"
              v-text="
                $moment(user.UTI_Utilisateur.DN_DateNaissance).format(
                  'DD/MM/YYYY'
                )
              "
            />
            <v-chip
              outlined
              color="#082B6E"
              style="width: 15%;"
              class="d-flex align-center justify-content-center"
            >
              <span v-text="getLastLicence(user)" />
            </v-chip>
          </div>
          <div v-if="userCMValues[index]" style="background-color: #fbfbfb;">
            <div
              class="d-flex align-center justify-space-between"
              style="padding: 0 12px;"
            >
              <div
                style="width: 49%;"
                class="d-flex align-center justify-space-between"
              >
                <span
                  v-text="'Certificat médical'"
                  class="text-span text-span5"
                  style="width: 35%;"
                />
                <v-file-input
                  required
                  :rules="[(v) => !!v || 'Veuillez selectionner un fichier']"
                  dense
                  prepend-icon=""
                  class="file-input"
                  outlined
                  show-size
                  style="font-size: 12px !important; width: 65%;"
                  @change="(file) => onFileChange(file, index)"
                  accept="image/jpeg, application/pdf, .doc, .docx"
                >
                  <template #label>
                    <v-icon small color="#bac4d7"> mdi-paperclip </v-icon>
                    <span
                      v-text="'Déposer un fichier'"
                      class="text-span text-span1 text-span3"
                    />
                  </template>
                </v-file-input>
              </div>
              <div style="width: 49%;" class="d-flex align-center">
                <span
                  v-text="'Type de certificat'"
                  class="text-span text-span5"
                  style="width: 35%;"
                />
                <InputSelect
                  :items="certificatsMedicauxTypes"
                  key="slug"
                  item_text="name"
                  item_value="slug"
                  @input="(event) => cmChanged(event, index)"
                  v-model="userCMValues[index].cmSelected"
                  style="width: 65%;"
                />
              </div>
            </div>
            <div style="width: 49%; padding: 0 12px;">
              <span
                class="text-span text-span2"
                v-text="'Format de fichiers acceptés : JPG, PDF, WORD'"
              />
            </div>
            <div class="d-flex align-center justify-space-between">
              <div
                style="width: 49%; padding: 0 12px;"
                class="d-flex align-center"
              >
                <span class="text-span text-span5" style="width: 35%;">
                  1<sup>ère</sup> saison de validité
                </span>

                <InputSelect
                  :items="getSaisonValide(index)"
                  v-model="userCMValues[index].selectedSaison"
                  @input="(event) => seasonChanged(event, index)"
                  style="width: 280px;"
                />
              </div>
              <div style="width: 49%;" class="d-flex align-center">
                <span
                  v-text="`Fin de validité :`"
                  class="text-span text-span1"
                  style="font-weight: 500 !important; width: 35%;"
                />
                <span
                  v-if="userCMValues[index].cmSelected !== 'sport_sante'"
                  v-text="userCMValues[index].endDate"
                  class="text-span text-span1"
                  style="font-weight: 500 !important;"
                />
                <DatePicker
                  v-else
                  v-model="userCMValues[index].finValidite"
                  rules="min:3"
                  minToday
                />
              </div>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions style="padding: 12px 60px;">
        <v-btn
          rounded
          height="42"
          @click.prevent="uploadValue"
          color="#082B6E"
          :disabled="$v.userCMValues.$invalid"
        >
          <span class="btn-style" style="color: white !important;">
            Envoyer les certificats médicaux</span
          >
        </v-btn>
        <v-btn
          text-color="white"
          color="#082B6E"
          rounded
          outlined
          height="42"
          @click="$emit('close')"
        >
          <span class="btn-style"> Annuler l'action </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputSelect from "@/components/utils/select.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications.js";
import Loading from "@/components/LoadingIndicator.vue";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

import { mapActions, mapGetters } from "vuex";
import { validate } from "@/constants/doc_status";

export default {
  name: "UserMultipleValidationCertificatMedical",
  mixins: [validationMixin],
  components: {
    InputSelect,
    Loading,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
    userList: {
      type: [Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      updateCMIDs: [],
      loadingCertificat: true,
      loading: false,
      uploadPercentage: 0,
      //multiple completion
      userCMValues: [],
      certificats: [],
      certificatsMedicauxTypes: [
        {
          name: "Loisir (valable 3 saisons)",
          slug: "loisir",
          file_path:
            "https://www.ffme.fr/wp-content/uploads/2019/06/certificat-medical-type.pdf",
        },
        {
          name: "Compétition (valable 3 saisons)",
          slug: "competition",
          file_path:
            "https://www.ffme.fr/wp-content/uploads/2019/06/certificat-medical-type.pdf",
        },
      ],
      isSportSante: false,
    };
  },
  validations: {
    userCMValues: {
      $each: {
        file: {
          required,
        },
        selectedSaison: { required },
        cmSelected: { required },
        finValidite: {
          required: requiredIf((userCMValues) => {
            return userCMValues.cmSelected === "sport_sante";
          }),
        },
      },
    },
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", ["currentStructureId"]),
    isFormDisabled() {
      if (
        !this.loadedFile ||
        !this.selectedSaison ||
        !this.cmSelected ||
        (!this.attestTrue && !this.isFederalMode)
      )
        return true;
      return false;
    },
    isFederalMode() {
      return localStorage.getItem("ffme_user_mode") === "federal";
    },
  },
  watch: {
    cmSelected: {
      handler() {
        this.updateCMIDValue();
      },
    },
    userList: {
      async handler() {
        this.setData();
        await this.fetchUserCertificat();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateurCertificatsM",
      "updateUserIsNonPratiquant",
    ]),
    ...mapActions("user", ["insertCertificatMedical"]),
    getSaisonValide() {
      return [
        this.currentSaison.id - 2,
        this.currentSaison.id - 1,
        this.currentSaison.id,
      ];
    },
    async fetchUserCertificat() {
      this.loadingCertificat = true;
      let certificatPromise = [];
      this.userList.forEach((user) => {
        certificatPromise.push(this.getUtilisateurCertificatsM(user));
      });
      await Promise.all(certificatPromise).then((response) => {
        this.certificats = [...response];
        return (this.loadingCertificat = false);
      });
    },
    setData() {
      for (let i = 0; i < this.userList.length; i++) {
        this.userCMValues.push({
          file: null,
          finValidite: null,
          selectedSaison: +this.currentSaison.id,
          cmSelected: null,
          endDate: "DD/MM/YYYY",
          endDateFormated: "",
          isSportSante: false,
          isImage: false,
          isPDF: false,
          fileName: "",
          updateCMIDs: [],
        });
      }
    },
    close() {
      this.$emit("close");
      this.reset();
    },
    async uploadValue() {
      // request to all files
      let uploadedPromises = [];
      this.userCMValues.forEach((user, index) => {
        return uploadedPromises.push(this.upload(user, index));
      });
      this.loading = true;
      await Promise.all(uploadedPromises).then(() => {
        this.loading = false;
        this.close();
        success_notification("Certificats ajoutés");
        this.$store.commit("structure/SET_INITIAL_CHECKBOX", false);
        setTimeout(() => {
          this.$store.commit("structure/SET_INITIAL_CHECKBOX", true);
        }, 250);
      });
    },
    getLastLicence(user) {
      const licence = [...user.UTI_Licence];
      const lastElement = licence.pop();
      return `${lastElement?.LIST_LicenceType?.NomTypeLicence} - ${lastElement?.ID_Saison}`;
    },
    getLicenceNumero(user) {
      const licenceNumero = `${user?.UTI_Utilisateur?.LicenceNumero}`;
      return `${licenceNumero.slice(0, 3)} ${licenceNumero.slice(3, 6)}`;
    },
    reset() {
      this.userCMValues = [];
    },
    cmChanged(event, index) {
      this.updateCMIDValue(index);

      switch (event) {
        default: {
          const finDate = new Date("08/31/2022").setFullYear(
            this.userCMValues[index].selectedSaison + 2
          );

          this.userCMValues[index].endDateFormated = this.$moment(
            finDate
          ).format("YYYY-MM-DD");

          this.userCMValues[index].endDate = this.$moment(
            finDate
          ).format("DD/MM/YYYY");

          return event;
        }
      }
    },
    seasonChanged(event, index) {
      this.updateCMIDValue(index);
      switch (this.userCMValues[index].cmSelected) {
        default: {
          const finDate = new Date("08/31/2022").setFullYear(event + 2);
          this.userCMValues[index].endDateFormated = this.$moment(
            `08/31/${event + 2}`
          ).format("YYYY-MM-DD");
          return (this.userCMValues[index].endDate = this.$moment(
            finDate
          ).format("DD/MM/YYYY"));
        }
      }
    },
    onFileChange(file, index) {
      if (file == null) return;
      this.userCMValues[index].file = file;
      if (file.type.indexOf("image/") !== -1) {
        this.userCMValues[index].isImage = false;
      }
      if (file.type === "application/pdf") {
        this.userCMValues[index].isPDF = true;
      }

      this.userCMValues[index].fileName = file.name;

      // read file data to memory
      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.userCMValues[index].file = event.target.result;
        };
        return reader.readAsDataURL(this.userCMValues[index].file);
      }
      alert("Sorry, FileReader API not supported");
    },
    // getCMIDS
    updateCMIDValue(index) {
      let certifLoisir = this.certificats[index].find(
        (cm) =>
          cm.LIST_DocumentType.SlugTypeDocument === "certificat_medical_loisir"
      );
      let certifCompetition = this.certificats[index].find(
        (cm) =>
          cm.LIST_DocumentType.SlugTypeDocument ===
          "certificat_medical_competition"
      );

      if (
        certifLoisir &&
        this.userCMValues[index].cmSelected === "competition"
      ) {
        this.userCMValues[index].updateCMIDs.push(certifLoisir.id);
      }

      if (
        certifCompetition &&
        this.userCMValues[index].cmSelected === "loisir"
      ) {
        this.userCMValues[index].updateCMIDs.push(certifCompetition.id);
      }

      if (certifLoisir && this.userCMValues[index].cmSelected === "loisir") {
        this.userCMValues[index].updateCMIDs.push(certifLoisir.id);
      }

      if (
        certifCompetition &&
        this.userCMValues[index].cmSelected === "competition"
      ) {
        this.userCMValues[index].updateCMIDs.push(certifCompetition.id);
      }
    },
    // upload document
    async fileUploaded(file) {
      try {
        let newCertificate = {
          CheminDocument: file.url,
          NomDocument: file.filename,
          ID_Type_Document: file.documentType,
          Categorie: "certificat",
          ID_Utilisateur: file.userToUpdate,
          DateFinValiditeDocument: file.finDeValidite,
          EST_Actif: true,
          EST_DocumentValide: true,
          ID_Saison: file.saison,
          DateValidation: file.date,
          status: validate,
        };
        await this.insertCertificatMedical({
          newCertificate,
          ID_Utilisateur: file.userToUpdate,
          updateIDs: file.updateCMIDs,
        });
        this.updateUserIsNonPratiquant({
          userID: file.userToUpdate,
          isNonPratiquant: false,
        });
      } catch (e) {
        console.log(e);
      }
    },

    // upload file
    async upload(data, index) {
      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: data.file,
        key: `${this.currentStructureId}_${hash}_${data.fileName}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };

      this.uploadPercentage = 0;
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload",
          payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            },
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          }
        );
        if (
          response.data &&
          response.data.location &&
          response.status &&
          response.status === 200
        ) {
          let freshFile = {
            url: response.data.location,
            filename: data.fileName,
            date: this.$moment(
              new Date(new Date().setFullYear(data.selectedSaison))
            ).toISOString(),
            updateCMIDs: [...new Set(data.updateCMIDs)],
            finDeValidite:
              this.cmSelected === "sport_sante"
                ? this.$moment(new Date(data.finValidite)).format("YYYY-MM-DD")
                : data.endDateFormated, // fin de validation
            saison: +this.currentSaison.id, // debut de saison
            documentType: this.getList.LIST_DocumentType.find(
              (type) =>
                type.SlugTypeDocument == `certificat_medical_${data.cmSelected}`
            )?.id, // type du document ceritifcat selected
            userToUpdate: this.userList[index].ID_Utilisateur,
          };
          return await this.fileUploaded(freshFile);
        }
        return error_notification("Upload unsuccessful");
      } catch (error) {
        this.loading = false;
        error_notification(error.toString());
      }
    },
  },
};
</script>

<style scoped>
.btn-style {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: initial !important;
  padding: 12px 24px !important;
}
.text-header-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #020918;
}
.header-container {
  position: relative;
}
.close-btn {
  cursor: pointer;
  position: absolute;
  left: 15px;
}
.information-alert {
  height: 36px;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary);
  background-color: rgba(8, 43, 110, 0.1);
  border-left: solid 2px var(--primary);
}
.checkbox-upload >>> .v-input__slot {
  max-width: 700px !important;
}
.text-span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.text-span1 {
  font-size: 14px;
}
.text-span5 {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.text-span2 {
  font-size: 12px !important;
  color: #768aae !important;
}
.text-span3 {
  color: #bac4d7 !important;
}
.text-span4 {
  color: #082b6e;
  text-decoration: underline;
}
.file-input >>> fieldset {
  border: solid 0.5px #e7effd !important;
  height: 32px !important;
  margin-top: 8px;
  width: 280px;
}
.header-panel {
  min-height: 56px;
  padding: 16px 24px 16px 16px;
  background-color: #ebeef3;
}
</style>
