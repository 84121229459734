<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="$emit('onSelected', user)"
          class="mx-1"
          fab
          x-small
          outlined
          color="orange"
        >
          <v-icon>mdi-plus-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>Selectionner l'utilisateur</span>
    </v-tooltip>
  </span>
</template>

<script>
export default {
  components: {},

  data: () => ({
    modalIsOpen: false,
    mounted: false,
  }),

  created() {
    this.mounted = true;
  },

  computed: {},

  methods: {
    closeModal() {
      this.modalIsOpen = false;
    },

    openModal() {
      this.modalIsOpen = true;
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.v-dialog .v-icon {
  top: inherit !important;
}
</style>
