<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="600px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab style="display:flex;flex-direction:column;">
        <span style="font-size:1.2rem;">
          {{ modalTitle }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat>
          <div class="text-center px-3 py-6">
            <h6>Copier les licences de la saison précedente ?</h6>
          </div>
          <v-card-actions class="px-10 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeModal"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Annuler</v-btn
            >
            <v-btn
              @click="copyLicence"
              :loading="loading"
              small
              rounded
              color="blue-dark text-white font-weight-bold"
              >Oui</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { success_notification } from "@/plugins/notifications";
import { bus } from "@/main";

export default {
  data: () => ({
    loading: false,
    mounted: false,
  }),

  async created() {
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", ["getStructure"]),

    modalTitle() {
      return "Copie de licences";
    },

    structure() {
      return this.getStructure(this.context.ID_Structure);
    },
  },

  methods: {
    ...mapActions("structure", [
      "updateLicenceUtilisateur",
      "updateLastLicenceUtilisateur",
    ]),
    ...mapActions("utilisateurs", ["getLicenceUtilisateur"]),

    closeModal() {
      this.$emit("onClose");
    },

    async copyLicence() {
      // Check if we have licence fot this saisons...
      // get all licence for user in tyhe last saison

      // uti utlisateur licence where saison == 2020
      let usersId = [];
      // console.log('les users',  this.users)
      this.users.forEach((user) => {
        if (user.UTI_Licence) {
          // Je récupére les licences qui ont un id saison
          if (user.UTI_Licence.ID_Saison < this.context.ID_Saison) {
            usersId.push(user.ID_Utilisateur);
          }
        }
      });

      let licencesUsers = await this.getLicenceUtilisateur({
        ID_Utilisateurs: usersId,
      });

      // console.log('licencestyoupdate', licencesUsers)
      let payload = licencesUsers.map((licence) => {
        let licenceAssu = licence.UTI_LicenceAssurance.map((assu) => {
          let item = assu;
          delete item.id;
          delete item.ASSU_AssuranceType;
          delete item.ID_Licence;
          delete item.__typename;
          item.ID_Saison = this.context.ID_Saison;
          item.ID_Utilisateur = licence.ID_Utilisateur;
          return item;
        });
        let GQLVariables = {
          ID_Saison: this.context.ID_Saison,
          ID_Structure: this.context.ID_Structure,
          ID_TypeLicence: licence.ID_TypeLicence,
          ID_Utilisateur: licence.ID_Utilisateur,
          ID_EtapeLicence: 1,
          UTI_LicenceAssurance: {
            data: licenceAssu,
          },
        };

        return GQLVariables;
      });
      // console.log('payload to send', payload)
      let response = await this.updateLicenceUtilisateur({
        items: payload,
        rmID_UTI_LicenceAssurance: [],
      });

      if (response) {
        let usersToUpLastLicence = [];
        this.users.forEach((user) => {
          if (
            user.UTI_Licence ||
            (user.UTI_Licence &&
              user.UTI_Licence.ID_Saison < this.context.ID_Saison)
          ) {
            delete user.UTI_Licence;
            delete user.UTI_Utilisateur;
            delete user.STR_Structure;
            //delete user.LIST_MissionSalarie
            delete user.__typename;
            response.forEach((respo) => {
              if (user.ID_Utilisateur == respo.ID_Utilisateur) {
                // console.log('user doublon', user)
                user.EST_Actif = true;
                user.ID_LastLicence = respo.id;
              }
            });

            usersToUpLastLicence.push(user);
          }
        });
        // console.log("users to update", usersToUpLastLicence)
        await this.updateLastLicenceUtilisateur({ data: usersToUpLastLicence });
      }

      success_notification("Mis à jour");
      bus.$emit("onUserListStructureUpdated");
      //this.$emit("onUpdate", response);
      this.$emit("onClose");
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    users: {
      type: Array,
    },
  },
};
</script>
