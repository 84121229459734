<template>
  <BaseModal
    :headerText="getText('title')"
    :is-open="isOpen"
    :btnValidationText="getText('button')"
    btnCancelText="Fermer"
    v-on="$listeners"
    @validate="updateCreateList()"
    key="list-modal"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center my-4">
          <span v-text="'Description de la liste'" class="text-subtitle" />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div class="d-flex align-center justify-content-between mt-2">
          <div class="d-flex flex-column position-relative" style="width: 47%;">
            <span class="text-blue">Catégorie de liste</span>
            <InputSelect
              :items="structureCategorieList"
              item_value="id"
              item_text="Nom_ListeCategorie"
              placeholder="Sélectionner une catégorie"
              v-model="categorieSelected"
            />
          </div>
          <div class="d-flex flex-column position-relative" style="width: 47%;">
            <span class="text-blue">Nom de la liste</span>
            <InputText v-model="listName" rules="required|min:0|max:50" />
          </div>
        </div>
        <div
          class="d-flex flex-column position-relative mt-2"
          style="width: 47%;"
        >
          <div
            class="text-blue"
            style="cursor: pointer;"
            @click.prevent="addNewCategorie"
          >
            <span class="text-decoration-underline">
              Ajouter une nouvelle catégorie
            </span>
            <v-icon small color="#1c3d7a">mdi-plus-thick</v-icon>
          </div>
          <InputText v-model="newCategorieName" rules="required|min:0|max:50" />
        </div>
        <div class="d-flex align-center my-2 mt-3">
          <span v-text="'Options de la liste'" class="text-subtitle" />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div class="table-container">
          <v-data-table
            class="id-fuse-table"
            :headers="headers"
            :items="userWithFunctionPerDefault"
            disable-sort
            hide-default-footer
            :loading="isFetchingUserWithFunction"
          >
            <template #item.fullname="{item}">
              <span
                v-if="item.UTI_Utilisateur"
                v-text="
                  `${item.UTI_Utilisateur.CT_Nom} ${item.UTI_Utilisateur.CT_Prenom}`
                "
              />
            </template>
            <template #item.use="{item}">
              <div class="d-flex justify-content-center">
                <v-checkbox
                  class="read-only"
                  v-model="item.canUseList"
                  readonly
                  style="margin-top: 4px !important;"
                />
              </div>
            </template>
            <template #item.edit="{item}">
              <div class="d-flex justify-content-center">
                <v-checkbox
                  class="check"
                  :class="{ 'read-only': isPresidentRole(item) }"
                  v-model="item.canEditList"
                  style="margin-top: 4px !important;"
                />
              </div>
            </template>
            <template #item.delete="{item}">
              <div class="d-flex justify-content-center">
                <v-checkbox
                  class="check"
                  v-model="item.canDeleteList"
                  :class="{ 'read-only': isPresidentRole(item) }"
                  :readonly="isPresidentRole(item)"
                  style="margin-top: 4px !important;"
                />
              </div>
            </template>
            <!-- footer -->
            <template #footer>
              <div class="add-person-container d-flex align-center">
                <v-icon medium color="#000000">mdi-account-plus</v-icon>
                <span
                  class="text-participant ml-4"
                  @click="$emit('open-participant-list')"
                  >Partager à d’autres personnes</span
                >
              </div>
            </template>
          </v-data-table>
          <div class="d-flex align-center my-4">
            <v-checkbox
              v-model="synchronizeTerms"
              style="margin-top: 4px !important;"
            />
            <span class="text-subtitle" style="font-weight: normal !important;">
              Synchroniser cette liste avec votre compte ID Fuse</span
            >
          </div>
          <div v-if="synchronizeTerms" class="alert-message">
            La synchronisation de la liste avec ID Fuse est sélectionnée.
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../../components/utils/modals/BaseModal.vue";
import InputSelect from "@/components/utils/select.vue";
import InputText from "@/components/utils/input";
import { mapActions, mapGetters, mapState } from "vuex";
import { error_notification } from "@/plugins/notifications";

export default {
  name: "IdFuseCancelSubscriptionModal",
  components: { BaseModal, InputSelect, InputText },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isEdited: {
      type: Boolean,
      default: false,
    },
    createWithList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // data list
      usersWithFunction: [],
      isFetchingUserWithFunction: false,
      structureListeCategorie: [],
      listName: "",
      categorieSelected: null,
      newCategorie: false,
      newCategorieName: "",
      // end DataList
      synchronizeTerms: false,
      list: [{ text: "Licencie", name: "Junior" }],
      headers: [
        {
          text: "Personne",
          value: "fullname",
        },
        {
          text: "Rôle",
          value: "FonctionUtilisateur.NomFonction",
        },
        {
          text: "Utiliser",
          align: "center",
          value: "use",
        },
        {
          text: "Editer",
          align: "center",
          value: "edit",
        },
        {
          text: "Supprimer",
          align: "center",
          value: "delete",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapState({
      userWithFunctionSelected: (state) =>
        state.structure.userWithFunctionSelected,
      structureCategorieList: (state) => state.structure.structureCategorieList,
      activeListSelected: (state) => state.structure.activeListSelected,
      userToAddToList: (state) => state.utilisateurs.userToAddToList,
    }),
    userWithFunctionPerDefault() {
      const canEditPerson = ["president", "bureau_secretaire"];

      if (!this.isEdited) {
        const slugFunctionPerDefault = [
          "president",
          "bureau_secretaire",
          "bureau_tresorier",
        ];
        let userPerDefault = this.usersWithFunction.filter((user) =>
          slugFunctionPerDefault.includes(
            user.FonctionUtilisateur?.SlugFonction
          )
        );
        userPerDefault = [...userPerDefault, ...this.userWithFunctionSelected];
        const users = userPerDefault
          ?.map((user) => ({
            ...user,
            canUseList: true,
            canEditList: canEditPerson.includes(
              user.FonctionUtilisateur?.SlugFonction
            ),
            canDeleteList:
              user.FonctionUtilisateur?.SlugFonction === "president",
          }))
          ?.sort((a, b) => a.ID_Fonction - b.ID_Fonction);
        this.$store.commit("structure/SET_USER_ACTIVE_IN_LIST", users);
        return users;
      }

      let user = this.activeListSelected?.STR_StructureListeProprietaries?.map(
        (proprietiare) => ({
          ...proprietiare,
          idProprietaire: proprietiare.id,
          UTI_Utilisateur: proprietiare.UTI_Utilisateur,
          FonctionUtilisateur: proprietiare.LIST_FonctionType,
          ...proprietiare.STR_StructureListeRole,
        })
      );
      const selected = [...this.userWithFunctionSelected].map((user) => ({
        ...user,
        canUseList: true,
        canEditList: canEditPerson.includes(
          user.FonctionUtilisateur?.SlugFonction
        ),
        canDeleteList: user.FonctionUtilisateur?.SlugFonction === "president",
      }));
      user = [...user, ...selected];
      this.$store.commit("structure/SET_USER_ACTIVE_IN_LIST", user);
      return user;
    },
  },
  methods: {
    ...mapActions("structure", [
      "getUserWhoHasFunctionInStructure",
      "createUpdateNewListStructure",
      "createUpdateNewStructureListeCategorie",
    ]),
    getText(item) {
      if (this.isEdited) {
        return item === "button"
          ? "Modifier la liste"
          : `Modifier la liste "${this.activeListSelected?.NomListe}"`;
      }
      return item === "button" ? "Créer la liste" : "Création de liste";
    },
    getSlug(item) {
      const newItem = item.split(" ");
      return newItem.join("_");
    },
    async addNewCategorie() {
      if (this.newCategorieName === "") {
        return error_notification("Veuillez entrer un nom de catégorie!");
      }
      const newCategoriePayload = {
        Nom_ListeCategorie: this.newCategorieName,
        SlugListeCategorie: this.getSlug(this.newCategorieName),
        ID_Structure: this.currentStructureId,
      };
      await this.createUpdateNewStructureListeCategorie({
        data: newCategoriePayload,
      });
      this.newCategorieName = "";
    },
    async getData() {
      // user with function president secretaire tresorier
      await this.getUserWithFunction();
      if (this.isEdited) return this.setData();
    },

    setData() {
      this.listName = this.activeListSelected.NomListe;
      this.categorieSelected = this.activeListSelected.STR_StructureListeCategorie.id;
    },

    async getUserWithFunction() {
      this.isFetchingUserWithFunction = true;

      this.usersWithFunction = await this.getUserWhoHasFunctionInStructure({
        ID_Structure: this.currentStructureId,
      });
      this.isFetchingUserWithFunction = false;
    },

    isPresidentRole(item) {
      if (item.FonctionUtilisateur?.SlugFonction === "president") return true;
      return false;
    },

    getRole({ canUseList, canEditList, canDeleteList }) {
      if (canUseList && canEditList && canDeleteList) return 1;
      if (canUseList && canEditList) return 2;
      return 3;
    },
    async updateCreateList() {
      let users = [...this.userWithFunctionPerDefault];
      const userPayload = users.map((user) => {
        const test = {
          ID_Utilisateur: user.UTI_Utilisateur?.id,
          ID_Role: this.getRole(user),
          ID_Fonction: user.FonctionUtilisateur?.id || user.ID_Fonction,
        };
        if (user?.idProprietaire) {
          test.id = user.idProprietaire;
        }
        return test;
      });

      const listPayload = {
        NomListe: this.listName,
        ID_ListeCategorie: this.categorieSelected,
        ID_Saison: this.currentSaison.id,
        EST_Actif: true,
        ID_Structure: this.currentStructureId,
        STR_StructureListeProprietaries: {
          data: userPayload,
          on_conflict: {
            constraint: "STR_StructureListeProprietary_pkey",
            update_columns: ["ID_Utilisateur", "ID_Role", "ID_Fonction"],
          },
        },
      };
      if (this.isEdited) {
        listPayload.id = this.activeListSelected.id;
      }
      // create with user in list
      if (this.createWithList) {
        const userListPayload = this.userToAddToList.map((user) => ({
          ID_Utilisateur: user.ID_Utilisateur,
        }));
        listPayload.STR_StructureListeUtilisateurs = {
          data: userListPayload,
          on_conflict: {
            constraint: "STR_StructureListeUtilisateur_pkey",
            update_columns: ["ID_Utilisateur", "Z_DateCreation"],
          },
        };
      }
      await this.createUpdateNewListStructure({ liste: { ...listPayload } });
      this.$emit("close");
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  .text-blue {
    position: absolute;
    top: -10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1c3d7a;
  }

  .id-fuse-table {
    .add-person-container {
      background: #ebeef3;
      min-height: 55px;
      padding: 8px 24px;
      .text-participant {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
  .alert-message {
    font-weight: normal;
    font-size: 14px;
    line-height: 36px;
    color: #00be76;
    background: rgba(0, 190, 118, 0.1);
    border-left: 2px solid #00be76;
    min-height: 45px;
    padding: 6px 12px;
  }
  .read-only {
    opacity: 0.4;
  }
}
</style>
<style scoped>
::v-deep .v-data-table-header th {
  background: #39558b !important;
  color: white !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}
::v-deep .id-fuse-table td {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000;
}
::v-deep .check-box >>> i {
  color: red !important;
  opacity: 0.2;
}
.select-item {
  font-family: Roboto;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px;
  color: #000000 !important;
  height: 10px;
}
</style>
