<template>
  <div v-if="item.UTI_Utilisateur.DOC_Documents[0]">
    <v-chip class="mr-1" small outlined color="blue">
      {{ certificats }}
    </v-chip>
  </div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  data: () => ({
    certificat_medical: [],
  }),

  created() {
    this.item.UTI_Utilisateur.DOC_Documents.map((certificat) => {
      if (
        certificat.LIST_DocumentType.SlugTypeDocument.includes(
          "certificat_medical"
        )
      ) {
        this.certificat_medical = certificat;
      }
    });
  },
  computed: {
    certificats() {
      const certMedical = this.certificat_medical.LIST_DocumentType
        .SlugTypeDocument;
      if (!this.item.UTI_Utilisateur.DOC_Documents) return false;
      if (certMedical === "certificat_medical_competition")
        return "Competition";
      if (certMedical === "certificat_medical_loisir") return "Loisir";

      return "";
    },
  },

  props: {
    item: {
      type: Object,
    },

    context: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    lastOne: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
