<template>
  <v-dialog
    v-model="isOpen"
    :width="modalWidth"
    @keydown.esc="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title
        class="header-container d-flex justify-content-center align-center"
      >
        <div class="d-flex align-center close-btn" @click="$emit('close')">
          <v-icon color="#082B6E" large>
            mdi-chevron-left
          </v-icon>
          <span class="close-text">
            Fermer
          </span>
        </div>
        <span class="modal-title" v-text="headerText" />
      </v-card-title>

      <v-card-text>
        <slot name="modal-content" />
      </v-card-text>

      <v-card-actions v-if="isActionNeeded" style="padding: 12px 60px;">
        <Button
          :color="cancelBtn ? '#FF0000' : '#082B6E'"
          class="btn-validation"
          :class="{ disabled: isValidationBtnDisabled }"
          @click="isValidationBtnDisabled ? null : $emit('validate')"
          rounded
          height="48"
          type="primary"
          :loading="isLoading"
        >
          {{ btnValidationText }}
        </Button>

        <Button
          color="#082B6E"
          text-color="#082B6E"
          outlined
          rounded
          height="48"
          type="primary"
          @click="$emit('close')"
        >
          {{ btnCancelText }}
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Button from "../button.vue";

export default {
  components: { Button },
  name: "BaseModal",
  props: {
    modalWidth: {
      type: String,
      default: "850",
    },
    btnValidationText: {
      type: String,
      default: "Valider",
    },
    btnCancelText: {
      type: String,
      default: "Annuler l'action",
    },
    headerText: {
      type: String,
      default: "",
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      type: Boolean,
      default: false,
    },
    isValidationBtnDisabled: {
      type: Boolean,
      default: false,
    },
    isActionNeeded: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  font-family: Roboto;
  font-style: normal;
  position: relative;
  text-transform: uppercase;
  .close-btn {
    cursor: pointer;
    position: absolute;
    left: 15px;
    .close-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #000000;
    }
  }
  .modal-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #020918;
  }
}
.btn-validation {
  color: white !important;
}
.disabled {
  opacity: 0.3 !important;
}
</style>
