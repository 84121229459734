<template>
  <v-dialog
    v-model="isOpen"
    width="1000"
    @keydown.esc="close"
    @click:outside="close"
  >
    <Loading
        :loading="loading"
        :progress="uploadPercentage"
        title="Télécharger"
    />
    <v-card>
      <v-card-title
          class="header-container d-flex justify-content-center align-center"
      >
        <div class="d-flex align-center close-btn" text @click="close">
          <v-icon color="#082B6E" large> mdi-chevron-left </v-icon>
          <span
              class="text-header-title"
              style="font-weight: 500; font-size: 10px;"
          >
            Fermer
          </span>
        </div>
        <span class="text-header-title">
          Téléverser un certificat medical
        </span>
      </v-card-title>
    <certificat-medical-upload
        ref="CMupload"
        :context="context"
        :certificats="certificats"
        @formCompleted="onFormCompleted"
    />
      <v-card-actions style="padding: 12px 60px;">
        <v-btn
            rounded
            height="42"
            @click.prevent="upload"
            :disabled="isFormDisabled"
            color="#082B6E"
        >
          <span class="btn-style" style="color: white !important;">
            Envoyer le certificat medical</span
          >
        </v-btn>
        <v-btn
            text-color="white"
            color="#082B6E"
            rounded
            outlined
            height="42"
            @click="close"
        >
          <span class="btn-style"> Annuler l'action </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { error_notification } from "@/plugins/notifications.js";

import { mapActions, mapGetters } from "vuex";

import CertificatMedicalUpload from "./CertificatMedicalUpload.vue";
import Loading from "@/components/LoadingIndicator.vue";
export default {
  name: "CertificatMedicalUploadModal",
  components: {
    CertificatMedicalUpload, Loading
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
    certificats: {
      type: [Object, Array],
      default: () => null,
    },
  },
  data() {
    return {
      loadedFile: null,
      test: null,
      endDateFormated: null,
      updateCMIDs: [],
      loading: false,
      endDate: "DD/MM/YYYY",
      cmSelected: null,
      uploadPercentage: 0,
      finValidite: null,
      selectedSaison: null,
      attestTrue: false,
      isSportSante: false,
      isFormDisabled: true,
    };
  },
  created() {
    this.selectedSaison = this.currentSaison.id;
  },
  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("structure", ["currentStructureId"]),
  },
  watch: {
    cmSelected: {
      handler() {
        this.updateCMIDValue();
      },
    },
  },
  methods: {
    ...mapActions("utilisateurs", ["getUtilisateurCertificatsM"]),
    close() {
      this.$emit("close");
      this.$refs.CMupload.reset();
      this.isFormDisabled = true;
    },
    // upload file
    async upload() {
      this.loading = true;
      const hash =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);

      let payload = {
        image: this.loadedFileContent,
        key: this.filename,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      console.log("payloaddd",payload)
      // let payload = {
      //   image: this.loadedFileContent,
      //   key: `${this.cmSelected}_${this.filename}`,
      //   config: {
      //     S3_BUCKET: process.env.S3_BUCKET,
      //   },
      // };

      this.uploadPercentage = 0;
      try {
        const response = await this.axios.post(
          process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload",
          payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            },
            onUploadProgress: (progressEvent) => {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          }
        );
        if (
          response.data &&
          response.data.location &&
          response.status &&
          response.status === 200
        ) {
          let freshFile = {
            url: response.data.location,
            filename: this.filename,

            date: this.$moment(
              new Date(new Date().setFullYear(this.selectedSaison))
            ).toISOString(), // debut de saison
            updateCMIDs: [...new Set(this.updateCMIDs)],
            finDeValidite:
              this.cmSelected === "sport_sante"
                ? this.$moment(new Date(this.finValidite)).format("YYYY-MM-DD")
                : this.endDateFormated, // fin de validation
            saison: +this.currentSaison.id,
            documentType: this.getList.LIST_DocumentType.find(
              (type) =>
                type.SlugTypeDocument == `certificat_medical_${this.cmSelected}`
            )?.id, // type du document ceritifcat selected
          };
          this.$emit("onFileUploaded", freshFile);
          this.loading = false;
          freshFile;
          this.loadedFile = null;
          return this.close();
        }
        this.loading = false;
        this.close();
        return error_notification("Upload unsuccessful");
      } catch (error) {
        this.loading = false;
        error_notification(error.toString());
        this.close();
      }
    },
    onFormCompleted(value) {
      this.isFormDisabled = !value.complete;
      if (value.complete) {
        this.selectedSaison= value.selectedSaison;
        this.finValidite= value.finValidite;
        this.cmSelected= value.cmSelected;
        this.updateCMIDs= value.updateCMIDs;
        this.attestTrue= value.attestTrue;
        this.endDateFormated= value.endDateFormated;
        this.loadedFileContent = value.loadedFileContent;
        this.filename = value.filename;
      }
      console.log('onFormCompleted', value)
    },
    // getCMIDS
    updateCMIDValue() {
      // si il y a un ou des certificats et qu'on choisi sport_santé il écrase tous les ancien
      if (this.cmSelected === "sport_sante") {
        return this.certificats.forEach((cm) => this.updateCMIDs.push(cm.id));
      }

      let certifLoisir = this.certificats?.find(
          (cm) =>
              cm.LIST_DocumentType.SlugTypeDocument === "certificat_medical_loisir"
      );
      let certifCompetition = this.certificats?.find(
          (cm) =>
              cm.LIST_DocumentType.SlugTypeDocument ===
              "certificat_medical_competition"
      );

      if (certifLoisir && this.cmSelected === "competition") {
        this.updateCMIDs.push(certifLoisir.id);
      }

      if (certifCompetition && this.cmSelected === "loisir") {
        this.updateCMIDs.push(certifCompetition.id);
      }

      if (certifLoisir && this.cmSelected === "loisir") {
        this.updateCMIDs.push(certifLoisir.id);
      }

      if (certifCompetition && this.cmSelected === "competition") {
        this.updateCMIDs.push(certifCompetition.id);
      }
    },
  },
};
</script>

<style scoped>
.btn-style {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-transform: initial !important;
  padding: 12px 24px !important;
}
.text-header-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #020918;
}
.text-label {
  font-family: Roboto;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #000000;
}

.header-container {
  position: relative;
}
.close-btn {
  cursor: pointer;
  position: absolute;
  left: 15px;
}
.information-alert {
  height: 36px;
  flex-grow: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.57;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary);
  background-color: rgba(8, 43, 110, 0.1);
  border-left: solid 2px var(--primary);
}
.checkbox-upload >>> .v-input__slot {
  max-width: 700px !important;
}
.text-span {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.text-span1 {
  font-size: 14px;
}
.text-span2 {
  font-size: 12px !important;
  color: #768aae !important;
}
.text-span3 {
  color: #bac4d7 !important;
}
.file-input >>> fieldset {
  border: solid 0.5px #e7effd !important;
  height: 42px !important;
  width: 280px;
}
::v-deep .item-without-msg .v-text-field__details {
  display: none !important;
}
</style>
