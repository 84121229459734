<template>
  <div>
    <div style="background: #f0f0f0;">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-if="panel === 0">Masquer les filtres</span>
            <span v-else>Afficher les filtres</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f0f0f0">
            <ValidateForm :showFieldObl="false" :key="refreshForm">
              <v-row
                class="d-flex align-items-center mt-2"
                @keydown.enter="sendNewSearch"
              >
                <v-col
                  cols="2"
                  lg="2"
                  style="padding-top: 0; padding-bottom: 0;"
                >
                  <inputText
                    v-model="search.CT_Nom"
                    rules="min:2"
                    placeholder="Nom"
                  ></inputText>
                </v-col>

                <v-col
                  cols="2"
                  lg="2"
                  style="padding-top: 0; padding-bottom: 0;"
                >
                  <inputText
                    v-model="search.CT_Prenom"
                    rules="min:2"
                    placeholder="Prénom"
                  ></inputText>
                </v-col>

                <v-col
                  cols="2"
                  lg="3"
                  xl="2"
                  style="padding-top: 0; padding-bottom: 0;"
                >
                  <inputText
                    v-model="search.LicenceNumero"
                    rules="min:3"
                    type="number"
                    placeholder="N° Licence"
                  ></inputText>
                </v-col>

                <v-col cols="12" lg="5" class="py-0">
                  <v-row
                    class="d-flex align-items-center align-content-center pt-1"
                  >
                    <v-col cols="4" lg="5" xl="2" class="pt-3">
                      <v-btn
                        @click.prevent="erraseFilter()"
                        outlined
                        color="indigo"
                        >Effacer tous les filtres</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="pt-2">
                <v-col
                  cols="9"
                  class="pb-3"
                  xl="10"
                  lg="9"
                  style="padding-top: 0; padding-bottom: 0;"
                >
                  <v-btn
                    class="btn btn-primary mr-5"
                    @click.prevent="sendNewSearch"
                    :disabled="disabledSearch"
                    >Rechercher</v-btn
                  >
                </v-col>
              </v-row>
            </ValidateForm>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div>
      <v-row no-gutters="">
        <v-col>
          <UserListWithLicence
            :headers="headers"
            :items="usersWithLicence"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/utils/input";
// import DatePicker from "@/components/utils/datePicker"
import UserListWithLicence from "@/components/User/List/User__List__With__ValideLicence.vue";
import { mapActions } from "vuex";
import ValidateForm from "@/components/utils/form";

export default {
  components: {
    inputText,
    UserListWithLicence,
    ValidateForm,
  },

  data: () => ({
    search: {
      CT_Nom: "",
      CT_Prenom: "",
      LicenceNumero: "",
    },
    headers: [
      { text: "Numéro de licence", value: "LicenceNumero" },
      { text: "Nom", value: "CT_Nom" },
      { text: "Prénom", value: "CT_Prenom" },
      {
        text: "Structure",
        // value: "STR_StructureUtilisateurs[0].STR_Structure.NomStructure",
        value: "UTI_Licences[0].STR_Structure.NomStructure",
      },
      { text: "Date de naissance", value: "DateNaissance" },
      { text: "Licence", value: "LicenceType" },
      { text: "Assurance", value: "AssuranceType" },
      { text: "Certificat", value: "Certificat" },
    ],
    usersWithLicence: [],
    refreshForm: 1,

    loading: false,
    panel: 0,
    userListKey: 0,
  }),

  computed: {
    disabledSearch() {
      if (
        this.search.CT_Nom === "" &&
        this.search.CT_Prenom === "" &&
        this.search.LicenceNumero === ""
      )
        return true;
      return false;
    },
    whereClause() {
      let arrayClause = [];

      if (
        this.search.CT_Nom !== "" &&
        this.search.CT_Prenom !== "" &&
        this.search.LicenceNumero !== ""
      ) {
        return {
          CT_Nom: { _ilike: `%${this.search.CT_Nom}%` },
          CT_Prenom: { _ilike: `%${this.search.CT_Prenom}%` },
          LicenceNumero: { _eq: this.search.LicenceNumero },
        };
      }
      if (this.search.CT_Nom !== "") {
        arrayClause.push({ CT_Nom: { _ilike: `%${this.search.CT_Nom}%` } });
      }
      if (this.search.CT_Prenom !== "") {
        arrayClause.push({
          CT_Prenom: { _ilike: `%${this.search.CT_Prenom}%` },
        });
      }
      if (this.search.LicenceNumero !== "") {
        arrayClause.push({ LicenceNumero: { _eq: this.search.LicenceNumero } });
      }

      let where = {
        _and: arrayClause,
      };

      // End --  Setup the field restriction

      return where;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["searchUtlisateurWithLicence"]),
    ...mapActions("structure", ["getStructureList"]),

    erraseFilter() {
      this.search = {};
      this.usersWithLicence = [];
      //console.log(this.$refs.userList.setData)
    },
    async sendNewSearch() {
      await this.setData();
    },

    async setData() {
      this.loading = true;
      const response = await this.searchUtlisateurWithLicence({
        where: this.whereClause,
      });
      const test = response.filter((user) => user.DOC_Documents.length > 0);
      console.log(`test====>>>`, test);
      console.log(`response===>>>`, response);
      this.usersWithLicence = response.map((user) => {
        let doc_documents = [];
        user.DOC_Documents.forEach((document) => {
          if (
            !doc_documents
              .map((doc) => doc.LIST_DocumentType.SlugTypeDocument)
              .includes(document.LIST_DocumentType.SlugTypeDocument)
          ) {
            doc_documents.push(document);
          }
        });
        return {
          ...user,
          DN_Naissance: this.$moment(user.DN_DateNaissance).format(
            "DD/MM/YYYY"
          ),
          Assurance: user.UTI_Licences[0]?.UTI_LicenceAssurance.sort((a, b) =>
            a.ASSU_AssuranceType.ID_AssuranceCategorie >
            b.ASSU_AssuranceType.ID_AssuranceCategorie
              ? 1
              : -1
          ),
          DOC_Documents: doc_documents,
        };
      });
      // console.log("this.usersWithLicence", this.usersWithLicence);
      this.loading = false;
    },
  },

  props: {
    context: {
      type: Object,
    },
    actions: {
      type: String,
      default: "show",
    },
    show_select: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>

<style lang="scss" scoped>
.v-input--switch {
  padding: 0;
}
.ffme-form__select {
  .v-input__control {
    min-height: 30px !important;
  }
  .v-input__slot {
    margin: 0 !important;
    box-shadow: none !important;
    min-height: 20px;
    border: 1px solid #dfe5f9;
    //padding: 2px 5px 1px !important;
    font-weight: 200;
    font-size: 0.8rem;
    border-radius: 0;
    height: 29px;
  }

  // CHIPS
  .v-select--chips {
    .v-input__slot {
      border: none;
    }
    .v-input__icon .v-icon {
      font-size: 2rem;
      color: #0045ab;
    }
  }
  .v-chip.v-size--small {
    font-size: 0.8rem;
    background: #0045ab;
    color: white;
    font-weight: bold;
  }

  .v-input__slot .v-select__slot {
    border: none;

    .v-label {
      font-size: 0.8rem;
      padding-left: 4px;
    }
  }
  .v-select__selections {
    padding: 4px 5px !important;
  }
  .v-select__selection.v-select__selection--comma {
    margin: 0;
  }
  input {
    max-height: 20px;
    padding-left: 10px !important;
  }
  input[type="text"]:disabled {
    background: transparent !important;
  }
  .v-text-field__details {
    display: none;
  }
}

.filters-opened {
  height: calc(100vh - 440px);
}
</style>
