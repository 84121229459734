<template>
  <div>
    <v-row class="m-0">
      <userListSearchMergeDuplicate
        :context="{
          ID_Utilisateur: currentUserId,
          ID_UtilisateurDemande: currentAuthUserId,
          ID_Structure: currentStructureId,
          ID_Saison: selectedSaison.id,
          hasCodeFederal: hasCodeFederal,
        }"
        :actions="getActions"
        :firstCurrentUser="currentUser"
        :modalDuplicateOpen="modalDuplicateOpen"
        @onClose="close"
      />
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import SubBar from "@/components/Common/Common__SubBar.vue";
// import userListSearch from '@/components/User/List/User__ListSearch.vue';

export default {
  components: {
    userListSearchMergeDuplicate: () =>
      import("@/components/User/List/User__ListSearchMergeDuplicate.vue"),
  },

  data: () => ({}),

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "currentStructureType",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["currentUserId"]),

    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },

    getActions() {
      let actions = "show|mergeDuplicate";
      return actions;
    },
  },

  methods: {
    close() {
      console.log("merge duplicate closeeeeeeeeeeeee");
      this.$emit("onClose");
    },
  },

  props: {
    currentUser: {
      type: Object,
    },
    modalDuplicateOpen: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
