import { render, staticRenderFns } from "./User__List.vue?vue&type=template&id=480dffc1&scoped=true&"
import script from "./User__List.vue?vue&type=script&lang=js&"
export * from "./User__List.vue?vue&type=script&lang=js&"
import style0 from "./User__List.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./User__List.vue?vue&type=style&index=1&id=480dffc1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480dffc1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCheckbox,VChip,VDataTable,VIcon,VSkeletonLoader,VTooltip})
