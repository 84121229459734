<template>
  <div :class="getClassTable">
    <v-skeleton-loader
      v-if="!mounted"
      :loading="true"
      :boilerplate="false"
      type="table"
      class="mx-auto"
    />
    <div v-else>
      <div class="">
        <div v-if="simpleSearch" class="mb-1 d-flex col-9 align-center">
          <inputText
            append-outer-icon="mdi-magnify"
            class="w-75"
            v-model="searchString"
            placeholder="Rechercher : numéro de licence, nom, prénom"
          ></inputText>
          <v-icon class="icon">mdi-magnify</v-icon>
          <div>
            <button @click="refresh">
              <v-icon class="icon">mdi-refresh</v-icon>
            </button>
          </div>
        </div>

        <div
          v-if="actionsContains('editLicenceMultiple')"
          class="py-4 px-4"
          style="background: rgba(230, 230, 230, 1);"
        >
          <v-btn
            :disabled="!currentItemsSelected.length || !hasCodeFederal"
            @click="openEditLicenceModal"
            small
            rounded
            color="blue-dark text-white font-weight-bold"
          >
            Renouveler les licences sélectionnées
          </v-btn>

          <span v-if="!usersHasLicence">
            <v-btn
              @click="openCopyLicenceModal"
              small
              rounded
              color="blue-dark text-white font-weight-bold ml-2"
            >
              Copier les licences de la saison précedente
            </v-btn>

            <modalLicenceCopy
              v-if="modalCopyLicenceIsOpen"
              :context="context"
              :users="membres"
              :modalIsOpen="modalCopyLicenceIsOpen"
              @onUpdate="onUpdateLicence"
              @onClose="closeCopyLicenceModal"
            >
            </modalLicenceCopy>
          </span>

          <modalLicence
            v-if="modalEditLicenceIsOpen"
            :context="context"
            :itemsSelected="currentItemsSelected"
            :modalIsOpen="modalEditLicenceIsOpen"
            :licenceCount="currentItemsSelected.length"
            @onUpdate="onUpdateLicence"
            @onClose="closeEditLicenceModal"
          ></modalLicence>
        </div>
      </div>

      <v-data-table
        v-model="currentItemsSelected"
        :headers="headers"
        fixed-header
        :height="tableHeight"
        :loading="loading || isLoading"
        :items="filteredMembres"
        item-key="id"
        @item-selected="itemSelected"
        @update:options="optionUpdated"
        @input="itemListUpdated"
        :show-select="show_select"
        :singleSelect="false"
        :class="{
          'filters-opened': areFiltersOpened,
          'elevation-1 list__table': scrollTable,
        }"
        :custom-sort="customSort"
        :key="tableKey"
        :footer-props="{
          'items-per-page-options': perPageOption,
        }"
        :hide-default-footer="viewFooter"
        :item-class="rowClass"
      >
        <template #item.Select="{ item }">
          <!-- id fuse role select user to list -->
          <div v-if="isInIDFuseState">
            <v-checkbox
              input-value="false"
              off-icon="mdi-plus-thick"
              on-icon="mdi-minus-thick"
              @change="(event) => banUser(item, event)"
              background-color="#082b6e"
              color="white"
              value
              dense
              style="width: 25px !important;"
            >
            </v-checkbox>
          </div>

          <!-- for multiple action  -->
          <div v-else>
            <v-checkbox
              v-if="initalCheckbox"
              input-value="true"
              :value="initalCheckbox"
              :disabled="isDisabled(item)"
              @change="addUser(item)"
              dense
            ></v-checkbox>
          </div>
        </template>
        <template v-slot:item.LicenceNumero="{ item }">
          <LicenceNumero :item="item"></LicenceNumero>
        </template>

        <template v-slot:item.user.licenceNumero="{ item }">
          <v-chip v-if="item.user.licenceNumero" color="blue" small dark>
            {{ item.user.licenceNumero }}
          </v-chip>
          <v-chip v-else color="gray" x-small dark>NC</v-chip>
        </template>

        <template v-slot:item.DN_DateNaissance="{ item }">
          <DateDeNaissance :item="item"></DateDeNaissance>
        </template>

        <template v-slot:item.user.dnDateNaissance="{ item }">
          {{ $moment(item.user.dnDateNaissance).format("DD/MM/YYYY") }}
        </template>

        <template v-slot:item.structure.nomStructure="{ item }">
          <NomStructure :item="item" />
        </template>

        <template v-slot:item.CT_Nom="{ item }">
          <CTNom :item="item" />
        </template>

        <template v-slot:item.CT_Prenom="{ item }">
          <CTPrenom :item="item" />
        </template>

        <template v-slot:item.CT_Email="{ item }">
          <div v-if="item.UTI_Utilisateur.CT_Email">
            {{ item.UTI_Utilisateur.CT_Email }}
          </div>
          <v-chip v-else color="gray" x-small dark>NC</v-chip>
        </template>

        <template v-slot:item.FullName="{ item }">
          <div
            v-if="item.UTI_Utilisateur.CT_Prenom || item.UTI_Utilisateur.CT_Nom"
          >
            <span v-if="item.UTI_Utilisateur.CT_Nom">
              {{ item.UTI_Utilisateur.CT_Nom }}</span
            >
            <span v-if="item.UTI_Utilisateur.CT_Prenom">
              {{ item.UTI_Utilisateur.CT_Prenom }}</span
            >
          </div>
          <v-icon v-else color="gray" close>mdi-close</v-icon>
        </template>

        <template v-slot:item.EST_RoleFederal="{ item }">
          <v-icon v-if="item.EST_RoleFederal" color="green"
            >mdi-checkbox-marked-circle</v-icon
          >
          <v-icon v-else color="gray" close>mdi-close</v-icon>
        </template>

        <template v-slot:item.ID_LicenceSaison="{ item }">
          <LicenceSaison :item="item" :context="context"></LicenceSaison>
        </template>

        <template v-slot:item.ID_Role="{ item }">
          <v-chip
            v-if="item.ID_Role"
            small
            :color="item.ID_Role == 1 ? 'green' : 'blue'"
            text-color="white"
            >{{ item.ADM_RoleType.NomRole }}</v-chip
          >
          <v-chip v-else color="gray" x-small dark>NC</v-chip>
        </template>

        <template v-slot:item.ID_LicenceType="{ item }">
          <LicenceType
            :item="item"
            :context="context"
            :saisonInput="saisonInput"
          ></LicenceType>
        </template>

        <template v-slot:item.Assurance="{ item }">
          <Assurance :item="item" :context="context"></Assurance>
        </template>

        <template v-slot:item.Certificat="{ item }">
          <Certificat :item="item" :context="context"></Certificat>
        </template>

        <template v-slot:item.ID_EtapeLicence="{ item }">
          <div v-if="StatusLicence(item)">
            <v-chip :color="colorStatusLicence(StatusLicence(item))" small dark>
              {{ StatusLicence(item).EtapeValidationLicence.NomEtapeLicence }}
            </v-chip>
          </div>
          <v-chip v-else color="gray" x-small dark
            >Aucune licence cette saison</v-chip
          >
        </template>
        <template>
          <div>type de licence</div>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-items-center">
            <ActionShowUser
              :users="filteredMembres"
              v-if="actionsContains('show') && hasCodeFederal"
              :user="item"
              :allUser="membres"
              :context="context"
            ></ActionShowUser>

            <div v-if="showFusion">
              <span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="openComponent(item)"
                      class="mx-1"
                      fab
                      x-small
                      outlined
                      color="blue"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>Sélectionner cet adhérent pour fusion</span>
                </v-tooltip>
              </span>
            </div>

            <ActionAddToStructure
              v-if="actionsContains('addToStructure') && hasCodeFederal"
              :user="item"
              :context="context"
            ></ActionAddToStructure>
            <ActionEditRoleModule
              v-if="
                (actionsContains('editRole') &&
                  item.EST_RoleFederal &&
                  isFFME) ||
                (actionsContains('editRole') &&
                  item.EST_RoleFederal &&
                  sameStructure(item))
              "
              :user="item"
              :context="context"
              @onUpdate="onUpdateRole"
            ></ActionEditRoleModule>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="openConfirmationDelete(item)"
                  class="mx-1"
                  fab
                  x-small
                  outlined
                  color="red"
                  v-if="
                    (!isConsultant &&
                      actionsContains('editRole') &&
                      item.EST_RoleFederal &&
                      isFFME) ||
                    (!isConsultant &&
                      actionsContains('editRole') &&
                      item.EST_RoleFederal &&
                      sameStructure(item))
                  "
                >
                  <v-icon color="red"> mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Supprimer le rôle fédéral</span>
            </v-tooltip>

            <ActionSelectUser
              v-if="actionsContains('selectUser') && hasCodeFederal"
              :user="item"
              :context="context"
              @onSelected="onUserSelected"
            ></ActionSelectUser>
            <ActionEditLicenceRow
              v-if="
                actionsContains('editLicence') &&
                hasCodeFederal &&
                !setItem(item)
              "
              :user="item"
              :context="context"
              @onUpdate="onUpdateLicence"
            ></ActionEditLicenceRow>
            <ActionEditLicenceSiegeRow
              v-if="actionsContains('editLicenceSiege') && hasCodeFederal"
              :user="item"
              :context="context"
              @onUpdate="onUpdateLicence"
            ></ActionEditLicenceSiegeRow>
          </div>
        </template>
      </v-data-table>
    </div>
    <YesNo
      :showDialog="openModal"
      title="CONFIRMATION"
      :text="
        'Souhaitez-vous supprimer le rattachement/rôle de ' +
        userFullName +
        ' de la structure ?'
      "
      @answerYes="deleteUserFromStructure"
      @answerNo="openModal = false"
      @close="openModal = false"
    ></YesNo>
  </div>
</template>

<script>
/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import Assurance from "@/components/User/List/User__ListAssurance.vue";
import Certificat from "@/components/User/List/User__ListCertificat.vue";
import DateDeNaissance from "@/components/User/List/User__ListDateDeNaissance.vue";
import LicenceNumero from "@/components/User/List/User__ListLicenceNumero.vue";
import LicenceType from "@/components/User/List/User__ListLicenceType.vue";
import { mapActions, mapGetters, mapState } from "vuex";

import CTNom from "@/components/User/List/User__ListCTNom.vue";
import CTPrenom from "@/components/User/List/User__ListCTPrenom.vue";
import LicenceSaison from "@/components/User/List/User__ListLicenceSaison.vue";
import NomStructure from "@/components/User/List/User__ListNomStructure.vue";
import YesNo from "@/components/utils/modals/yes-no.vue";

import ActionShowUser from "@/components/User/List/User__ListActionShowUser.vue";

import ActionAddToStructure from "@/components/User/List/User__ListActionAddToStructure.vue";
import ActionEditLicenceRow from "@/components/User/List/User__ListActionEditLicence.vue";
import ActionEditLicenceSiegeRow from "@/components/User/List/User__ListActionEditLicenceSiege.vue";
import ActionEditRoleModule from "@/components/User/List/User__ListActionRoleModule.vue";
import ActionSelectUser from "@/components/User/List/User__ListActionSelectUser.vue";

import modalLicence from "@/components/utils/modals/Licence/Licence__Modal.vue";
import modalLicenceCopy from "@/components/utils/modals/Licence/LicenceCopy__Modal.vue";

import inputText from "@/components/utils/input.vue";
import { bus } from "@/main";
import { success_notification } from "@/plugins/notifications";

export default {
  components: {
    LicenceNumero,
    DateDeNaissance,
    LicenceType,
    CTNom,
    CTPrenom,
    LicenceSaison,
    modalLicence,
    modalLicenceCopy,
    ActionEditLicenceRow,
    ActionEditLicenceSiegeRow,
    ActionShowUser,
    ActionEditRoleModule,
    ActionSelectUser,
    ActionAddToStructure,
    inputText,
    YesNo,
    Assurance,
    Certificat,
    NomStructure,
    // ActionSelectUserMergeDuplicate,
  },

  data: () => ({
    mounted: false,
    currentItemsSelected: [],
    loading: false,
    structure: null,
    userToCM: [],
    membres: [],
    filteredMembres: [],
    modalEditLicenceIsOpen: false,
    modalCopyLicenceIsOpen: false,
    tableKey: 1,
    searchString: "",
    infoUser: {},
    openModal: false,
    federal: false,
    itemsPerPage: 0,
    isAbleToEditRole: false,
    perPageOption: [30, 60, 90, -1],
    modalMergeDuplicateIsOpen: false,
    infoUserSelected: {},
    userDeSelected: [],
    membresWithoutCurrentLHC: [],
  }),

  async created() {
    await this.getListEtapeLicence();
    await this.setData();
    bus.$on("onUserListStructureUpdated", async () => {
      //return await this.setData();
      this.$emit("hasToReload");
    });
    this.mounted = true;
    this.federal = this.roles[0].EST_RoleFederal;
  },

  computed: {
    ...mapGetters("structure", [
      "getStructure",
      "currentStructureType",
      "hasCodeFederal",
      "currentStructure",
    ]),
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("auth", ["roles"]),
    ...mapGetters("user", ["getRole"]),
    ...mapState({
      initalCheckbox: (state) => state.structure.initalCheckbox,
      isInIDFuseState: (state) => state.utilisateurs.isInIDFuseState,
    }),
    ...mapGetters("saisons", ["currentSaisonId"]),
    tableHeight() {
      const height = window.innerHeight;
      if (!this.areFiltersOpened) return `${height - 300}px`;
      return `${height - 495}px`;
    },
    isConsultant() {
      if (!this.getRole) return false;
      if (this.getRole?.SlugRole === "club_consultation_utilisateur")
        return true;
      return false;
    },

    userFullName() {
      if (this.infoUser) {
        return `${this.infoUser.UTI_Utilisateur?.CT_Prenom} ${this.infoUser.UTI_Utilisateur?.CT_Nom}`;
      }
      return null;
    },

    getClassTable() {
      let className = "table__user_list";
      if (this.simpleSearch) {
        className += " simpleSearch";
      }
      if (this.actionsContains("editLicenceMultiple")) {
        className += " buttonLine";
      }

      if (this.isModule == "saisirUneLicence") {
        className += " saisirLicence";
      }

      if (this.isModule == "MembresFederaux") {
        className += " membresFederaux";
      }

      return className;
    },

    scrollTable() {
      if (this.panel === 0 || this.itemsPerPage > 10 || this.itemsPerPage == -1)
        return true;
      return false;
    },

    usersHasLicence() {
      let has = true;
      this.membres.every((membre) => {
        if (!this.StatusLicence(membre)) has = false;
      });
      return has;
    },
    Saisons() {
      return [
        { ID_saison: 2021 },
        { ID_saison: 2020 },
        { ID_saison: 2019 },
        { ID_saison: 2018 },
      ];
    },
    isFFME() {
      if (!this.getRole) return false;
      return (
        this.getRole?.SlugRole == "super_administrateur" ||
        this.getRole?.SlugRole == "administrateur_vie_territoriale"
      );
    },
  },

  methods: {
    ...mapActions("structure", ["getStructureMembre", "loadStructure"]),
    ...mapActions("basics", ["getListEtapeLicence"]),
    ...mapActions("utilisateurs", [
      "removeUserFromStructure",
      "updateEstActif",
    ]),
    ...mapActions("auth", ["initRolesAndPermissions"]),
    ...mapActions("admin", [
      "editStrucutreUtilisateurRole",
      "removeAllUserModule",
    ]),
    async refresh() {
      this.mounted = false;

      await this.setData();

      bus.$on("onUserListStructureUpdated", async () => {
        this.$emit("hasToReload");
      });

      this.mounted = true;
    },
    rowClass(item) {
      if (!this.isInIDFuseState) return "";
      if (this.userDeSelected.some((user) => user === item.UTI_Utilisateur.id))
        return "test-class";
    },
    isDisabled(item) {
      if (this.userListSelected.length < 10) return false;
      if (this.userListSelected.some((user) => user.id === item.id))
        return false;
      return true;
    },
    setItem(item) {
      console.log(
        "setItem saison",
        item.UTI_Licence.some(
          (licence) => licence.ID_Saison == this.currentSaisonId
        )
      );
      return item.UTI_Licence.some(
        (licence) => licence.ID_Saison == this.currentSaisonId && licence.EST_Actif === true
      );
    },
    openConfirmationDelete(item) {
      this.infoUser = { ...item };
      this.openModal = true;
    },
    addUser(item) {
      this.$emit("updateCM", item);
    },

    openModalMergeDuplicate(item) {
      this.modalMergeDuplicateIsOpen = true;
      this.infoUserSelected = { ...item };
    },
    // id fuse
    banUser(item, event) {
      this.$emit("banUser", item);
      if (!event) {
        return this.userDeSelected.push(item.UTI_Utilisateur.id);
      }
      this.userDeSelected.splice(
        this.userListSelected.indexOf(item.UTI_Utilisateur.id),
        1
      );
    },
    async deleteUserFromStructure() {
      console.log("deleteUserFromStructure", this.infoUser.STR_Structure);
      let payload = {
        role: {
          ID_Structure: this.context.ID_Structure,
          ID_Utilisateur: this.infoUser.ID_Utilisateur,
          ID_Role: null,
          id: this.infoUser.id,
        },
        user: {
          ID_Structure: this.infoUser.ID_Structure,
          ID_Utilisateur: this.infoUser.ID_Utilisateur,
          ID_Role: this.infoUser.id,
        },
      };
      try {
        // turn user to non actif
        await this.removeUserFromStructure({
          ID_Utilisateur: this.infoUser.ID_Utilisateur,
          EST_RoleFederal: false,
          ID_Structure: parseInt(this.infoUser.ID_Structure),
        });

        // update his role
        await this.editStrucutreUtilisateurRole(payload);
        //delete all modules that have been granted to him
        success_notification(
          `${this.userFullName} n'a plus de rôles fédéraux au sein de la structure`
        );
        // if role is not from club we delete the link
        // if (this.infoUser.STR_Structure?.ID_TypeStructure !== 1) {
        //   let payloadUpdateEstActif = {
        //     ID_Structure: this.infoUser.ID_Structure,
        //     ID_Utilisateur: this.infoUser.ID_Utilisateur,
        //   };
        //   await this.updateEstActif(payloadUpdateEstActif);
        // }

        bus.$emit("reloadListStrUti");
      } catch (error) {
        console.log(error);
      }
      this.openModal = false;
    },

    itemSelected(item) {},
    optionUpdated(option) {
      this.itemsPerPage = option.itemsPerPage;
    },
    itemListUpdated(items) {},

    closeEditLicenceModal() {
      this.modalEditLicenceIsOpen = false;
    },

    openEditLicenceModal() {
      this.modalEditLicenceIsOpen = true;
    },

    openCopyLicenceModal() {
      this.modalCopyLicenceIsOpen = true;
    },

    closeCopyLicenceModal() {
      this.modalCopyLicenceIsOpen = false;
    },

    async setData() {
      this.loading = true;
      await this.initRolesAndPermissions();
      if (this.hasItems) {
        if (this.isLHC) {
          this.membresWithoutCurrentLHC = [];
        } else {
          this.membresWithoutCurrentLHC = this.items?.filter((item) => {
            return item.season.id === this.context.ID_Saison;
          });
        }

        this.membres = this.items;
        this.filteredMembres = this.actionsContains("editLicenceMultiple")
          ? this.membresWithoutCurrentLHC
          : this.membres;
      } else {
        let payload = {
          ID_Structure: this.context.ID_Structure,
          ID_Saison: this.context.ID_Saison,
        };
        // Get the good wrap of user
        let arrayClause = {
          EST_Actif: { _eq: true },
          ID_Utilisateur: { _is_null: false },
          // ********* Afficher tous les membres rattachés à la structure ayant ou non une licence
          // UTI_Licence: { ID_Saison: { _eq: this.context.ID_Saison } },
        };
        if (!this.context.onlyCurrentStructure) {
          if (this.currentStructureType.SlugTypeStructure == "ligue") {
            arrayClause.STR_Structure = {
              ID_Ligue: { _eq: this.context.ID_Structure },
            };
          } else if (
            this.currentStructureType.SlugTypeStructure == "comite_territorial"
          ) {
            arrayClause.STR_Structure = {
              ID_ComiteTerritorial: { _eq: this.context.ID_Structure },
            };
          } else if (
            this.currentStructureType.SlugTypeStructure ==
            "service_federal_ffme"
          ) {
            arrayClause.STR_Structure = {
              ID_Federation: { _eq: this.context.ID_Structure },
            };
          } else {
            arrayClause.ID_Structure = { _eq: this.context.ID_Structure };
          }
        } else {
          arrayClause.ID_Structure = { _eq: this.context.ID_Structure };
        }
        payload.where = { ...arrayClause };

        let structureMembre = await this.getStructureMembre(payload);
        console.log("ato :>> ", structureMembre);
        // let temp = structureMembre;
        if (this.newUser && this.newUser[0]) {
          const filter = structureMembre.filter(
            (str) => str.id == this.newUser[0].id
          );
          if (!filter[0]) {
            structureMembre.push(this.newUser[0]);
          }
        }

        this.membres = [
          ...structureMembre.sort((a, b) => {
            let fa = a.UTI_Utilisateur.CT_Nom.toLowerCase(),
              fb = b.UTI_Utilisateur.CT_Nom.toLowerCase();
            if (fa < fb) return -1;
            if (fa > fb) return 1;
            return 0;
          }),
        ];
        let membresWithoutCurrentLicence = this.membres?.filter((item) => {
          const licence =
            item &&
            item.UTI_Licence &&
            item.UTI_Licence.filter(
              (lic) => lic.ID_Saison == this.context.ID_Saison
            );
          return !licence || !licence[0];
        });
        this.filteredMembres = this.actionsContains("editLicenceMultiple")
          ? membresWithoutCurrentLicence
          : this.membres;
      }

      this.loading = false;

      if (this.showFusion) {
        this.filteredMembres = this.filteredMembres.filter(
          (member) =>
            member.user.id !=
            this.firstCurrentUser.UTI_Utilisateur.id
        );
      }
    },

    async onUpdateLicence(items) {
      this.$emit("onUpdate", items);
      this.currentItemsSelected = [];
    },

    onUpdateRole() {},

    log(item) {},

    onUserSelected(user) {
      this.$emit("onUserSelected", user);
    },

    StatusLicence(item) {
      // Check if w   have licence fot this saisons...
      if (!item?.UTI_Licence[0]) return false;
      // just to be sur to return a unic object, normaly just one licence per year but, who knows
      return item?.UTI_Licence[0];
    },

    colorStatusLicence(licence) {
      let licenceEnAttente = this.getList.LIST_EtapeValidationLicence.find(
        (lic) => lic.SlugEtapeLicence == "adhesion_en_attente"
      );
      let licenceAttenteRenouv = this.getList.LIST_EtapeValidationLicence.find(
        (lic) => lic.SlugEtapeLicence == "en_attente_de_renouvellement"
      );
      let licenceValide = this.getList.LIST_EtapeValidationLicence.find(
        (lic) => lic.SlugEtapeLicence == "adhesion_valide"
      );

      if (licence.ID_EtapeLicence == licenceEnAttente.id) {
        return "blue";
      }
      if (licence.ID_EtapeLicence == licenceAttenteRenouv.id) {
        return "red";
      }
      if (licence.ID_EtapeLicence == licenceValide.id) {
        return "green";
      }
    },

    customSort(data, index, isDesc) {
      data.sort((a, b) => {
        const filterA = a && a.UTI_Licence && a.UTI_Licence[0];
        const filterB = b && b.UTI_Licence && b.UTI_Licence[0];
        if (index[0] == "ID_LicenceType") {
          const saisonIdA = filterA && filterA.ID_Saison;
          const saisonIdB = filterB && filterB.ID_Saison;
          let saisonNameA =
            filterA &&
            filterA.LIST_LicenceType &&
            filterA.LIST_LicenceType.NomTypeLicence;
          let saisonNameB =
            filterB &&
            filterB.LIST_LicenceType &&
            filterB.LIST_LicenceType.NomTypeLicence;
          saisonNameB = saisonNameB === undefined ? "Zzz" : saisonNameB;
          saisonNameA = saisonNameA === undefined ? "Zzz" : saisonNameA;
          saisonNameB = saisonNameB === null ? "Zzz" : saisonNameB;
          saisonNameA = saisonNameA === null ? "Zzz" : saisonNameA;
          if (!isDesc[0]) {
            if (saisonNameA.toLowerCase() > saisonNameB.toLowerCase()) {
              return -1;
            } else if (saisonNameA.toLowerCase() < saisonNameB.toLowerCase()) {
              return 1;
            } else {
              if (saisonIdA > saisonIdB) {
                return -1;
              } else if (saisonIdA < saisonIdB) {
                return 1;
              } else return 0;
            }
          } else {
            if (saisonNameB.toLowerCase() > saisonNameA.toLowerCase()) {
              return -1;
            } else if (saisonNameB.toLowerCase() < saisonNameA.toLowerCase()) {
              return 1;
            } else {
              if (saisonIdB > saisonIdA) {
                return -1;
              } else if (saisonIdB < saisonIdA) {
                return 1;
              } else return 0;
            }
          }
        }
        if (index[0] == "LicenceNumero") {
          if (!isDesc[0]) {
            return ("" + b.UTI_Utilisateur.LicenceNumero).localeCompare(
              a.UTI_Utilisateur.LicenceNumero
            );
          } else {
            return ("" + a.UTI_Utilisateur.LicenceNumero).localeCompare(
              b.UTI_Utilisateur.LicenceNumero
            );
          }
        }

        if (index[0] == "CT_Nom") {
          if (!isDesc[0]) {
            return ("" + b.UTI_Utilisateur.CT_Nom).localeCompare(
              a.UTI_Utilisateur.CT_Nom
            );
          } else {
            return ("" + a.UTI_Utilisateur.CT_Nom).localeCompare(
              b.UTI_Utilisateur.CT_Nom
            );
          }
        }

        if (index[0] == "CT_Prenom") {
          if (!isDesc[0]) {
            return ("" + b.UTI_Utilisateur.CT_Prenom).localeCompare(
              a.UTI_Utilisateur.CT_Prenom
            );
          } else {
            return ("" + a.UTI_Utilisateur.CT_Prenom).localeCompare(
              b.UTI_Utilisateur.CT_Prenom
            );
          }
        }

        if (index[0] == "DN_DateNaissance") {
          if (!isDesc[0]) {
            return (
              new Date(b.UTI_Utilisateur.DN_DateNaissance) -
              new Date(a.UTI_Utilisateur.DN_DateNaissance)
            );
          } else {
            return (
              new Date(a.UTI_Utilisateur.DN_DateNaissance) -
              new Date(b.UTI_Utilisateur.DN_DateNaissance)
            );
          }
        }

        if (index[0] == "CT_Email") {
          if (!isDesc[0]) {
            return ("" + b.UTI_Utilisateur.CT_Email).localeCompare(
              a.UTI_Utilisateur.CT_Email
            );
          } else {
            return ("" + a.UTI_Utilisateur.CT_Email).localeCompare(
              b.UTI_Utilisateur.CT_Email
            );
          }
        }

        if (index[0] == "NomStructure") {
          if (!isDesc[0]) {
            return ("" + b.STR_Structure.NomStructure).localeCompare(
              a.STR_Structure.NomStructure
            );
          } else {
            return ("" + a.STR_Structure.NomStructure).localeCompare(
              b.STR_Structure.NomStructure
            );
          }
        }

        if (index[0] == "ID_EtapeLicence") {
          let userA = this.StatusLicence(a);
          let userB = this.StatusLicence(b);
          if (!userA) userA = { ID_EtapeLicence: null };
          if (!userB) userB = { ID_EtapeLicence: null };

          // equal items sort equally
          if (userA.ID_EtapeLicence === userB.ID_EtapeLicence) {
            return 0;
          }
          // nulls sort after anything else
          else if (userA.ID_EtapeLicence === null) {
            return 1;
          } else if (userB.ID_EtapeLicence === null) {
            return -1;
          }
          // otherwise, if we're ascending, lowest sorts first
          else if (!isDesc[0]) {
            return userA.ID_EtapeLicence < userB.ID_EtapeLicence ? -1 : 1;
          }
          // if descending, highest sorts first
          else {
            return userA.ID_EtapeLicence < userB.ID_EtapeLicence ? 1 : -1;
          }
        }

        if (index[0] == "EST_RoleFederal") {
          if (!isDesc[0]) {
            return b.EST_RoleFederal - a.EST_RoleFederal;
          } else {
            return a.EST_RoleFederal - b.EST_RoleFederal;
          }
        }

        if (index[0] == "ID_Role") {
          // equal items sort equally
          if (a.ID_Role === b.ID_Role) {
            return 0;
          }
          // nulls sort after anything else
          else if (a.ID_Role === null) {
            return 1;
          } else if (b.ID_Role === null) {
            return -1;
          }
          // otherwise, if we're ascending, lowest sorts first
          else if (!isDesc[0]) {
            return a.ID_Role < b.ID_Role ? -1 : 1;
          }
          // if descending, highest sorts first
          else {
            return a.ID_Role < b.ID_Role ? 1 : -1;
          }
        }
      });
      return data;
    },

    actionsContains(act) {
      let actions = this.actions.split("|");
      return actions.find((action) => action == act);
    },
    sameStructure(item) {
      const isSameStructure =
        item.STR_Structure.id === this.currentStructure.STR_Structure.id;
      return isSameStructure;
    },

    filterMember(val) {
      let membres = this.membres.filter((mb) => {
        if (!val) return true;

        if (mb?.UTI_Utilisateur?.LicenceNumero !== null) {
          if (
            mb?.UTI_Utilisateur?.LicenceNumero.toString()
              .toLowerCase()
              .includes(val.toLowerCase())
          ) {
            return true;
          }
        }

        if (mb?.UTI_Utilisateur?.CT_Nom !== null) {
          if (
            mb?.UTI_Utilisateur?.CT_Nom.toLowerCase().includes(
              val.toLowerCase()
            )
          ) {
            return true;
          }
        }

        if (mb?.UTI_Utilisateur?.CT_Prenom !== null) {
          if (
            mb?.UTI_Utilisateur?.CT_Prenom.toLowerCase().includes(
              val.toLowerCase()
            )
          ) {
            return true;
          }
        }

        if (mb?.STR_Structure?.NomStructure !== null) {
          if (
            mb?.STR_Structure?.NomStructure.toLowerCase().includes(
              val.toLowerCase()
            )
          ) {
            return true;
          }
        }
        return false;
      });

      let membresWithoutCurrentLicence = membres?.filter((item) => {
        const licence = item?.UTI_Licence?.filter(
          (lic) => lic?.ID_Saison == this.context.ID_Saison
        );
        return !licence || !licence[0];
      });
      this.filteredMembres = this.actionsContains("editLicenceMultiple")
        ? membresWithoutCurrentLicence
        : membres;
    },

    openComponent(item) {
      const modalDuplicateIsOpen = true;
      this.$emit("modalIsOpen", modalDuplicateIsOpen, item);
    },
  },

  watch: {
    searchString(val) {
      this.filterMember(val);
    },
  },

  props: {
    saisonInput: {
      type: Number,
    },
    context: {
      type: Object,
    },
    headers: {
      type: Array,
    },
    show_select: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: String,
      default: "",
    },
    propStructure: {
      type: Object,
    },

    panel: {
      type: Number,
    },

    dephResult: {
      type: Boolean,
      default: false,
    },

    isModule: {
      type: String,
      default: "",
    },

    simpleSearch: {
      type: Boolean,
      default: false,
    },
    isLoading: { type: Boolean, default: false },

    items: {
      type: Array,
    },

    onlyCurrentStructure: {
      type: Boolean,
      default: false,
    },

    hasItems: {
      type: Boolean,
      default: false,
    },
    isLHC: {
      type: Boolean,
      default: false,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    newUser: {
      type: Array,
    },
    firstCurrentUser: {
      type: Object,
    },
    userListSelected: {
      type: Array,
      default: () => [],
    },
    viewFooter: {
      type: Boolean,
      default: false,
    },
    showFusion: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.test-class {
  opacity: 0.3;
}

.test-class i {
  color: white !important;
}

.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}

.bg-blue-mode {
  background: #39558b !important;
}

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.table__user_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 275px);
  }

  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}
</style>
<style scoped>
::v-deep .v-data-table-header th {
  background: #39558b !important;
  color: white !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}

::v-deep .v-data-table-header__icon {
  color: white !important;
}

::v-deep .id-fuse-table td {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000;
}
</style>
