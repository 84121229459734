<template>
  <div v-if="NomStructure">{{ NomStructure }}</div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    NomStructure() {
      if (this.item.product.slug.includes('hors_club')) {
        return 'Licence HORS CLUB';
      }

      if (this.structure && this.structure.nomStructure) {
        return this.structure.nomStructure;
      }

      return false;
    },

    structure() {
      if (this.item.structure) {
        return this.item.structure;
      }

      return false;
    },
  },

  props: {
    item: {
      type: Object,
    },
    utiFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
