<template>
  <div v-if="dateDeNaissance">{{ dateDeNaissance }}</div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    dateDeNaissance() {
      if (this.utiFirst) {
        if (this.item.DN_DateNaissance) {
          return this.$moment(this.item.DN_DateNaissance).format("DD/MM/YYYY");
        }
        return false;
      }

      if (this.item.UTI_Utilisateur.DN_DateNaissance) {
        return this.$moment(this.item.UTI_Utilisateur.DN_DateNaissance).format(
          "DD/MM/YYYY"
        );
      }
      return false;
    },
  },

  props: {
    item: {
      type: Object,
    },
    utiFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
