<template>
  <v-row
    no-gutters
    class="gestion_des_utilisateurs__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="10" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;" class="mt-5">
            <v-col cols="4">
              <v-card
                height="100%"
                width="100%"
                style="max-height: 450px;"
                class="p-3 d-flex flex-column justify-center"
              >
                <v-card-text class="text-center pb-0">
                  <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                    {{ getNameMemberStructure }}
                  </p>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <a
                    :href="linkLicenceUsers"
                    class="subtitle-2 font-weight-light"
                  >
                    <v-btn icon exact>
                      <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                    </v-btn>
                  </a>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="4" v-if="!isClub">
              <dashboardCard
                title_card="Membres fédéraux"
                module_name="gestion_des_utilisateurs__membres_federaux_du_club"
                auth
              />
            </v-col>

            <v-col v-if="isClub || isCT || isLigue" cols="4">
              <v-card
                height="100%"
                width="100%"
                style="max-height: 450px;"
                class="p-3 d-flex flex-column justify-center"
              >
                <v-card-text class="text-center pb-0">
                  <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                    Vérification licence niveau national
                  </p>
                </v-card-text>

                <v-card-actions class="justify-center">
                  <a
                    :href="linkLicenceSearch"
                    class="subtitle-2 font-weight-light"
                  >
                    <v-btn icon exact>
                      <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                    </v-btn>
                  </a>
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col v-if="isFFME" cols="4">
              <dashboardCard
                title_card="Demandes de modification"
                module_name="demandes_de_modification__gestion_des_demandes"
                auth
              />
            </v-col>

            <v-col cols="4" v-if="isFFME">
              <dashboardCard
                module_name="gestion_des_licences__saisir_une_licence_siege"
                title_card="Saisir une licence siège"
                auth
              />
            </v-col>
            <!-- <v-col cols="4" v-if="isFFME">
              <dashboardCard
                module_name="gestion_des_animations_locale__liste_licences_hors_club"
                title_card="Licences hors club"
                auth
              />
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DashboardCard from '@/components/Common/DashboardCard';
export default {
  name: 'GestionDesLicences__Dashboard',

  components: { DashboardCard },

  data: () => ({
    cards: [],
    loading: true,
  }),
  async created() {
    await this.setAppTitle({ title: 'Gestion des adhérents' });
    this.loading = false;
  },

  methods: {
    ...mapActions('app', ['setAppTitle']),
  },

  computed: {
    ...mapGetters('structure', ['currentStructure', 'currentStructureId', 'currentStructureType']),

    getNameMemberStructure() {
      if (this.isClub) return 'Membres du club';
      let type = this.currentStructureType.SlugTypeStructure;
      if (type == 'ligue') {
        return 'Membres de la ligue';
      } else if (type == 'comite_territorial') {
        return 'Membres du CT';
      } else {
        return 'Membres de la structure';
      }
    },

    isClub() {
      return this.currentStructureType.EST_Club;
    },

    isFFME() {
      return (
        this.currentStructureType.SlugTypeStructure == 'service_federal_ffme'
      );
    },

    isCT() {
      return (
        this.currentStructureType.SlugTypeStructure == 'comite_territorial'
      );
    },

    isLigue() {
      return this.currentStructureType.SlugTypeStructure == 'ligue';
    },
    linkLicenceSearch() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/recherche?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
    linkDiscoveryLicence() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/licence-decouverte/nouvelle?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
    linkLicenceUsers() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/utilisateurs?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    }
  },
};
</script>

<style lang="scss">
.gestion_des_utilisateurs__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
