<template>
  <div class>
    <LoadingIndicator
      :loading=!mounted
      :progress=false
    />
    <modalUserDetail
      v-if="modalIsOpen && mounted && user"
      :modalIsOpen="modalIsOpen"
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentAuthUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
        hasCodeFederal: hasCodeFederal,
        user,
        fromModal: true
       }"
      :user="user"
      @onClose="closeModal"
      :areNextPrevDisabled="true"
      :showFusion="true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalUserDetail from "@/components/utils/modals/User/User__Modal.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { error_notification } from "@/plugins/notifications.js";
import { getLicences } from "@/plugins/licenceService";

export default {
  components: { modalUserDetail, LoadingIndicator },

  data: () => ({
    modalIsOpen: false,
    mounted: false,
    membres: [],
    user: undefined,
    back: undefined,
  }),

  async created() {
    let whereClause = {
      EST_Actif: { _eq: true },
      ID_Utilisateur: { _eq: this.$route.params.id }
    };
    if (this.currentStructureType.SlugTypeStructure == "ligue") {
      whereClause.STR_Structure = {
        ID_Ligue: { _eq: this.currentStructureId },
      };
    } else if (
      this.currentStructureType.SlugTypeStructure == "comite_territorial"
    ) {
      whereClause.STR_Structure = {
        ID_ComiteTerritorial: { _eq: this.currentStructureId },
      };
    } else if (
      this.currentStructureType.SlugTypeStructure ==
      "service_federal_ffme"
    ) {
      whereClause.STR_Structure = {
        _or: [{ID_Federation: { _eq: this.currentStructureId }}, {id: { _eq: this.currentStructureId }}]
      };
    } else {
      whereClause.ID_Structure = { _eq: this.currentStructureId };
    }
    this.membres = await this.getStructureMembre({
      where: {
        ...whereClause
      }
    });

    if (this.$route.query.from) {
      this.back = Buffer.from(this.$route.query.from, 'base64').toString('ascii');
    }

    this.user = this.membres.find((membre) => membre.ID_Utilisateur === this.$route.params.id);

    let licences = await getLicences({user: this.user.ID_Utilisateur});

    this.user = {
      ...this.user,
      licences: licences
    }

    if (!this.user) {
      this.mounted = true;
      error_notification('Utilisateur inconnu ou ne faisant pas partie de votre périmètre de visibilité');
      this.$router.push({
        path: "/",
      })
    } else {
      this.openModal()
      this.mounted = true;
    }
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureType",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["currentUserId"]),

    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },
  },

  methods: {
    ...mapActions("structure", ["getStructureMembre"]),
    closeModal() {
      this.modalIsOpen = false;
      this.routeToNext();
    },

    openModal() {
      this.modalIsOpen = true;
    },

    routeToNext() {
      window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/utilisateurs?appMode=${localStorage.getItem("ffme_user_mode")}${this.back ? '&' + this.back : ''}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
