<template>
  <div>
    <subBar
      title="Vérification des licences au niveau national"
      route_name="gestion_des_utilisateurs__dashboard"
    ></subBar>

    <userListSearchVerificationLicence
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentAuthUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
        hasCodeFederal: hasCodeFederal,
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import subBar from "@/components/Common/Common__SubBar.vue";
import userListSearchVerificationLicence from "@/components/User/List/User__ListSearchVerificationLicence.vue";

export default {
  components: {
    subBar,
    userListSearchVerificationLicence,
  },

  data: () => ({}),

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "hasCodeFederal",
      "currentStructureId",
    ]),
    ...mapGetters("saisons", ["currentSaisonId", "selectedSaison"]),
    ...mapGetters("user", ["currentUserId"]),

    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },
  },

  methods: {},
};
</script>

<style>
.v-text-field__details {
  display: none;
}

.v-text-field__slot input {
  padding-left: 20px;
  padding-right: 20px;
}
</style>
