var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__structure_list"},[_c('v-data-table',{staticClass:"elevation-1 list__table",class:{ 'filters-opened': _vm.areFiltersOpened },attrs:{"headers":_vm.headers,"fixed-header":true,"loading":_vm.loading,"items":_vm.users,"item-key":"id","custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.LicenceNumero",fn:function(ref){
var item = ref.item;
return [(item.LicenceNumero)?_c('v-chip',{attrs:{"color":"blue","small":"","dark":""}},[_vm._v(_vm._s(_vm.licenceNumero(item.LicenceNumero)))]):_vm._e()]}},{key:"item.DateNaissance",fn:function(ref){
var item = ref.item;
return [(item.DN_DateNaissance)?_c('span',[_vm._v(_vm._s(_vm.$moment(item.DN_DateNaissance).format("DD/MM/YYYY")))]):_c('span',[_vm._v("Date invalide")])]}},{key:"item.LicenceType",fn:function(ref){
var item = ref.item;
return [(
          item.UTI_Licences[0] &&
          item.UTI_Licences[0].LIST_LicenceType &&
          item.UTI_Licences[0].LIST_LicenceType.NomTypeLicence
        )?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" "+_vm._s(((item.UTI_Licences[0] && item.UTI_Licences[0].LIST_LicenceType && item.UTI_Licences[0].LIST_LicenceType.NomTypeLicence) + " - " + (item.UTI_Licences[0] && item.UTI_Licences[0].ID_Saison)))+" ")]):_vm._e()]}},{key:"item.AssuranceType",fn:function(ref){
        var item = ref.item;
return _vm._l((item.Assurance),function(licence,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":licence.ASSU_AssuranceType.ID_AssuranceCategorie === 2
            ? 'success'
            : 'primary',"pill":""}},[_vm._v(" "+_vm._s(("" + (licence.ASSU_AssuranceType.NomTypeAssurance)))+" ")])})}},{key:"item.Certificat",fn:function(ref){
            var item = ref.item;
return [_vm._l((item.DOC_Documents),function(doc,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":"primary","outlined":"","pill":""}},[_vm._v(" "+_vm._s(("" + (doc && doc.LIST_DocumentType && doc.LIST_DocumentType.SlugTypeDocument)))+" ")])}),(item && item.DOC_Documents.length == 0)?_c('v-chip',{attrs:{"color":"gray","x-small":"","dark":""}},[_vm._v("NC")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }