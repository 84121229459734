const ClubGestionLicences = 7;
const ClubGestionLicencesDecouverte = 8;
const ClubConsultationUtilisateurs = 9;
const ClubAdministrateur = 5;
const CTGestionLicencesDecouvertes = 13;
const LigueAdministrateur = 15;
const LigueGestionLicencesDecouvertes = 17;
const EtablissementAffilieGestionLicences = 19;
const Administrateur = 1;
const EtablissementAffilieAdministrateur = 11;
const AdministrateurVieTerritoriale = 20;
const MembreAssocieAdministrateur = 22;
const AdministrateurComptabilite = 23;
const CTAdministrateur = 10;

export default {
  ClubGestionLicences: ClubGestionLicences,
  ClubGestionLicencesDecouverte: ClubGestionLicencesDecouverte,
  ClubConsultationUtilisateurs: ClubConsultationUtilisateurs,
  ClubAdministrateur: ClubAdministrateur,
  CTGestionLicencesDecouvertes: CTGestionLicencesDecouvertes,
  LigueAdministrateur: LigueAdministrateur,
  LigueGestionLicencesDecouvertes: LigueGestionLicencesDecouvertes,
  EtablissementAffilieGestionLicences: EtablissementAffilieGestionLicences,
  Administrateur: Administrateur,
  EtablissementAffilieAdministrateur: EtablissementAffilieAdministrateur,
  AdministrateurVieTerritoriale: AdministrateurVieTerritoriale,
  MembreAssocieAdministrateur: MembreAssocieAdministrateur,
  AdministrateurComptabilite: AdministrateurComptabilite,
  CTAdministrateur: CTAdministrateur,
}
