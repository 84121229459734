<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="closeModal()"
    max-width="600px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        Ajouter utilisateur à
        {{
          canAddToAny
            ? "une structure"
            : currentStructure.STR_Structure.NomStructure
        }}
      </v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content">
      <v-tabs-items>
        <v-card flat>
          <div class="text-center py-10">
            <div v-if="isAlreadyFede">
              <span class="font-weight-bold"
                >{{ user.UTI_Utilisateur.CT_Prenom }}
                {{ user.UTI_Utilisateur.CT_Nom }}
              </span>
              est déjà rattaché à la structure
              <span class="font-weight-bold">{{
                currentStructure.STR_Structure.NomStructure
              }}</span>
              et a le rôle suivant :
              <span class="font-weight-bold">{{ roleUser }}</span>
            </div>
            <div
              v-else
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
                p-2
              "
            >
              <div v-if="currentRole">
                <p>
                  Ajouter
                  <span class="font-weight-bold">
                    {{ user.UTI_Utilisateur.CT_Prenom }}
                    {{ user.UTI_Utilisateur.CT_Nom }}
                  </span>
                  <br />
                  à une nouvelle structure avec un rôle fédéral.
                </p>
                <v-autocomplete
                  class="ffme-form__select"
                  v-model="structureSelected"
                  :items="structuresList"
                  item-text="NomStructure"
                  return-object
                  dense
                  labels="Structures"
                  solo
                  :loading="loading"
                  :disabled="!isFFME"
                >
                  <template v-slot:item="{ item }" style="height: 10px">
                    <v-list-item-content style="padding: 0">
                      <v-list-item-title class="d-flex align-center">
                        {{ item.NomStructure }}
                        <span v-if="item.ID_CodeFederal">
                          - {{ item.ID_CodeFederal }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </div>
              <div v-else>
                Ajouter
                <span class="font-weight-bold">
                  {{ user.UTI_Utilisateur.CT_Prenom }}
                  {{ user.UTI_Utilisateur.CT_Nom }}
                </span>
                en rôle féderal à la structure
                <span class="font-weight-bold">{{
                  currentStructure.STR_Structure.NomStructure
                }}</span>
              </div>
            </div>
          </div>
          <div class="text-center pb-10">
            <!-- <v-btn @click="closeModal()">
              Fermer
            </v-btn> -->
            <v-btn
              class="btn ml-6 btn-primary"
              :disabled="isAlreadyFede"
              @click="confirm"
            >
              Confirmer
            </v-btn>
          </div>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import { mapActions, mapGetters } from "vuex";
import { send_email } from "@/plugins/utils";
import { bus } from "@/main";

export default {
  components: {},
  data: () => ({
    isAlreadyFede: false,
    userExists: null,
    userRole: null,
    structuresList: [],
    structureSelected: null,
    loading: true,
  }),

  async created() {
    let type = {};

    type.id = { _eq: this.context.ID_Structure };
    this.structuresList = await this.getStructureList({ whereClause: {} });
    const userStructure = this.structuresList?.find(
      (structure) =>
        structure.NomStructure ===
        this.context.currentStructure?.STR_Structure?.NomStructure
    );
    this.structureSelected = !this.isFFME ? { ...userStructure } : null;
    this.loading = false;
    this.userExists = await this.userStructure({
      ID_Utilisateur: this.user.ID_Utilisateur,
      ID_Structure: this.context.ID_Structure,
    });
    // if (this.userExists.length && this.userExists[0].EST_RoleFederal == true) {
    //   this.isAlreadyFede = true;
    // }
    return;
  },

  methods: {
    ...mapActions("utilisateurs", ["addUserToStructure", "userStructure"]),
    ...mapActions("structure", ["getStructureList"]),
    ...mapActions("admin", ["editStrucutreUtilisateurRole"]),

    closeModal() {
      this.$emit("onClose");
    },
    async confirm() {
      if (this.canAddToAny()) {
        this.userExists = await this.userStructure({
          ID_Utilisateur: this.user.ID_Utilisateur,
          ID_Structure: this.structureSelected.id,
        });

        if (
          this.userExists.length &&
          this.userExists[0].EST_RoleFederal === true
        ) {
          error_notification(
            "L'utilisateur possède déjà un rôle fédéral sur la structure"
          );
        } else {
          let payload = {
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Structure: this.structureSelected.id,
            EST_Actif: true,
            EST_RoleFederal: true,
            EST_StructureLicencie: false,
          };

          if (this.userExists.length && this.userExists[0].id) {
            payload.id = this.userExists[0].id;
            if (this.userExists[0].EST_StructureLicencie == true) {
              payload.EST_StructureLicencie = true;
            }
          }

          try {
            let userAdded = await this.addUserToStructure({ user: payload });
            userAdded =
              userAdded.data.insert_STR_StructureUtilisateur.returning[0];
            // await this.sendEmail(payload);
            success_notification("Utilisateur ajouté");

            // New user on ffme
            if (
              this.structureSelected.LIST_StructureType.SlugTypeStructure ===
              "service_federal_ffme"
            ) {
              await this.newUserToFFME(userAdded);
            }

            this.$emit("onAdded");
            bus.$emit("reloadListStrUti");
          } catch (e) {
            error_notification();
            console.log(e);
          }
          // console.log(payload)
        }
      } else {
        let payload = {
          ID_Utilisateur: this.user.ID_Utilisateur,
          ID_Structure: this.context.ID_Structure,
          EST_Actif: true,
          EST_RoleFederal: true,
          EST_StructureLicencie: false,
        };
        if (this.userExists.length && this.userExists[0].id) {
          payload.id = this.userExists[0].id;
          if (this.userExists[0].EST_StructureLicencie == true) {
            payload.EST_StructureLicencie = true;
          }
        }
        try {
          await this.addUserToStructure({ user: payload });
          // await this.sendEmail(payload);
          success_notification("Utilisateur ajouté");
          this.$emit("onAdded");
          bus.$emit("reloadListStrUti");
        } catch (e) {
          error_notification();
          console.log(e);
        }
      }
    },

    async newUserToFFME(userAdded) {
      try {
        let payload = {
          role: {
            ID_Structure: this.structureSelected.id,
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Role: 20, // user federal ffme
            id: userAdded.id,
          },
          user: {
            ID_Structure: this.structureSelected.id,
            ID_Utilisateur: this.user.ID_Utilisateur,
          },
        };

        await this.editStrucutreUtilisateurRole(payload);
        success_notification(
          "L'utilisateur " +
            this.user.UTI_Utilisateur.CT_Nom +
            " " +
            this.user.UTI_Utilisateur.CT_Prenom +
            " a bien été ajouté en tant qu'administrateur à la structure."
        );
        bus.$emit("onUserRoleModuleUpdated");
      } catch (e) {
        console.log(e);
      }
    },

    // async sendEmail(user) {
    //   let payloadEmail = {};
    //   if (this.canAddToAny()) {
    //     payloadEmail = {
    //       User: user.ID_Utilisateur,
    //       ID_Structure: this.currentStructure.STR_Structure.id.toString(),
    //       Structure: this.structureSelected.NomStructure,
    //       Context: "userAddedAsFedeToStructure",
    //       SlugModule: "gestion_des_utilisateurs",
    //     };
    //   } else {
    //     payloadEmail = {
    //       User: user.ID_Utilisateur,
    //       ID_Structure: this.currentStructure.STR_Structure.id.toString(),
    //       Structure: this.currentStructure.STR_Structure.NomStructure,
    //       Context: "userAddedAsFedeToStructure",
    //       SlugModule: "gestion_des_utilisateurs",
    //     };
    //   }

    //   try {
    //     // payload email
    //     let email = await send_email(payloadEmail);
    //     if (!email.ok) {
    //       error_notification("Une erreur est survenue lors de l'envoi du mail");
    //     }
    //   } catch (e) {
    //     console.log("error on email", e);
    //   }
    //   // ...
    // },

    canAddToAny() {
      let roleSlug = this.currentRole.SlugRole;
      return (
        roleSlug == "super_administrateur" ||
        roleSlug == "utilisateur_federal" ||
        roleSlug == "administrateur_si" ||
        roleSlug == "administrateur_vie_territoriale"
      );
    },
  },

  computed: {
    ...mapGetters("structure", ["currentStructure"]),
    isFFME() {
      if (this.context.currentStructure?.STR_Structure?.id === 1318)
        return true;
      else return false;
    },
    isSameStr() {
      return this.user.ID_Structure == this.context.ID_Structure;
    },

    roleUser() {
      if (this.userExists[0].ADM_RoleType)
        return (
          "le rôle " + this.userExists[0].ADM_RoleType.NomRole.toLowerCase()
        );
      return "un rôle fédéral";
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    user: {
      type: Object,
    },

    context: {
      type: Object,
    },
    currentRole: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.label_column {
  width: auto;
  label {
    margin-bottom: 0;
    padding-top: 5px;
    font-weight: 200;
    font-size: 0.8rem;
  }
}
.not-editable {
  font-size: 0.8rem;
  line-height: 30px;
}
input {
  border: 1px solid #dfe5f9;
  padding: 4px 5px;
  font-weight: 200;
  font-size: 0.8rem;
  width: 100%;
  max-width: 400px;

  &.is-invalid {
    border-color: red;
  }
  &:focus {
    outline: none;
  }

  &.full-width {
    max-width: 100%;
  }
}

.rounded-pill {
  padding: 4px 13px;
}
</style>
