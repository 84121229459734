<template>
  <div v-if="CTPrenom">{{ CTPrenom }}</div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
import { trimAndCapitalizeFirstLetter } from "@/plugins/utils";

export default {
  computed: {
    CTPrenom() {
      if (this.utiFirst) {
        if (this.item.CT_Prenom) {
          return trimAndCapitalizeFirstLetter(this.item.CT_Prenom);
        }
        return false;
      }
      if (this.item.UTI_Utilisateur.CT_Prenom) {
        return trimAndCapitalizeFirstLetter(this.item.UTI_Utilisateur.CT_Prenom);
      }
      return false;
    },
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
  },

  props: {
    item: {
      type: Object,
    },
    utiFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
