<template>
  <BaseModal
    headerText="Ajout de participants"
    :is-open="isOpen"
    btnValidationText="Ajouter les participants selectionnés"
    v-on="$listeners"
    @validate="setNewParticipant()"
  >
    <template #modal-content>
      <div class="content-modal d-flex flex-column px-1">
        <div class="d-flex align-center my-4">
          <span
            v-text="
              'Liste des utilisateurs avec une fonction dans la structure'
            "
            class="text-subtitle"
          />
          <v-divider class="ml-4" color="#dfe5f9"></v-divider>
        </div>
        <div class="table-container">
          <v-data-table
            class="id-fuse-table"
            :headers="headers"
            :items="userSelectable"
            disable-sort
            hide-default-footer
          >
            <template #item.fullname="{item}">
              <span
                v-if="item.UTI_Utilisateur"
                v-text="
                  `${item.UTI_Utilisateur.CT_Nom} ${item.UTI_Utilisateur.CT_Prenom}`
                "
              />
            </template>
            <template #item.use="{item}">
              <div class="d-flex justify-content-center">
                <v-checkbox
                  style="margin-top: 4px !important;"
                  @change="(event) => setUser(event, item)"
                />
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../../../../components/utils/modals/BaseModal.vue";
import { mapState } from "vuex";

export default {
  name: "IdFuseParticipantListModal",
  components: { BaseModal },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userSelectable: [],
      userToAdd: [],
      headers: [
        {
          text: "Personne",
          align: "center",
          value: "fullname",
        },
        {
          text: "Rôle",
          align: "center",
          value: "FonctionUtilisateur.NomFonction",
        },
        {
          text: "Sélectionner",
          align: "center",
          value: "use",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      usersWithFunction: (state) => state.structure.userWithFunctionInStructure,
      userWithFunctionSelected: (state) =>
        state.structure.userWithFunctionSelected,
      userSelectedInList: (state) => state.structure.userSelectedInList,
    }),
  },
  created() {
    this.setData();
  },
  methods: {
    setData() {
      // we filter those who are not selected yet
      this.userSelectable = this.usersWithFunction.filter(
        (user) =>
          !this.userSelectedInList?.some(
            (userSelected) =>
              userSelected.UTI_Utilisateur?.id === user.UTI_Utilisateur?.id
          )
      );
    },
    setNewParticipant() {
      this.$store.commit(
        "structure/SET_SELECTED_USER_WITH_FUNCTION",
        this.userToAdd
      );
      this.$emit("close");
    },
    setUser(event, item) {
      if (event) {
        return this.userToAdd.push(item);
      }
      return this.userToAdd.splice(
        this.userToAdd.findIndex((user) => user.id === item.id),
        1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.content-modal {
  font-family: Roboto;
  font-style: normal;
  color: #020918;
  .text-subtitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
}
</style>
<style scoped>
::v-deep .v-data-table-header th {
  background: #39558b !important;
  color: white !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px;
}
::v-deep .id-fuse-table td {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  color: #000000;
}
</style>
