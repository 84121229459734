<template>
  <v-row v-if="mounted">
      <v-col cols="12">
        <h4>Modules</h4>
      </v-col>
    <v-col cols="12">

      <v-btn class="btn btn-primary" @click.prevent="addModuleToUser">
        Donner l'accès à un module
      </v-btn>
      <modalModuleAccess
        v-if="modalModuleAccess"
        :modalIsOpen="modalModuleAccess"
        :context="context"
        :user="user"
        @onUpdate="onUpdate"
        @onClose="onClose"
      ></modalModuleAccess>
    </v-col>

    <v-col cols="12">
      <h6>Accès aux modules spécifiques:</h6>
      <v-card outlined>
        <v-list dense flat>
          <v-list-item-group>
            <v-list-item v-for="(item, index) in modulesList" :key="index">
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.NomModule"
                  :selected="false"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-icon @click="removeModule(item)" color="red">
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import modalModuleAccess from "@/components/utils/modals/Modules/AddModuleUser__Modal.vue";
import { success_notification } from "@/plugins/notifications";
import {bus} from "@/main"
export default {
  components: { modalModuleAccess },

  data: () => ({
    allModules: [],
    modulesAccess: [],
    mounted: false,
    modalModuleAccess: false,
  }),

  async created() {
    this.setData();
    this.mounted = true;
  },

  methods: {
    ...mapActions("admin", ["deleteModuleAccess"]),

    setData() {
      this.allModules = [...this.getModules];
    },

    moduleSelected() {
      //   console.log(module)
    },

    addModuleToUser() {
      this.modalModuleAccess = true;
    },

    onClose() {
      this.modalModuleAccess = false;
    },

    onUpdate() {},

    async removeModule(item) {
      try {
        let payload = {
          module: {
            ID_Structure: this.user.ID_Structure,
            ID_Utilisateur: this.user.ID_Utilisateur,
            ID_Module: item.id,
          },
           user: {
            ID_Structure: this.user.ID_Structure,
            ID_Role: this.user.ID_Role,
            ID_Utilisateur: this.user.ID_Utilisateur
          }
         
        };
        await this.deleteModuleAccess(payload);
        success_notification("Module supprimé");
        bus.$emit('onUserRoleModuleUpdated')
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters("modules", ["getModules"]),

    modulesList() {
      // console.log(user)

      let modules = this.user.ADM_StructureUtilisateurModule.map(
        (userModule) => {
          return userModule.STR_Module;
        }
      ); // TODO: Filter by validity

      let filteredModules = [];
      modules.forEach((mod) => {
        if (!filteredModules.find((d) => d.id == mod.id))
          filteredModules.push(mod);
      });
      return filteredModules;
    },
  },

  props: {
    user: {
      type: Object,
    },
    context: {
      type: Object,
    },
  },
};
</script>
