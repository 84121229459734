<template>
  <span v-if="hasAuthization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
            v-on="on"
            @click="openModal"
            class="mx-1"
            fab
            x-small
            outlined
            color="green"
            :disabled="!canEditLicence"
          >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Licence siége</span>
    </v-tooltip>
     <modalLicenceSiege
      :user="currentUser"
      :context="context"
      v-if="modalSiegeIsOpen"
      :modalIsOpen="modalSiegeIsOpen"
      @onUpdate="onUpdate"
      @onClose="closeModal"
    ></modalLicenceSiege>
  </span>
</template>

<script>
import modalLicenceSiege from "@/components/utils/modals/Licence/LicenceSiege__Modal.vue";
import { mapGetters } from "vuex"

export default {
  components: {  modalLicenceSiege },

  data: () => ({
    modalSiegeIsOpen: false,
  }),
  created() {
    //console.log(this.user)
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison', 'nextSaison']),
    ...mapGetters('user', ['can']),

    hasAuthization() {
      return this.can('gestion_des_licences__renouveler_licences_en_serie')
    },

    canEditLicence() {
      if(this.nextSaison && this.context.ID_Saison == this.nextSaison.id) return true
      return this.user.EST_StructureLicencie && this.context.ID_Saison == this.currentSaison.id
    },

    currentUser() {
      return this.user;
    },
  },

  methods: {
    closeModal() {
      if(this.$route.name === "gestion_des_licences__saisir_une_licence_siege") {
      this.modalSiegeIsOpen = false;
      }
      else {
      this.modalIsOpen = false;
      }
    },

    onUpdate(items) {
      this.$emit("onUpdate", items);
    },

    openModal() {
      if(this.$route.name === "gestion_des_licences__saisir_une_licence_siege") {
      this.modalSiegeIsOpen = true;
      }
      else {
      this.modalIsOpen = true;
      }
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    renewLicence: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
