<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="450px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>{{ modalTitle }}</v-tab>
      <v-icon class="close-icon" @click="$emit('onClose')">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat class="px-8 py-6">
          Confirmer la licence siège pour l'utilisateur
          <b
            >{{ users[0].UTI_Utilisateur.CT_Prenom }}
            {{ users[0].UTI_Utilisateur.CT_Nom }}</b
          >

          <v-card-actions class=" pb-4 pt-10">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeModal"
              :loading="loading"
              gray
              rounded
              color="gray text-black font-weight-bold"
              >Annuler</v-btn
            >
            <v-btn
              @click="onConfirm"
              :loading="loading"
              rounded
              color="blue-dark text-white font-weight-bold"
              >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    mounted: false,
    tarifs: {},
    assurancesList: [],
    licenceTypeList: [],
    fluxFinancier: [],

    tarifsAlreadyPayed: [],
    fluxData: [],

    idsParents: [],
  }),

  async created() {
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("saisons", ["getSaison"]),

    modalTitle() {
      return "Confirmer la licence";
    },

    saison() {
      return this.getSaison(this.data.saison);
    },

    licence() {
      return this.licenceTypeList.find((lic) => lic.id == this.data.licence);
    },

    assurance() {
      return this.assurancesList.find((assu) => assu.id == this.data.assurance);
    },

    tarifsToPay() {
      let tarifs = this.tarifs.filter((tarif) => {
        let hasPayed = this.tarifsAlreadyPayed.find((hp) => hp.id == tarif.id);
        if (hasPayed) return false;
        return true;
      });
      return tarifs;
    },
  },

  methods: {
    ...mapActions("tarifs", ["getTarifForLicenceSiege"]),
    ...mapActions("structure", ["getStructureParent"]),
    ...mapActions("basics", ["getListTypeLicence", "getListTypeAssurances"]),

    async setData() {
      // Get the tarif and the flux on all users

      let idsAssurances = [];
      if (this.data.assurance)
        idsAssurances = [...idsAssurances, ...[this.data.assurance]];
      if (this.data.assuranceOption)
        idsAssurances = [...idsAssurances, ...[this.data.assuranceOption]];

      try {
        // console.log("payload query",  this.users[0].ID_Structure, this.data.saison, this.users.map(us => {return us.ID_Utilisateur})  )
        let response = await this.getTarifForLicenceSiege({
          context: {
            ID_Saison: this.data.saison,
          },
        });
        this.tarifs = response.MKP_Tarif;
        console.log(this.tarifs);
        //  console.log("flux sssssssfinancier", {...this.fluxFinancier})

        this.setFluxFinancier();
      } catch (e) {
        console.log(e);
      }
      this.assurancesList = await this.getListTypeAssurances();
      this.licenceTypeList = await this.getListTypeLicence();
      return;
    },

    getAssurance(id_assu) {
      return this.assurancesList.find((assu) => assu.id == id_assu);
    },

    getTarifOptionAssurance(id_assu) {
      return this.tarifs.find((tarif) => tarif.ID_TypeAssurance == id_assu);
    },

    closeModal() {
      this.$emit("onClose");
    },

    onConfirm() {
      this.$emit("onConfirm", { tarifs: this.tarifs, fluxData: this.fluxData });
    },

    setFluxFinancier() {
      // We need to pass across all user andcheck if they have a flux on this context
      let data = this.users.map((user) => {
        // isolé les flux des ct et ligues que j'ai récupéré
        // create a new flux iif the user doesnt have one for ths str and saison,
        let fluxUser = {
          ID_FluxFinancierDebit: 0,
          ID_Saison: this.data.saison,
          ID_Structure: user.ID_Structure,
          ID_Traitement: 0,
          ID_Utilisateur: user.ID_Utilisateur,
          MontantHT: 0,
          MontantTTC: 0,
          MontantTVA: 0,
          NomFluxFinancier:
            "Licence gratuite/siége" +
            user.UTI_Utilisateur.CT_Nom +
            " " +
            user.UTI_Utilisateur.CT_Prenom +
            " - " +
            this.data.saison,
          EST_Reglement: false,
          MKP_FluxFinancierDetail: {
            data: [],
            on_conflict: {
              constraint: "MKP_FluxFinancierDetail_pkey",
              update_columns: ["ID_Tarif"],
            },
          },
        };

        // prepare the fluxdetail object
        this.tarifs.forEach((tarif) => {
          let datafluxDetail = {
            ID_Structure: user.ID_Structure,
            ID_Utilisateur: user.ID_Utilisateur,
            ID_Saison: this.data.saison,
            ID_Tarif: tarif.id,
            MontantHT: tarif.MontantUnitaire,
            MontantTTC: tarif.MontantUnitaire,
            MontantTVA: 1,
            FIN_CodeAnalytique: 0,
            FIN_CodeCompta: 0,
            NomFluxFinancierDetail: `${tarif.NomTarif}`,
          };

          fluxUser.MKP_FluxFinancierDetail.data.push(datafluxDetail);
        });

        //create the total of the flux
        let total = 0;

        fluxUser.MontantHT = total;
        fluxUser.MontantTTC = total;
        return fluxUser;
      });
      this.fluxData = [...data];
    },
  },

  props: {
    users: {
      type: Array,
    },

    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    data: {
      type: Object,
    },
  },
};
</script>
