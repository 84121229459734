<template>
  <div v-if="licence">
    <v-chip small outlined :color="colorChips">
      {{ licence.ID_Saison }}
    </v-chip>
  </div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    licence() {
      //If we want the last licence the user have
      if (this.lastOne) {
        return this.getLastLicence();
      } else {
        // Check if we have licence fot this saisons...
        let licences = this.item.UTI_Utilisateur.UTI_Licences.filter(
          (licence) =>
            licence.ID_Saison == this.context.ID_Saison &&
            licence.ID_Structure == this.item.ID_Structure
        );

        if (!licences.length) return false;
        // just to be sur to return a unic object, normaly just one licence per year but, who knows
        let licence = licences.find(
          (licence) => licence.ID_Saison == this.context.ID_Saison
        );
        //if (!licence.ID_EtapeLicence) return false;

        return licence;
      }
    },

    colorChips() {
      if (this.licence) {
        if (this.licence.ID_Saison === this.context.ID_Saison) return "blue";
      }
      return "gray";
    },
  },
  methods: {
    getLastLicence() {
      if (this.utiFirst) {
        let licence;
        this.item.UTI_Licences.forEach((li) => {
          if (licence && licence.ID_Saison) {
            if (li.ID_Saison > licence.ID_Saison) return (licence = li);
            return;
          }
          return (licence = li);
        });
        return licence;
      }
    },
  },

  props: {
    item: {
      type: Object,
    },

    context: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    lastOne: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
