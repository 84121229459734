<template>
  <ValidateForm
    @validated="submit"
    :class="{
      'ffme-form__user-details': true,
      'height-100': true,
    }"
    :showFieldObl="mandatory"
  >
    <div v-if="!mounted" class="form-user-details__loading-container">
      <Loading :loading="true" />
    </div>

    <div v-else>
      <div class="form-user-details__container mt-0">
        <Loading :loading="loading" />
        <br />
        <div class="p-0 mb-5 col-12 d-flex">
          <span class="title-modal-section"> Informations personnelles </span>
          <v-divider class="ml-5"></v-divider>
        </div>
        <div class="form-user-details__left">
          <v-row v-if="!isFFME">
            <v-col
              :cols="12"
              :class="{ 'py-0': true, 'p-0': isFFME }"
            >
              <div style="font-size: 0.8rem; color: black">
                <v-row>
                  <v-col sm="12" lg="6" cols="6">
                    <InputText
                      label="Sexe"
                      readonly
                      :value="currentCivilite.text"
                    ></InputText>
                  </v-col>
                  <v-col sm="12" lg="6" cols="6">
                    <InputText
                        readonly
                        label="Date de Naissance"
                        :value="
                        $moment(userInfo.DN_DateNaissance).format('DD/MM/YYYY')
                      "
                    ></InputText>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col sm="12" lg="6" cols="6">
                    <InputText
                      label="Prénom"
                      readonly
                      :value="userInfo.CT_Prenom"
                    ></InputText>
                  </v-col>
                  <v-col sm="12" lg="6" cols="6">
                    <InputText
                      label="Nom"
                      readonly
                      :value="userInfo.CT_Nom"
                      class="mb-2"
                    ></InputText>
                  </v-col>
                </v-row>
                <v-btn @click="modalIsOpen = true" class="btn btn-primary mt-5"
                  >Modifier les coordonnées</v-btn
                >
              </div>

              <!-- fin editable -->
            </v-col>

            <modalUserUpdate
              v-if="modalIsOpen && mounted"
              :context="context"
              :modalIsOpen="modalIsOpen"
              :userInfos="userInfo"
              :adminMode="adminMode"
              @onClose="closeModal"
            />
          </v-row>
          <div
            v-else
            class="d-flex justify-space-between"
            style="font-size: 1rem"
          >
            <div style="width: 49% !important">
              <selectInput
                :items="civilites"
                v-model="userInfoEditable.CT_EST_Civilite"
                rules="required"
                item_text="label"
                item_value="label"
                label="Sexe"
              ></selectInput>
              <InputText
                v-model="userInfoEditable.CT_Prenom"
                rules="min:2|max:250|regexname"
                label="Prenom"
              ></InputText>
            </div>
            <div class="d-flex flex-column" style="width: 49% !important">
              <DatePicker
                  rules="required"
                  v-model="userInfoEditable.DN_DateNaissance"
                  style="min-width: 100px"
                  label="Date de naissance"
              ></DatePicker>
              <InputText
                v-model="userInfoEditable.CT_Nom"
                rules="min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>
            </div>
          </div>

          <!-- non griseee -->
          <br />
          <div class="p-0 mb-5 col-12 d-flex">
            <span
              class="title-modal-section"
              style="text-transform: inherit !important"
            >
              Informations complémentaires
            </span>
            <v-divider class="ml-5"></v-divider>
          </div>
          <div class="form-user-details__left">
            <!-- <countriesSelect
            v-model="currentPays"
            label="Pays"
            @onChange="onPaysChanged"
          ></countriesSelect> -->

            <v-row>
              <v-col sm="12" lg="6" cols="6">
                <AddressAutocomplete
                  :item="userAdresse.Adresse1"
                  rules="required|min:6|max:50"
                  label="Adresse"
                  :minimum_characters="3"
                  ref="adresseAuto"
                  @onSelected="adresseSelected"
                  @onChange="adresseSelected"
                ></AddressAutocomplete>
              </v-col>
              <v-col sm="12" lg="6" cols="6">
                <InputText
                  v-model="userAdresse.Adresse2"
                  rules="min:0|max:50"
                  label="Complément d'adresse"
                ></InputText>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" lg="6" sm="12">
                <InputText
                  v-model="userAdresse.CodePostal"
                  :key="refreshCodePostal"
                  :rules="limit"
                  label="Code postal"
                ></InputText>
              </v-col>
              <v-col cols="6" lg="6" sm="12">
                <InputText
                  v-model="userAdresse.Ville"
                  :key="refreshVille"
                  rules="required|min:1|max:60"
                  label="Ville"
                ></InputText>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="6" cols="6">
                <countriesSelect
                    v-model="userAdresse.ID_Pays"
                    label="Pays"
                    :key="refreshCountry"
                    @onChange="CountryChange"
                    rules="required"
                ></countriesSelect>
              </v-col>
              <v-col sm="12" lg="6" cols="6">
                <countriesSelect
                    v-model="currentNationalite"
                    label="Nationalité"
                    @onChange="NationaliteChange"
                ></countriesSelect>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" lg="6" sm="12">
                <InputTelephone
                  v-model="userInfo.CT_TelephoneFixe"
                  rules="min:0|max:30"
                  label="Téléphone"
                  key="1"
                ></InputTelephone>
              </v-col>
              <v-col cols="6" lg="6" sm="12">
                <InputTelephone
                  v-model="userInfo.CT_TelephoneMobile"
                  rules="min:0|max:30"
                  label="Téléphone portable"
                  key="2"
                ></InputTelephone>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" lg="6" sm="12">
                <InputText
                  v-model="newEmail"
                  rules="required|email"
                  label="Adresse Email"
                ></InputText>
              </v-col>
              <v-col cols="6" lg="6" sm="12">
                <InputText
                  v-model="userInfo.CT_Email2"
                  rules="email"
                  label="Adresse Email supplémentaire"
                  class="mb-2"
                ></InputText>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" lg="6" cols="6">
                <InputText
                    v-model="userInfoEditable.CT_NomDeNaissance"
                    rules="min:2|max:250|regexname|dashCounter"
                    label="Nom de naissance"
                ></InputText>
              </v-col>
              <v-col sm="12" lg="6" cols="6">
                <CommuneAutocomplete
                    class="mt-auto"
                    v-model="userInfoEditable.DN_CommuneNaissance"
                    onlyCities
                    :item="userInfoEditable.DN_CommuneNaissance"
                    rules="required"
                    @onSelected="communeSelected"
                    label="Commune de naissance"
                />
              </v-col>
            </v-row>
            <!-- <InputText
            v-model="newEmail"
            rules="required|email"
            label="Adresse courriel"
            :disabled="true"
            ><v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-2"
                  aria-hidden="false"
                  v-bind="attrs"
                  v-on="on"
                >
                  help
                </v-icon>
              </template>
              <span
                >La modification de l'adresse courriel sera bientôt
                possible</span
              >
            </v-tooltip>
          </InputText> -->

            <p class="poste" v-if="context.user && context.user.EST_Salarier">
              Utilisateur salarié au sein de la structure
              {{
                context &&
                context.user &&
                context.user.STR_Structure &&
                context.user.STR_Structure.NomStructure
              }}
              <span v-if="context.user.LIST_MissionSalarie"
                >: {{ context.user.LIST_MissionSalarie.NomMission }}</span
              >
            </p>
            <!-- <p v-if="context.fromModal">ID de l'utilisateur: {{context.user.ID_Utilisateur}}</p> -->
          </div>

          <!-- <div class="form-user-details__right"></div> -->
        </div>
      </div>
      <div class="form-user-details__action d-flex align-items-center">
        <v-btn type="submit" :loading="loading" class="btn btn-primary mt-5">{{
          btnName
        }}</v-btn>
        <span
          :loading="loading"
          class="custom-outline modifier_coordonnees ml-1 mt-5"
          >Réinitialiser</span
        >
      </div>
      <p class="caption font-italic mt-2">
        Pour en savoir plus sur vos données personnelles,
        <router-link to="/legal/confidentialite">cliquez ici</router-link>
      </p>
    </div>
  </ValidateForm>
</template>

<script>
import InputText from "@/components/utils/input.vue";
import ValidateForm from "@/components/utils/form.vue";
// import SelectPays  from "@/components/utils/selectPays.vue"
import InputTelephone from "@/components/utils/inputTelephone.vue";
import { mapActions, mapGetters } from "vuex";
import {
  listDeCivilite,
  sleep,
  admin_email_update,
  update_user_email,
} from "@/plugins/utils";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import selectInput from "@/components/utils/select.vue";
import Loading from "@/components/LoadingIndicator.vue";

import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import countriesSelect from "@/components/utils/select__countries.vue";
import modalUserUpdate from "@/components/utils/modals/User/User__Update.vue";
import CommuneAutocomplete from "@/components/utils/adresseAutocomplete";
import { log } from "console";
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";
import {
  formatName,
} from "@/plugins/userService";

export default {
  name: "FormUserDetails",

  components: {
    AddressAutocomplete,
    InputText,
    selectInput,
    ValidateForm,
    //  SelectPays,
    InputTelephone,
    countriesSelect,
    Loading,
    modalUserUpdate,
    CommuneAutocomplete,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
  },

  props: {
    adminMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: [Object, Array],
      default: null,
    },

    context: {
      type: Object,
    },
  },

  data: () => ({
    mandatory: false,
    loading: true,
    title_section: "Informations générales",
    oldUser: null,
    limit: "",
    // Mine
    mounted: false,

    listCivilite: [],
    currentCivilite: {},
    listPays: [],
    currentPays: null,
    currentNationalite: {},

    userInfo: {},
    civilites: [{ label: "Homme" }, { label: "Femme" }],
    civilite: null,
    userInfoEditable: {
      CT_EST_Civilite: 0,
      CT_Nom: "",
      CT_Prenom: "",
      DN_CommuneNaissance: "",
      CT_NomDeNaissance: "",
      DN_DateNaissance: null,
    },
    userAdresse: {},
    newEmail: "",

    modalIsOpen: false,

    restrictCountry: "FR",
    refreshCodePostal: 99,
    refreshVille: 0,
    refreshCountry: 999,
    currentUser: {},
  }),

  async created() {
    console.log("currentUserId", this.currentUserId);
    console.log("this.appUserMode----", this.appUserMode);
    this.$emit("setTitle", this.title_section);
    this.listCivilite = listDeCivilite();
    this.listPays = await this.getListPays();

    await this.setData();
    this.userInfoEditable.CT_EST_Civilite =
      this.userInfo.CT_EST_Civilite === 1 ? "Homme" : "Femme";
    this.userInfoEditable.CT_Nom = trimAndUppercaseAllLetters(this.userInfo?.CT_Nom);

    this.userInfoEditable.CT_Prenom = trimAndCapitalizeFirstLetter(this.userInfo?.CT_Prenom);
    this.userInfoEditable.DN_CommuneNaissance =
      this.userInfo?.DN_CommuneNaissance;
    // this.userInfoEditable.DN_DateNaissance = this.$moment(
    //   this.userInfo?.DN_DateNaissance
    // ).toISOString();d

    this.userInfoEditable.DN_DateNaissance = this.userInfo?.DN_DateNaissance;

    this.userInfoEditable.CT_NomDeNaissance = trimAndUppercaseAllLetters(this.userInfo?.CT_NomDeNaissance);

    this.loading = false;

    this.mounted = true;
    await sleep(500);
    if (this.userAdresse && this.userAdresse.ADR_Pays && this.$refs.adresseAuto)
      this.$refs.adresseAuto.setRestriction(this.userAdresse.ADR_Pays.CodePays);

    // Set current pays restriction
  },

  computed: {
    ...mapGetters("basics", ["getList"]),
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("structure", ["hasCodeFederal", "currentStructure"]),
    ...mapGetters("app", ["appUserMode"]),

    btnName() {
      return "Enregistrer les modifications";
    },
    isFFME() {
      return this.currentStructure?.STR_Structure?.id === 1318;
    },
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateur",
      "getUtilisateurAdresse",
      "updateUtilisateurCoordonnees",
      "getUserBasicInfos",
    ]),
    ...mapActions("basics", ["getListPays"]),

    async setData() {
      this.loading = true;
      this.oldUser = await this.getUtilisateur(this.context);
      console.log(" this.oldUser ", this.oldUser);

      this.userInfo = { ...this.oldUser };
      console.log("this.userInfo ", this.userInfo);
      this.newEmail = this.userInfo.CT_Email;
      this.userAdresse = {
        ...(await this.getUtilisateurAdresse(this.context)),
      };

      if (this.userInfo.CT_EST_Civilite != null) {
        this.currentCivilite = this.listCivilite.find(
          (c) => c.value == this.userInfo.CT_EST_Civilite
        );
      }

      if (this.userInfo.ID_PaysNationalite) {
        this.currentNationalite = this.listPays.find(
          (p) => p.id == this.userInfo.ID_PaysNationalite
        );
      } else {
        this.currentNationalite = {};
      }
      if (this.userAdresse) {
        this.currentPays = this.userAdresse.ADR_Pays;
      }

      if (!this.currentStructure) {
        let user = await this.getUserBasicInfos(this.currentUserId);
        this.currentUser = user.UTI_Utilisateurs[0];
        console.log("this.currentUser atoooo", this.currentUser);
      }

      this.loading = false;
      return;
    },

    CiviliteChange(item) {
      this.userInfo.CT_EST_Civilite = item.value;
    },

    onPaysChanged(item) {
      this.userAdresse.ID_Pays = item.id;
      this.userAdresse.ADR_Pays = item;
      // this.restrictCountry = item.CodePays;
      // this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.currentPays = item;
    },

    NationaliteChange(item) {
      this.userInfo.ID_PaysNationalite = item.id;
    },

    CountryChange(item) {
      this.userAdresse.ID_Pays = item.id;
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0)?.toUpperCase() + string.slice(1)?.toLowerCase();
    },

    async adresseSelected(item) {
      if (typeof item === "string") {
        this.userAdresse.Adresse1 = item.toUpperCase();
        return;
      }

      await sleep(10);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1?.toUpperCase();
      this.userAdresse.ID_Utilisateur = this.userInfo.id;
      this.userAdresse.Adresse1 = adresse1?.toUpperCase();
      this.userAdresse.Adresse2 = "";
      this.userAdresse.Ville = item[2].long_name?.toUpperCase();
      this.userAdresse.CodePostal = item[6]
        ? item[6].long_name?.toUpperCase()
        : "";

      this.userAdresse.ID_Pays = this.listPays.find(
        (p) => p.CodePays.toLowerCase() == item[5].short_name.toLowerCase()
      )?.id;

      this.refreshCodePostal++;
      this.refreshVille++;
      this.refreshCountry++;
    },

    async communeSelected(item) {
      this.userInfoEditable.DN_CommuneNaissance = item[0].long_name;
      await this.getCodeInsee(item[0].long_name);
    },

    async getCodeInsee(city_name) {
      const fetch = require("node-fetch");

      (async () => {
        const response = await fetch(
          "https://api-adresse.data.gouv.fr/search/?q=" +
            city_name +
            "&type=municipality&autocomplete=0",
          {
            method: "get",
          }
        );
        const json = await response.json();
        if (json.features.length) {
          this.userInfoEditable.DN_CommuneNaissance_CodeInsee =
            json.features[0].properties.id;
        }
      })();

      return;
    },

    async submit() {
      console.log("this.userAdresse submit", this.userAdresse);
      this.loading = true;
      // localStorage.setItem("changeNewEmail", this.newEmail);
      try {
        let oldEmail = this.userInfo.CT_Email;

        // if has code federal, we overwrite de data by the new inserted data
        let codeFederal = this.hasCodeFederal
          ? this.hasCodeFederal
          : this.currentUser.UTI_Licences[0].STR_Structure.ID_CodeFederal;
        let payloadUser = codeFederal
          ? {
              ...this.userInfo,
              ...this.userInfoEditable,
              CT_EST_Civilite:
                this.userInfoEditable.CT_EST_Civilite === "Homme" ? 1 : 0,
              CT_Email: this.newEmail,
              DN_DateNaissance: this.userInfoEditable.DN_DateNaissance,
            }
          : {
              ...this.userInfo,
              CT_Email: this.newEmail,
              DN_DateNaissance: this.userInfoEditable.DN_DateNaissance,
            };

            payloadUser.CT_Nom = formatName(payloadUser.CT_Nom, 'lastname');
            payloadUser.CT_Prenom = formatName(payloadUser.CT_Prenom, 'firstname');

            if (payloadUser.CT_NomDeNaissance) {
              payloadUser.CT_NomDeNaissance = formatName(payloadUser.CT_NomDeNaissance, 'lastname');
            } else {
              payloadUser.CT_NomDeNaissance = null;
            }
        // await this.updateUtilisateurCoordonnees({
        //   user: payloadUser,
        //   adresse: this.userAdresse,
        // });
        // if (this.context.fromModal) {
        //   success_notification('Modifications enregistrées');
        // } else {
        //   success_notification('Informations mises à jour');
        // }

        //DEACTIVATE ALL EMAIL "Modification d'une adresse mail sur l'espace fédéral"
        if (this.newEmail && this.newEmail != oldEmail) {
          if (!this.userInfo.LOG_Login) {
            this.loading = false;
            // return error_notification("Pas de LOG_Login, impossible d'envoyer le mail")
          } else {
            if (this.appUserMode === "federal") {
              let response = await this.adminEmailUpdateCognito();
              console.log("responseeeee", response);
              if (response.ok) {
                await this.updateUtilisateurCoordonnees({
                  user: payloadUser,
                  adresse: this.userAdresse,
                });
                if (this.context.fromModal) {
                  success_notification("Modifications enregistrées");
                } else {
                  success_notification("Informations mises à jour");
                }
              }
            } else {
              let response = await this.UpdateUserEmailCognito();

              if (response.ok) {
                await this.updateUtilisateurCoordonnees({
                  user: payloadUser,
                  adresse: this.userAdresse,
                });
                if (this.context.fromModal) {
                  success_notification("Modifications enregistrées");
                } else {
                  success_notification("Informations mises à jour");
                }
              }
            }
          }
        } else {
          await this.updateUtilisateurCoordonnees({
            user: payloadUser,
            adresse: this.userAdresse,
          });
          if (this.context.fromModal) {
            success_notification("Modifications enregistrées");
          } else {
            success_notification("Informations mises à jour");
          }
        }

        this.loading = false;
        location.reload();
        return;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");

        console.log(e);
        this.loading = false;
      }
    },

    dataEquals() {
      if (
        JSON.stringify(this.userInfo) !== JSON.stringify(this.currentUser) ||
        JSON.stringify(this.userAdresse) !==
          JSON.stringify(this.currentUserAdresse) ||
        JSON.stringify(this.userLicence) !==
          JSON.stringify(this.currentUserValidLicence)
      ) {
        return false;
      }

      return true;
    },

    // change user email by ADMIN in cognito
    async adminEmailUpdateCognito() {
      let payload = {
        newEmail: this.newEmail,
        username: this.userInfo.LOG_Login,
      };
      let response = await admin_email_update(payload);
      console.log("response adminEmailUpdateCognito", response);
      if (!response.ok) {
        error_notification(
          "une erreur est survenue lors de l'envoie du courriel"
        );
        return { ok: false };
      } else {
        success_notification(
          `Un courriel de validation a été envoyé à ${this.userInfo.CT_Prenom} ${this.userInfo.CT_Nom}`
        );
        return { ok: true };
      }
    },

    // change user email by USER in Cognito
    async UpdateUserEmailCognito() {
      let response = await update_user_email(this.newEmail);
      console.log("response UpdateUserEmailCognito", response);

      if (!response.ok) {
        error_notification(
          "une erreur est survenue lors de l'envoie du courriel"
        );
        return { ok: false };
      } else {
        success_notification(
          `Un courriel de validation a été envoyé à ${this.userInfo.CT_Prenom} ${this.userInfo.CT_Nom}`
        );
        return { ok: true };
      }
    },

    closeModal() {
      this.modalIsOpen = false;
    },
  },

  watch: {
    user: {
      handler() {
        this.setData();
      },
      deep: true,
      immediate: true,
    },
    // user() {
    //   this.setData();
    // },
    currentPays() {
      this.limit = this.currentPays === 75 ? "" : "required|digits:5";
    },
  },

  beforeRouteLeave(to, from, next) {
    if (!this.dataEquals()) {
      const answer = window.confirm(
        "Voulez-vous vraiment partir? Vous avez des changements non enregistrés!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.ffme-form__user-details {
  .form-user-details__protected-content {
    background: #f5f5f5;
    margin: -30px -30px 0;
    padding: 10px 20px;
  }

  &.__admin-mode {
    .form-user-details__protected-content {
      margin-left: -30px;
      padding-left: 30px;
      margin-top: -30px;
      padding-top: 35px;
      padding-right: 10px;
      margin-right: 0;
    }

    .form-user-details__container {
      display: flex;
      flex-direction: column !important;
    }
    .title-modal-section {
      // position: static;
      // width: 165px;
      // height: 20px;
      // left: 0px;
      // top: 0px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      //styleName: Body/14px Deci medium;
      text-align: left;

      display: flex;
      align-items: center;

      /* Primary/Dark */

      color: #000000;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 10px;
    }
    .form-user-details__left {
      width: 50%;
      padding-right: 10px;
      padding-top: 0;
    }
    .form-user-details__right {
      width: 50%;
      padding-left: 10px;
    }
  }

  .ffme__date-picker .v-input.v-text-field .v-input__control .v-input__slot {
    min-height: 30px;
  }
  .field__not-editable {
    .label_column,
    .value_column {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.form-coordonnees__modify {
  border-left: 4px solid #0044aa1c;
  padding-top: 0;
  font-size: 0.9rem;
}

.poste {
  font-size: 13px;
  padding: 1em 0;
  background: #f5f5f5;
}

div {
  &.v-input__slot {
    min-height: 0px !important;
  }
}
/***custom input style */
.v-text-field fieldset {
  border-color: #e7effd !important;
  border-radius: unset !important;
  border-width: 0.5px !important;
  border: solid !important;
}

.custom-outline {
  background: #ffffff !important;
  opacity: 0.3 !important;
  color: #082b6e;
  border: 1px solid #082b6e !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  border-radius: 32px !important;
  padding: 10px 25px !important;
  &.modifier_coordonnees {
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
  }
  /* margin-top: 60px !important; */
}

.coordonnes_result {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 400;
  &.label {
    font-weight: 500 !important;
  }
}

.form-user-details__left .field__label-column {
  min-width: 44%;
  flex: 1 0 200px;
}
</style>
