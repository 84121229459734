<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="1400px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column">
        <span style="font-size: 1.2rem">
          {{ modalTitle }}
        </span>
        <v-icon
          class="close-icon"
          style="margin-top: -23px !important; margin-left: auto"
          @click="closeModal"
          large
          >mdi-close</v-icon
        >
      </v-tab>
    </v-tabs>
    <div
      class="modal__content"
      style="
        overflow: hidden;
        min-height: calc(100vh - 300px);
        background: white;
      "
      v-if="mounted"
    >
      <v-tabs-items>
        <v-card flat>
          <v-row class="px-10">
            <v-col cols="12" class="text-center px-10 py-10">
              <h4>
                Editer les rôles et accès aux modules pour l'utilisateur
                {{
                  user.UTI_Utilisateur.CT_Prenom +
                  " " +
                  user.UTI_Utilisateur.CT_Nom
                }}
                sur la structure {{ user.STR_Structure.NomStructure }}
              </h4>
            </v-col>
            <v-row v-if="mounted">
              <v-col cols="4" class="">
                <RoleModules
                  :context="context"
                  :user="{
                    ...user,
                    ADM_RoleType,
                    ADM_StructureUtilisateurModule,
                    ID_Role: roleUser.ID_Role,
                  }"
                  :idTypeStructure="idTypeStructure"
                ></RoleModules>
              </v-col>
              <v-col cols="8">
                <ModuleAccess
                  :context="context"
                  :user="{
                    ...user,
                    ADM_RoleType,
                    ADM_StructureUtilisateurModule,
                    ID_Role: roleUser.ID_Role,
                  }"
                ></ModuleAccess>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import RoleModules from "@/components/utils/modals/Modules/ModulesRoles.vue";
import ModuleAccess from "@/components/utils/modals/Modules/ModuleAccess.vue";
import { mapActions } from "vuex";
import { bus } from "@/main";

export default {
  components: { RoleModules, ModuleAccess },

  data: () => ({
    loading: false,
    mounted: false,
    roleUser: {},
    ADM_RoleType: null,
    ADM_StructureUtilisateurModule: [],
    idTypeStructure: null,
  }),

  async created() {
    bus.$on("onUserRoleModuleUpdated", async () => {
      // console.log("role updated");
      this.ADM_RoleType = null;
      this.ADM_StructureUtilisateurModule = [];
      return await this.setData();
    });
    await this.setData();
    console.log("this.context====>>", this.context);
    this.mounted = true;
    return;
  },

  computed: {
    modalTitle() {
      return "Editer les rôles et accès aux modules";
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getUtilisateurRoleModule"]),
    ...mapActions("structure", ["getStructureByID"]),

    closeModal() {
      this.$emit("onClose");
    },

    async setData() {
      let response = await this.getUtilisateurRoleModule({ user: this.user });
      this.roleUser = response.STR_StructureUtilisateur[0];
      if (this.roleUser && this.roleUser.ADM_RoleType) {
        this.ADM_RoleType = response.STR_StructureUtilisateur[0].ADM_RoleType;
      }

      if (
        this.roleUser.ADM_StructureUtilisateurModule &&
        this.roleUser.ADM_StructureUtilisateurModule.length
      ) {
        this.ADM_StructureUtilisateurModule =
          this.roleUser.ADM_StructureUtilisateurModule;
      }
      const dataStructure = await this.getStructureByID(
        this.context.ID_Structure
      );
      this.idTypeStructure = dataStructure.LIST_StructureType?.id;
    },

    async submit() {},
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    user: {
      type: Object,
    },
  },
};
</script>
