<template>
  <div v-if="!isLoading" :key="keyComponent">
    <div>
      <div>
        <v-alert text type="info">
          <p>Pour activer la licence, la validation du questionnaire santé est nécessaire. Seuls les licenciés suivants devront présenter un certificat médical tous les 3 ans (et attestation du questionnaire santé dans l'intervalle des 3 ans) :</p>
          <ul style="list-style: disc;">
            <li>Les licenciés (jeunes ou adultes) ayant répondu "oui" à l'une des questions du questionnaire santé</li>
            <li>Les compétiteurs adultes escalade à partir des 1/2 Finales Championnat France et Coupe de France</li>
            <li>Les compétiteurs adultes en ski-alpinisme</li>
          </ul>
        </v-alert>
      </div>
      <div>
        <div class="d-flex">
          <div style="width: 52%;" class="mr-2">
            <CertificatMedicalPanel
              headerTitle="Certificat médical"
              headerContent="Télécharger un certificat médical type"
              btnTitle="Téléverser un certificat médical"
              @clicked="openCertificatMedicalModal = true"
              @download="downloadCertificat"
            />
          </div>
          <div style="width: 52%;">
            <CertificatMedicalPanel
              headerTitle="Questionnaire santé"
              headerContent="Télécharger le questionnaire santé"
              content="Le questionnaire santé est à répondre de votre côté."
              btnTitle="J'atteste que le questionnaire santé permet la pratique"
              @download="downloadQS"
              @clicked="validateQS"
              :is-disabled="!canValidateQS || hasValidQS"
              isInfoToolTipDisabled
              :panel="hasValidQS ? 1 : 0"
            />
          </div>
        </div>
      </div>
      <certificatList
        class="mx-1"
        @renouvellement="openModalRenouvellement"
        ref="listCertificatsM"
        :context="context"
        :isCT="isCT"
        key="certificat_medical"
        @docDelete="updateComponent"
      ></certificatList>
      <certificat-medical-upload-modal
        :isOpen="openCertificatMedicalModal"
        @close="openCertificatMedicalModal = false"
        @onFileUploaded="fileUploaded"
        :context="context"
        :certificats="certificats"
      />
      <DisabilityForm v-if="isAdmin() || this.context.ID_Utilisateur === this.user.id" :userId="this.context.ID_Utilisateur" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import moment from "moment-timezone";

import certificatList from "@/components/Documents/Doc__ListCertificatsM.vue";
import DisabilityForm from "../Disability/DisabilityForm.vue";

import RoleTypes from "@/constants/roles.js";

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications.js";

import CertificatMedicalUploadModal from "../../utils/modals/fileUpload/CertificatMedicalUploadModal.vue";
import CertificatMedicalPanel from "./CertificatMedicalPanel.vue";
import { validate } from "@/constants/doc_status";
import { getLicences } from "@/plugins/licenceService";

export default {
  components: {
    certificatList,
    CertificatMedicalUploadModal,
    CertificatMedicalPanel,
    DisabilityForm,
  },

  data: () => ({
    openCertificatMedicalModal: false,
    title_section: "Certificats médicaux",
    certificats: null,
    certificatSelected: null,
    date: new Date().toISOString().substr(0, 10),
    fileModalIsOpen: false,
    isCT: true,
    selectedSaison: null,
    pdfLink: require("@/assets/certificat-medical-type.pdf"),
    customExpirationDate: null,
    expirationDate: null,
    updateCMIDs: [],
    userAge: null,
    licence: null,
    isLoading: true,
    keyComponent: 0,
  }),

  async created() {
    const licences = await getLicences({
      user: `/api/uti_utilisateurs/${this.context.ID_Utilisateur}`,
      season: `/api/saison/${this.currentSaison.id}`,
      'order[dateEnd]': 'desc',
    });
    this.licence = licences[0];

    this.$emit("setTitle", this.title_section);
    console.log('this.context', this.context)
    this.initialize();
    this.isLoading = false;
  },

  computed: {
    ...mapGetters("basics", ["getList", "currentSaison"]),
    ...mapGetters("saisons", ["currentSaison", "getSaison", "nextSaison"]),
    ...mapGetters("structure", [
      "hasCodeFederal",
      "currentStructure",
      "currentStructureId",
    ]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("user", ["currentUser", "currentUserId", "getRole"]),
    ...mapState({ hasValidQS: (state) => state.utilisateurs.hasValidQS }),

    canValidateQS() {
      if (!this.certificats?.length) return true;
      const CMAncienity = this.$moment().diff(
        new Date(this.certificats[0].DateValidation),
        "years"
      );
      if (CMAncienity < 3) return true;
      return false;
    },
  },

  methods: {
    moment,
    ...mapActions("basics", [
      "insertDocument",
      "getListTypesDocument",
      "getTemplatesBySlug",
      "updateLicenceStatus"
    ]),
    ...mapActions("user", ["insertCertificatMedical"]),
    ...mapActions("utilisateurs", [
      "updateUserIsNonPratiquant",
      "getUtilisateurCertificatsM",
    ]),
    ...mapActions("structure", ["getStructuresByID"]),
    ...mapActions("documents", ["getTemplateBySlug"]),
    async initialize() {
      await this.setData();
      await this.getListTypesDocument();
    },

    async setData() {
      this.certificats = await this.getUtilisateurCertificatsM(this.context);

      const structureUser = await this.getStructuresByID({
        ID_Utilisateur: this.user.id,
      });
      this.isCT =
        structureUser &&
        structureUser[0].STR_Structure?.LIST_StructureType?.NomTypeStructure ===
          "Comité territorial"
          ? true
          : false;

      this.selectedSaison = this.currentSaison.Annee;
      console.log(this);
      return;
    },
    updateComponent() {
      this.keyComponent++;
    },
    getLastDateOfSeason() {
      var today = new Date();
      var lastDayOfMonth = new Date(today.getFullYear(), 8, 0);
      return lastDayOfMonth;
    },

    isAdmin() {
      if ([RoleTypes.Administrateur, RoleTypes.AdministrateurVieTerritoriale].includes(this.getRole?.id)) {
        return true;
      }

      return false;
    },

    // validate QS
    async validateQS() {
      let newItem = {
        Categorie: "questionnaire",
        CheminDocument: null,
        DateFinValiditeDocument: new Date(`08/31/${this.currentSaison.id}`),
        DateValidation: null,
        EST_Actif: true,
        EST_DocumentValide: true,
        ID_Type_Document: 60,
        ID_Utilisateur: null,
        status: validate,
        LIST_DocumentType: {
          EST_Image: false,
          EST_SaisirDateFinValidite: null,
          EST_SaisirDateValidation: null,
          ID_DocumentCategorie: null,
          NomTypeDocument:
            "Gestion des certificats médicaux / Questionnaire santé",
          SlugTypeDocument: null,
          id: null,
        },
      };
      await this.addCMItem(newItem);

      this.updateComponent();
    },

    async fileUploaded(file) {
      let newCertificate = {
        CheminDocument: file.url,
        NomDocument: file.filename,
        ID_Type_Document: file.documentType,
        Categorie: "certificat",
        ID_Utilisateur: this.context.ID_Utilisateur,
        DateFinValiditeDocument: file.finDeValidite,
        EST_Actif: true,
        EST_DocumentValide: true,
        ID_Saison: file.saison,
        DateValidation: file.date,
        status: validate,
      };

      try {
        const document = await this.insertCertificatMedical({
          newCertificate,
          ID_Utilisateur: this.context.ID_Utilisateur,
          updateIDs: file.updateCMIDs,
        });

        await this.updateLicenceStatus({id: this.licence.id})
          .then(async () => {
            success_notification("Certificat ajouté");

            await this.$refs.listCertificatsM.setData();

            this.certificatSelected = null;
            this.fileModalIsOpen = false;

            // if is non pratiquant
            this.updateUserIsNonPratiquant({
              userID: this.context.ID_Utilisateur,
              isNonPratiquant: false,
            });

            await this.initialize();
          })
          .catch((error) => {
            error_notification(error.response.data.message);
          });
      } catch (e) {
        error_notification();
      }

      this.updateComponent();
      // bus.$emit("updateCM");
    },

    async downloadCertificat() {
      const href = await this.getTemplateBySlug({ slug: `Template_Certificat_Medical_${this.currentSaison.id}`, season: this.currentSaison.id });
      const link = document.createElement("a");

      link.href = href.CheminDocument;
      link.target = "_blank";
      link.click();
      URL.revokeObjectURL(link.href);
    },

    async downloadQS() {
      let birthdate = this.context?.user?.UTI_Utilisateur
        ? this.context?.user?.UTI_Utilisateur?.DN_DateNaissance
        : this.context.DN_DateNaissance;
      const age = this.$moment().diff(this.$moment(birthdate), "years");

      let payload = {
        ID_Saison: this.currentSaison.id,
        SlugNomTemplate: +age >= 18 ? "QS_majeur" : 'QS_mineur',
      };
      let template = await this.getTemplatesBySlug(payload);

      const link = document.createElement("a");
      link.href = template[0].CheminDocument;
      link.download = template[0].NomDocument;
      link.click();
      URL.revokeObjectURL(link.href);
    },

    saisonChange(saison) {
      this.selectedSaison = saison;
    },

    openModalRenouvellement(slug) {
      this.certificatSelected = slug;
      this.fileModalIsOpen = true;
    },

    updateExpirationDate(date) {
      this.expirationDate = this.$moment.utc(date);
    },

    async addCMItem(item) {
      this.$refs.listCertificatsM.addItem(item);

      let payload = {
        CheminDocument: "",
        NomDocument: "Gestion des certificats médicaux / Questionnaire santé",
        ID_Type_Document: 60,
        ID_Structure: this.currentStructureId,
        ID_Utilisateur: this.context.ID_Utilisateur,
        ID_News: null,
        DateFinValiditeDocument: item.DateFinValiditeDocument,
        DateValidation: this.$moment(new Date()).toISOString(),
        EST_Actif: true,
        EST_DocumentValide: true,
        Categorie: "Questionnaire santé",
        ID_Saison: this.currentSaison.id,
        status: validate,
      };

      try {
        let doc = await this.insertDocument(payload);

        await this.updateLicenceStatus({id: this.licence.id})
          .then(async () => {
            success_notification('Questionnaire santé ajouté');
          }).catch((error) => {
            error_notification(error.response.data.message);
          });
      } catch (error) {
        console.log(error)
        error_notification('Oups, une erreur est survenue');
      }
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.certificate_form {
  .certificate_form__select {
    label {
      margin: 0 !important;
      font-size: 0.8rem;
    }
  }

  .v-input--radio-group__input {
    width: auto !important;
  }

  .certificate_form__datepicker,
  .certificate_form__inputfile {
    max-width: 800px;
  }
}
.download {
  cursor: pointer;
  text-decoration: underline;
}

.telecharger_certificat {
  color: #3a1e00 !important;
  font-weight: bold;
  font-family: "Roboto" !important;
  font-size: 14px !important;
}

.choisir_certificat {
  font-family: "Roboto" !important;
  font-size: 16px !important;
}
</style>
