<template>
  <div v-if="CTNom">{{ CTNom }}</div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    CTNom() {
      if (this.utiFirst) {
        if (this.item.CT_Nom) {
          return this.item.CT_Nom;
        }
        return false;
      }
      if (this.item.UTI_Utilisateur.CT_Nom) {
        return this.item.UTI_Utilisateur.CT_Nom;
      }
      return false;
    },
  },

  props: {
    item: {
      type: Object,
    },
    utiFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
