<template>
  <span v-if="hasAuthization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="openModal"
          class="mx-1"
          fab
          x-small
          outlined
          color="green"
          :disabled="!isFederal"
        >
          R
        </v-btn>
      </template>
      <span>Modifier les rôles et modules</span>
    </v-tooltip>

    <modalRoleModule
      :user="user"
      :context="context"
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      @onUpdate="onUpdate"
      @onClose="closeModal"
    ></modalRoleModule>
  </span>
</template>

<script>
import modalRoleModule from "@/components/utils/modals/Modules/Modules__Modal.vue";
import { mapGetters } from "vuex";

export default {
  components: { modalRoleModule },

  data: () => ({
    modalIsOpen: false,
  }),

  computed: {
    ...mapGetters("user", ["can"]),

    hasAuthization() {
      return (
        this.can("gestion_des_utilisateurs__gestion_des_roles") ||
        this.can("gestion_des_utilisateurs__gestion_des_modules")
      );
    },

    isFederal() {
      return this.user.EST_RoleFederal;
    },
    // moduleList() {
    //   // console.log(user)
    //   let modulesByRole = [];
    //   if (this.user.ID_Role) {
    //     modulesByRole = this.user.ADM_RoleType.ADM_RoleModule.map((role) => {
    //       return role.STR_Module;
    //     });
    //   }
    //   let moduleAccess = this.user.ADM_StructureUtilisateurModule.map(
    //     (userModule) => {
    //       return userModule.STR_Module;
    //     }
    //   ); // TODO: Filter by validity

    //   let modules = [...modulesByRole, ...moduleAccess];
    //   let filteredModules = [];
    //   modules.forEach((mod) => {
    //     if (!filteredModules.find((d) => d.id == mod.id))
    //       filteredModules.push(mod);
    //   });
    //   return filteredModules;
    // },
  },

  methods: {
    closeModal() {
      this.modalIsOpen = false;
    },

    onUpdate() {
      this.$emit("onUpdate");
    },

    openModal() {
      this.modalIsOpen = true;
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
};
</script>
