<template>
  <p class="pt-4" v-if="user && user.EST_Salarier">Utilisateur salarié au sein de la structure {{user.STR_Structure.NomStructure}} <span v-if="user.LIST_MissionSalarie">: {{ user.LIST_MissionSalarie.NomMission }}</span></p>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
    },

    context: {
      type: Object,
    },
  },
}
</script>

<style>

</style>