<template>
  <div class="table__structure_list">
    <v-data-table
      :class="{ 'filters-opened': areFiltersOpened }"
      :headers="headers"
      :fixed-header="true"
      :loading="loading"
      :items="users"
      item-key="id"
      class="elevation-1 list__table"
      :custom-sort="customSort"
    >
      <template v-slot:item.LicenceNumero="{ item }">
        <v-chip v-if="item.LicenceNumero" color="blue" small dark>{{
          licenceNumero(item.LicenceNumero)
        }}</v-chip>
        <!-- <v-chip v-else color="gray" x-small dark>NC</v-chip> -->
      </template>
      <template v-slot:item.DateNaissance="{ item }">
        <span v-if="item.DN_DateNaissance">{{
          $moment(item.DN_DateNaissance).format("DD/MM/YYYY")
        }}</span>
        <span v-else>Date invalide</span>
      </template>
      <template v-slot:item.LicenceType="{ item }">
        <v-chip
          v-if="
            item.UTI_Licences[0] &&
            item.UTI_Licences[0].LIST_LicenceType &&
            item.UTI_Licences[0].LIST_LicenceType.NomTypeLicence
          "
          class="ma-2"
          color="primary"
          outlined
          pill
        >
          {{
            `${
              item.UTI_Licences[0] &&
              item.UTI_Licences[0].LIST_LicenceType &&
              item.UTI_Licences[0].LIST_LicenceType.NomTypeLicence
            } - ${item.UTI_Licences[0] && item.UTI_Licences[0].ID_Saison}`
          }}
        </v-chip>
        <!-- <v-chip v-else color="gray" x-small dark>NC</v-chip> -->
      </template>
      <template v-slot:item.AssuranceType="{ item }">
        <v-chip
          class="ma-2"
          :color="
            licence.ASSU_AssuranceType.ID_AssuranceCategorie === 2
              ? 'success'
              : 'primary'
          "
          pill
          v-for="(licence, i) in item.Assurance"
          :key="i"
        >
          {{ `${licence.ASSU_AssuranceType.NomTypeAssurance}` }}
        </v-chip>
      </template>
      <template v-slot:item.Certificat="{ item }">
        <v-chip
          v-for="(doc, i) in item.DOC_Documents"
          :key="i"
          class="ma-2"
          color="primary"
          outlined
          pill
        >
          {{
            `${
              doc &&
              doc.LIST_DocumentType &&
              doc.LIST_DocumentType.SlugTypeDocument
            }`
          }}
        </v-chip>
        <v-chip
          v-if="item && item.DOC_Documents.length == 0"
          color="gray"
          x-small
          dark
          >NC</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("saisons", ["selectedSaison"]),
  },
  data: () => ({
    openDeclineDialog: false,
    openDetailDialog: false,
    details: {},
    users: [],
  }),
  methods: {
    customSort(data, index, isDesc) {
      data.sort((a, b) => {
        const filterA = a.UTI_Licences[0];
        const filterB = b.UTI_Licences[0];
        if (index[0] == "LicenceType") {
          const saisonIdA = filterA && filterA.ID_Saison;
          const saisonIdB = filterB && filterB.ID_Saison;
          let saisonNameA =
            filterA &&
            filterA.LIST_LicenceType &&
            filterA.LIST_LicenceType.NomTypeLicence;
          let saisonNameB =
            filterB &&
            filterB.LIST_LicenceType &&
            filterB.LIST_LicenceType.NomTypeLicence;
          saisonNameB = saisonNameB === undefined ? "Zzz" : saisonNameB;
          saisonNameA = saisonNameA === undefined ? "Zzz" : saisonNameA;
          if (!isDesc[0]) {
            if (saisonNameA.toLowerCase() > saisonNameB.toLowerCase()) {
              return -1;
            } else if (saisonNameA.toLowerCase() < saisonNameB.toLowerCase()) {
              return 1;
            } else {
              if (saisonIdA > saisonIdB) {
                return -1;
              } else if (saisonIdA < saisonIdB) {
                return 1;
              } else return 0;
            }
          } else {
            if (saisonNameB.toLowerCase() > saisonNameA.toLowerCase()) {
              return -1;
            } else if (saisonNameB.toLowerCase() < saisonNameA.toLowerCase()) {
              return 1;
            } else {
              if (saisonIdB > saisonIdA) {
                return -1;
              } else if (saisonIdB < saisonIdA) {
                return 1;
              } else return 0;
            }
          }
        } else if (index[0] == "LicenceNumero") {
          if (!isDesc[0]) {
            return new Date(b.LicenceNumero) - new Date(a.LicenceNumero);
          } else {
            return new Date(a.LicenceNumero) - new Date(b.LicenceNumero);
          }
        } else if (index[0] == "DN_Naissance") {
          if (!isDesc[0]) {
            return new Date(b.DN_DateNaissance) - new Date(a.DN_DateNaissance);
          } else {
            return new Date(a.DN_DateNaissance) - new Date(b.DN_DateNaissance);
          }
        } else if (index[0] == "CT_Nom") {
          a.CT_Nom = a.CT_Nom === undefined ? "Zzz" : a.CT_Nom;
          b.CT_Nom = b.CT_Nom === undefined ? "Zzz" : b.CT_Nom;
          if (!isDesc[0]) {
            return a.CT_Nom > b.CT_Nom ? -1 : 1;
          } else {
            return b.CT_Nom > a.CT_Nom ? -1 : 1;
          }
        } else if (index[0] == "CT_Prenom") {
          a.CT_Prenom = a.CT_Prenom === undefined ? "Zzz" : a.CT_Prenom;
          b.CT_Prenom = b.CT_Prenom === undefined ? "Zzz" : b.CT_Prenom;
          if (!isDesc[0]) {
            return a.CT_Prenom > b.CT_Prenom ? -1 : 1;
          } else {
            return b.CT_Prenom > a.CT_Prenom ? -1 : 1;
          }
        } else if (
          index[0] == "STR_StructureUtilisateurs[0].STR_Structure.NomStructure"
        ) {
          const structA = a && a.STR_StructureUtilisateurs[0];
          const structB = b && b.STR_StructureUtilisateurs[0];
          let filterStructA =
            structA &&
            structA.STR_Structure &&
            structA.STR_Structure.NomStructure;
          let filterStructB =
            structB &&
            structB.STR_Structure &&
            structB.STR_Structure.NomStructure;
          filterStructA = filterStructA === undefined ? "Zzz" : filterStructA;
          filterStructB = filterStructB === undefined ? "Zzz" : filterStructB;
          if (!isDesc[0]) {
            return filterStructB > filterStructA ? -1 : 1;
          } else {
            return filterStructA > filterStructB ? -1 : 1;
          }
        } else if (index[0] == "AssuranceType") {
          let assuranceNomA = a && a.Assurance && a.Assurance[0];
          let assuranceNomB = b && b.Assurance && b.Assurance[0];
          let dataA =
            assuranceNomA &&
            assuranceNomA.ASSU_AssuranceType &&
            assuranceNomA.ASSU_AssuranceType.NomTypeAssurance.length;
          let dataB =
            assuranceNomB &&
            assuranceNomB.ASSU_AssuranceType &&
            assuranceNomB.ASSU_AssuranceType.NomTypeAssurance.length;
          dataA = dataA === undefined ? 30 : dataA;
          dataB = dataB === undefined ? 30 : dataB;
          if (!isDesc[0]) {
            return dataA - dataB;
          } else {
            return dataB - dataA;
          }
        } else if (index[0] == "Certificat") {
          const certificatA = a && a.DOC_Documents && a.DOC_Documents.length;
          const certificatB = b && b.DOC_Documents && b.DOC_Documents.length;
          if (!isDesc[0]) {
            return new Date(certificatB) - new Date(certificatA);
          } else {
            return new Date(certificatA) - new Date(certificatB);
          }
        }
      });
      return data;
    },
    licenceNumero(licenceNumero) {
      if (licenceNumero !== null) {
        return this.formatLicence(licenceNumero);
      }
      return false;
    },
    formatLicence(licence) {
      let licenceNumero = licence.toString();
      if (licenceNumero.length && licenceNumero.length <= 6) {
        return `${licenceNumero.substr(0, 3)} ${licenceNumero.substr(3, 6)}`;
      }
      return `${licenceNumero.substr(0, 1)} ${licenceNumero.substr(
        1,
        3
      )} ${licenceNumero.substr(4, 7)}`;
    },
  },
  watch: {
    items: function (newval) {
      var userwithlicence = newval.filter(
        (item) => item.UTI_Licences.length !== 0
      );
      console.log("userwithlicence !!!", userwithlicence);
      this.users = userwithlicence;
      this.users.map((certificat) => {
        if (certificat && certificat.DOC_Documents.length != 0) {
          {
            {
              certificat &&
                certificat.DOC_Documents.map((certif) => {
                  let documentType =
                    certif &&
                    certif.LIST_DocumentType &&
                    certif.LIST_DocumentType.SlugTypeDocument;
                  if (
                    certif &&
                    certif.LIST_DocumentType &&
                    certif.LIST_DocumentType.SlugTypeDocument.includes(
                      "certificat_medical"
                    )
                  ) {
                    if (
                      certif &&
                      certif.LIST_DocumentType &&
                      certif.LIST_DocumentType.SlugTypeDocument ===
                        "certificat_medical_competition"
                    ) {
                      certif.LIST_DocumentType.SlugTypeDocument = "Compétition";
                    }
                    if (
                      certif &&
                      certif.LIST_DocumentType &&
                      certif.LIST_DocumentType.SlugTypeDocument ===
                        "certificat_medical_loisir"
                    ) {
                      certif.LIST_DocumentType.SlugTypeDocument = "Loisir";
                    }
                  }
                });
            }
          }
        }
      });
    },
  },

  props: {
    items: {
      type: Array,
    },

    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list__table .v-data-table-header {
  background: rgba(230, 230, 230, 1);
}
th {
  background: lightgrey !important;
}

.table__structure_list {
  .list__table .v-data-table__wrapper {
    height: calc(100vh - 420px);
  }
  .list__table.filters-opened .v-data-table__wrapper {
    height: calc(100vh - 500px);
  }

  &.buttonLine {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 480px);
    }
  }

  &.membresFederaux {
    .list__table .v-data-table__wrapper {
      height: calc(100vh - 310px);
    }
  }
}

/* .listSearch {
    height: calc(100vh - 370px);
    overflow: hidden;
    overflow-y: scroll;
} */
.filters-opened {
  height: calc(100vh - 440px);
}
.periode__class {
  color: lightslategray;
}
</style>
