<template>
  <section class="list_demande_modifications">
    <v-data-table
      :headers="fullHeaders"
      :items="demandesModifs"
    >
      <template v-slot:item.Modif_CT_Prenom="{ item }">
        <span :style="[
          item.UTI_Utilisateur.CT_Prenom !== item.Modif_CT_Prenom ? { fontWeight: '700' } : null,
          !formatUserName(item.Modif_CT_Prenom, 'firstname').isFormatted ? { color: 'red' } : null
        ]">
          {{formatUserName(item.Modif_CT_Prenom, 'firstname').name}}
        </span>
      </template>
      <template v-slot:item.Modif_CT_Nom="{ item }">
        <span :style="[
          item.UTI_Utilisateur.CT_Nom !== item.Modif_CT_Nom ? { fontWeight: '700' } : null,
          !formatUserName(item.Modif_CT_Nom, 'lastname').isFormatted ? { color: 'red' } : null
        ]">
          {{formatUserName(item.Modif_CT_Nom, 'lastname').name}}
        </span>
      </template>
      <template v-slot:item.UTI_Utilisateur.DN_DateNaissance="{ item }">
        <span>{{$moment(item.UTI_Utilisateur.DN_DateNaissance).format('DD/MM/YYYY')}}</span>
      </template>

      <template v-slot:item.Modif_DN_DateNaissance="{ item }">
        <span :style="item.UTI_Utilisateur.DN_DateNaissance !== item.Modif_DN_DateNaissance ? 'font-weight: 700;' : null">
          {{$moment(item.Modif_DN_DateNaissance).format('DD/MM/YYYY')}}
        </span>
      </template>

      <template v-slot:item.UTI_Utilisateur.CT_EST_Civilite="{ item }">
        <span>
          {{getCivilite(item.UTI_Utilisateur.CT_EST_Civilite)}}
        </span>
      </template>

      <template v-slot:item.Modif_CT_EST_Civilite="{ item }">
        <span :style="item.UTI_Utilisateur.CT_EST_Civilite !== item.Modif_CT_EST_Civilite ? 'font-weight: 700;' : null">
          {{getCivilite(item.Modif_CT_EST_Civilite)}}
        </span>
      </template>

      <template v-slot:item.send="{ item }" v-if="context.hasCodeFederal">
        <v-btn icon color="green" @click.prevent="sendToFFME(item, true)">
          <v-icon color="green">mdi-check</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.refuse="{ item }" v-if="context.hasCodeFederal">
        <v-btn icon color="red" @click.prevent="openRefusModal(item)">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.confirm="{ item }" v-if="context.hasCodeFederal">
        <v-btn icon color="green" @click.prevent="updateUser(item)" :disabled="!formatUserName(item.Modif_CT_Prenom, 'firstname').isFormatted || !formatUserName(item.Modif_CT_Nom, 'lastname').isFormatted">
          <v-icon color="green">mdi-check</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.show="{ item }">
        <ActionShowUser :users="demandesModifs" :user="item" :allUser="demandesModifs" :context="context"></ActionShowUser>
      </template>

    </v-data-table>

    <!-- modal commentaire de refus -->
    <v-dialog
    v-model="modalIsOpen"
    max-width="700"
    transition="dialog-bottom-transition"
    class="modal"
    >
    <section class="refus-message">
      <p>Raisons du refus</p>
      <Textarea
        v-model="refusMessage"
        placeholder="Entrez les raisons du refus de la demande de modification"
      ></Textarea>
      <v-btn class="btn btn-primary float-right" @click="sendToFFME(selectedDemande, false)">Envoyer</v-btn>
    </section>
    </v-dialog>
  </section>
</template>

<script>
import {  mapActions } from "vuex";
import { listDeCivilite } from "@/plugins/utils";
import ActionShowUser from "@/components/User/List/User__ListActionShowUser.vue"
import Textarea from "@/components/utils/textarea.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";
import {
  formatName,
} from "@/plugins/userService";
// import { mapGetters, mapActions } from "vuex";
export default {
  components: { ActionShowUser, Textarea },
  props: {
    headers: {
      type: Array,
    },
    demandesModifs: {
      type: Array,
    },
    isFFME: {
      type: Boolean
    },
    context: {
      type: Object
    }
  },
  data: () => ({
    modalIsOpen: false,
    refusMessage: null,
    selectedDemande: null
  }),
  computed: {
    fullHeaders(){
      let headers = [...this.headers, { text: "Refuser", value: "refuse" }]
      if(this.isFFME) headers.push({ text: "Valider", value: "confirm" })
      else headers.push({ text: "Envoyer", value: "send" })
      return headers
    }
  },
  methods: {
    ...mapActions('utilisateurs', ['updateDemandeModif', "updateUserModif"]),
    getCivilite(value){
      let civiliteTemp = ""
      listDeCivilite().forEach(civilite => {
        if(civilite.value === value) civiliteTemp = civilite.text
      });
      return civiliteTemp
    },
    async sendToFFME(item, isSentToFFME){
      try {
        let payload = {}
        if(this.isFFME) payload = {
          id: item.id,
          fields: {
            ID_UtilisateurValidation : this.context.ID_UtilisateurDemande,
            Z_DateValidation : new Date(),
            EST_Demande_Traitee: true,
            EST_Demande_Validee: isSentToFFME,
            Message: this.refusMessage
          }
        }
        else payload= {
          id: item.id,
          fields: {
            EST_Demande_Validee: isSentToFFME,
            ID_UtilisateurExpeFFME: this.context.ID_UtilisateurDemande,
            Z_DateConfirmationExpeFFME: new Date(),
            Message: this.refusMessage
          }
        }
      await this.updateDemandeModif(payload)
      this.modalIsOpen = false;
      this.refusMessage = null
      this.$emit('onUpdated')
      success_notification(isSentToFFME ? 'La demande a été envoyé à la FFME' : "Refus de la demande pris en compte")
      } catch (error) {
        error_notification("Oups, une erreur est survenue...");
      }
    },
    async updateUser(item){
      try {
        const lastname = formatName(item.Modif_CT_Nom, 'lastname')
        const firstname = formatName(item.Modif_CT_Prenom, 'firstname')

        let birthname;

        if (item.Modif_CT_NomDeNaissance) {
          birthname = formatName(item.Modif_CT_NomDeNaissance, 'lastname')
        }

        let payload = {
          id: item.id,
          fields: {
            ID_UtilisateurValidation : this.context.ID_UtilisateurDemande,
            Z_DateValidation : new Date(),
            EST_Demande_Traitee: true
          },
          userModifications: {
            CT_EST_Civilite: item.Modif_CT_EST_Civilite,
            CT_Nom: lastname,
            CT_NomDeNaissance: birthname,
            CT_Prenom: firstname,
            DN_CommuneNaissance: item.Modif_DN_CommuneNaissance,
            DN_CommuneNaissance_CodeInsee: item.Modif_DN_CommuneNaissance_CodeInsee,
            DN_DateNaissance: item.Modif_DN_DateNaissance,
            ID_PaysNationalite: item.Modif_ID_PaysNationalite,
          },
          ID_Modified_User: item.UTI_Utilisateur.id
        }
        await this.updateUserModif({payload})
        this.$emit('onUpdated')
        success_notification("Les données de l'utilisateurs ont été modifiées")
      } catch (error) {
        error_notification("Oups, une erreur est survenue...");
      }
      // console.log('item:', item)
    },
    openRefusModal(item) {
      this.selectedDemande = item
      this.modalIsOpen = true
    },
    formatUserName(name, type) {
      try {
        const formattedName = formatName(name, type);

        return {
          name: formattedName,
          isFormatted: true,
        };
      } catch (error) {
        return {
          name,
          isFormatted: false,
        };
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .v-dialog {
    background: white !important;
    margin: auto;
    padding: 1em !important;
  }
  .refus-message {
    width: 80%;
    margin: auto
  }
  </style>
