import { render, staticRenderFns } from "./User__MergeDuplicate.vue?vue&type=template&id=04b2f0f2&scoped=true&"
import script from "./User__MergeDuplicate.vue?vue&type=script&lang=js&"
export * from "./User__MergeDuplicate.vue?vue&type=script&lang=js&"
import style0 from "./User__MergeDuplicate.vue?vue&type=style&index=0&id=04b2f0f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04b2f0f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VRow})
