<template>
  <div class="row mt-6 mx-1">
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Handicap
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ValidateForm @validated="submitHandiForm" :loading="loading" class="col-12">
            <RadioGroup
              v-if="!componentLoading"
              v-model="hasDisabilities"
              :checked="hasDisabilities"
              :options="[{ value: true, label: 'Oui' }, { value: false, label: 'Non' }]"
              label="Je suis en situation de handicap"
            ></RadioGroup>
            <selectInput
              v-if="hasDisabilities"
              label="Type de handicap"
              rules="required"
              :items="listDisabilities"
              item_text="name"
              item_value="id"
              v-model="userDisabilities"
              multiple
            ></selectInput>
            <v-card-actions class="px-10 pb-4">
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :loading="loading"
                small
                rounded
                class="btn btn-primary"
                >Enregistrer les informations</v-btn
              >
            </v-card-actions>
          </ValidateForm>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ValidateForm from "@/components/utils/form.vue";
import selectInput from "@/components/utils/select.vue";
import RadioGroup from "@/components/utils/radioGroup.vue";


export default {
  props: {
    context: {
      type: Object,
    },
    userId: {
      type: String,
    },
  },

  components: {
    RadioGroup,
    selectInput,
    ValidateForm
  },

  data: () => ({
    componentLoading: true,
    loading: false,
    hasDisabilities: false,
    userDisabilities: [],
    listDisabilities: [],
    panel: 0,
  }),

  async created() {
    this.listDisabilities = await this.getListDisabilities();

    this.userDisabilities = await this.getUserDisabilities(this.userId);
    this.userDisabilities = this.userDisabilities.map(
      (disability) => disability.id_disability
    );

    this.hasDisabilities = this.userDisabilities.length > 0;

    this.componentLoading = false;
    return;
  },

  methods: {
    ...mapActions("basics", ["getListDisabilities"]),
    ...mapActions("user", ["getUserDisabilities", "updateDisabilities"]),

    submitHandiForm() {
      this.loading = true;

      this.updateDisabilities({
        id: this.userId,
        disabilities: this.hasDisabilities ? this.userDisabilities.map((handicapId) => { return { id_utilisateur: this.userId, id_disability: handicapId }; }) : [],
      });

      this.loading = false;
    }
  },

};
</script>
