<template>
  <span v-if="hasAuthization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="openModal"
          class="mx-1"
          fab
          x-small
          :outlined="!user.EST_Salarier"
          color="blue"
        >
          <v-icon :color="user.EST_Salarier ? 'white' : null"
            >mdi-account-outline</v-icon
          >
        </v-btn>
      </template>
      <span v-if="!user.EST_Salarier">Voir la fiche de l'utilisateur</span>
      <span v-else>Voir la fiche du salarié</span>
    </v-tooltip>
    <modalUserDetail
      v-if="modalIsOpen && mounted"
      :modalIsOpen="modalIsOpen"
      :context="{ ...context, user, fromModal: true }"
      :user="currentUser"
      :all-user="allUser"
      @onClose="closeModal"
      @onNext="nextUser"
      @onPrev="prevUser"
      :areNextPrevDisabled="areNextPrevDisabled"
      :showFusion="showFusion"
    />
  </span>
</template>

<script>
import modalUserDetail from "@/components/utils/modals/User/User__Modal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { modalUserDetail },

  data: () => ({
    modalIsOpen: false,
    currentUserId: null,
    currentUserFormatted: null,
    mounted: false,
  }),

  async created() {
    this.currentUserId = this.user.ID_Utilisateur || this.user.user.id;

    if (this.user?.['@type']) {
      this.currentUserId = this.user.user.id;

      let whereClause = {
        EST_Actif: { _eq: true },
        ID_Utilisateur: { _eq: this.currentUserId }
      };

      let membres = await this.getStructureMembre({
        where: {
          ...whereClause
        }
      });

      this.currentUserFormatted = membres.find((membre) => membre.ID_Utilisateur === this.currentUserId);
    }
    this.mounted = true;
  },

  computed: {
    ...mapGetters("user", ["can"]),

    hasAuthization() {
      return this.can("gestion_des_utilisateurs__fiche_licencie");
    },

    currentUser: {
      get() {
        let currentUser = this.allUser.find(
          (user) => user.ID_Utilisateur == this.currentUserId || user.user.id == this.currentUserId
        );

        if (currentUser?.['@type']) {
          currentUser = this.currentUserFormatted;
        }

        return currentUser;
      },
      set(val) {
        this.currentUserId = val;
      },
    },
    currentIndex() {
      return this.allUser.findIndex(
        (user) => user.ID_Utilisateur === this.currentUser.ID_Utilisateur || user.user.id === this.currentUser.ID_Utilisateur
      );
    },

    showFusion() {
      if (this.user?.['@type']) {
        return false;
      }

      return true;
    }
  },

  methods: {
    ...mapActions("structure", ["getStructureMembre"]),
    closeModal() {
      this.modalIsOpen = false;
    },

    openModal() {
      this.modalIsOpen = true;
      this.currentUser = this.user.ID_Utilisateur;
    },

    nextUser() {
      let index = this.currentIndex;
      if (index === this.allUser.length - 1) index = 0;
      else index++;
      this.currentUser = this.allUser[index].ID_Utilisateur;
    },

    prevUser() {
      let index = this.currentIndex;
      index--;
      this.currentUser = this.allUser[
        index < 0 ? this.allUser.length - 1 : index
      ].ID_Utilisateur;
    },

    areNextPrevDisabled() {
      if (this.currentUser?.['@type']) {
        return true;
      }

      return !(this.users.length > 1);
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },
    users: {
      type: Array,
    },

    allUser: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.v-dialog .v-icon {
  top: inherit !important;
}
</style>
