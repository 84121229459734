<template>
  <span v-if="hasAuthorization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          @click="openModal"
          class="mx-1"
          fab
          x-small
          outlined
          color="green"
          :disabled="disabled"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Ajouter à une structure</span>
    </v-tooltip>

    <AddUserToStructure
      v-if="modalIsOpen"
      :user="currentUser"
      :context="{ ...context, currentStructure }"
      :isOpen="modalIsOpen"
      :currentRole="getRole"
      @onAdded="onAdded"
      @onClose="closeModal"
    ></AddUserToStructure>
  </span>
</template>

<script>
import AddUserToStructure from "@/components/utils/modals/User/User__AddToStructure.vue";
import { mapGetters } from "vuex";

export default {
  components: { AddUserToStructure },

  data: () => ({
    modalIsOpen: false,
  }),
  created() {
    // console.log()
  },

  computed: {
    ...mapGetters("user", ["can", "getRole"]),
    ...mapGetters("structure", ["currentStructure"]),

    disabled() {
      return false;
      // if(this.getRole.id === 1) {
      //   return false
      // }
      // else {
      //   return this.currentUser.EST_RoleFederal
      // }
    },

    hasAuthorization() {
      return this.can(
        "gestion_des_utilisateurs__gestion_des_membres_de_la_structure"
      );
    },

    currentUser() {
      if (this.utiFirst) {
        console.log(this.user);
        let user = {};
        user.ID_Structure = this.context.ID_Structure;
        (user.UTI_Utilisateur = this.user),
          (user.ID_Utilisateur = this.user.id);
        return user;
      }

      return this.user;
    },
  },

  methods: {
    closeModal() {
      this.modalIsOpen = false;
    },

    onUpdate(items) {
      this.$emit("onUpdate", items);
    },

    openModal() {
      this.modalIsOpen = true;
    },
    onAdded() {
      this.closeModal();
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    renewLicence: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
