<template>
  <span v-if="hasAuthization">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
            v-on="on"
            @click="openModal"
            class="mx-1"
            fab
            x-small
            outlined
            color="green"
            :disabled="!canEditLicence"
          >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Licence</span>
    </v-tooltip>

    <modalLicence
      :user="currentUser"
      :context="context"
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      @onUpdate="onUpdate"
      @onClose="closeModal"
      :renewLicence="renewLicence"
    ></modalLicence>
     <modalLicenceSiege
      :user="currentUser"
      :context="context"
      v-if="modalSiegeIsOpen"
      :modalIsOpen="modalSiegeIsOpen"
      @onUpdate="onUpdate"
      @onClose="closeModal"
      :renewLicence="renewLicence"
    ></modalLicenceSiege>
  </span>
</template>

<script>
import modalLicence from "@/components/utils/modals/Licence/Licence__Modal.vue";
import modalLicenceSiege from "@/components/utils/modals/Licence/LicenceSiege__Modal.vue";
import { mapGetters } from "vuex"

export default {
  components: { modalLicence, modalLicenceSiege },

  data: () => ({
    modalIsOpen: false,
    modalSiegeIsOpen: false,
  }),
  created() {
    //console.log(this.user)
  },

  computed: {
    ...mapGetters('saisons', ['currentSaison', 'selectedSaison']),
    ...mapGetters('user', ['can']),

    hasAuthization() {
      return this.can('gestion_des_licences__renouveler_licences_en_serie')
    },

    canEditLicence() {
      // un utilisateur peut renouveler sa licence si il n'a pas de licence active pour la saison en cours
      // ou si il a une licence inactive pour la saison en cours et qu'il n'a pas de licence active pour la saison en cours
      let activesLicenceForThisSeason = [];
      let unActivesLicenceForThisSeason = [];
      let isPossibleToRenewForThisSeason = false;

      if(this.user.UTI_Licence && this.user.UTI_Licence.length > 0) {
        activesLicenceForThisSeason = this.user.UTI_Licence.filter(licence => {
          return licence.ID_Saison === this.currentSaison.id && licence.EST_Actif;
        });
        unActivesLicenceForThisSeason = this.user.UTI_Licence.filter(licence => {
          return licence.ID_Saison === this.currentSaison.id && !licence.EST_Actif;
        });

        if (activesLicenceForThisSeason.length == 0 && unActivesLicenceForThisSeason.length > 0) {
          isPossibleToRenewForThisSeason = true;
        } else if (activesLicenceForThisSeason.length == 0 && unActivesLicenceForThisSeason.length == 0) {
          isPossibleToRenewForThisSeason = true;
        } else {
          isPossibleToRenewForThisSeason = false;
        }
      }

      if (this.user.UTI_Licence && this.user.UTI_Licence.length === 0) {
        return true;
      } else if (this.user.EST_StructureLicencie && isPossibleToRenewForThisSeason) {
        return true;
      } else if ((this.context.ID_Saison == this.selectedSaison.id) && isPossibleToRenewForThisSeason) {
        return true;
      } else if ((this.user.EST_StructureLicencie && (this.context.ID_Saison == this.currentSaison.id)) && isPossibleToRenewForThisSeason) {
        return true;
      } else {
        return false;
      }
    },

    currentUser() {
      if (this.utiFirst) {
        //console.log(this.user);
        let user = {};
        user.ID_Structure = this.context.ID_Structure;
        (user.UTI_Utilisateur = this.user),
          (user.ID_Utilisateur = this.user.id);
        return user;
      }

      return this.user;
    },
  },

  methods: {
    closeModal() {
      if(this.$route.name === "gestion_des_licences__saisir_une_licence_siege") {
      this.modalSiegeIsOpen = false;
      }
      else {
      this.modalIsOpen = false;
      }
    },

    onUpdate(items) {
      this.$emit("onUpdate", items);
    },

    openModal() {
      if(this.$route.name === "gestion_des_licences__saisir_une_licence_siege") {
      this.modalSiegeIsOpen = true;
      }
      else {
      this.modalIsOpen = true;
      }
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    renewLicence: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
