<template>
  <v-chip v-if="licenceNumero" color="blue" small dark>{{
    licenceNumero
  }}</v-chip>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    licenceNumero() {
      if (this.utiFirst) {
        if (this.item.EST_Licencie) {
          return this.formatLicence(this.item.LicenceNumero);
        }
        return false;
      }
      if (this.item.UTI_Utilisateur.EST_Licencie) {
        return this.formatLicence(this.item.UTI_Utilisateur.LicenceNumero);
      }
      return false;
    },
  },

  methods: {
    formatLicence(licence) {
      let licenceNumero = licence.toString();
      if (licenceNumero.length && licenceNumero.length <= 6) {
        return `${licenceNumero.substr(0, 3)} ${licenceNumero.substr(3, 6)}`;
      }
      return `${licenceNumero.substr(0, 1)} ${licenceNumero.substr(
        1,
        3
      )} ${licenceNumero.substr(4, 7)}`;
    },
  },

  props: {
    item: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
