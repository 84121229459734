<template>
  <div v-if="licence">
    <v-chip small outlined color="blue">
      {{ licence && licence.LIST_LicenceType.NomTypeLicence }} -
      {{ licence && licence.ID_Saison }}
    </v-chip>
  </div>
  <v-chip v-else color="gray" x-small dark>NC</v-chip>
</template>

<script>
export default {
  computed: {
    licence() {
      let licence = {};

      //If we want the last licence the user have
      // Todo ici affichage du type de la licence type à checker
      if (!this.item.UTI_Licence) return false;
      if (this.saisonInput && this.saisonInput !== 2) {
        licence = this.item?.UTI_Licence?.find(
          (licence) => licence.ID_Saison === this.saisonInput
        );
      } else {
        let filterLicences;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        filterLicences = this.item?.UTI_Licence?.filter(item => item.EST_Actif).sort(
          (a, b) => b.ID_Saison - a.ID_Saison
        );
        licence = filterLicences[0];
      }

      return licence;
    },
  },

  props: {
    saisonInput: {
      type: Number,
    },
    item: {
      type: Object,
    },

    context: {
      type: Object,
    },

    utiFirst: {
      type: Boolean,
      default: false,
    },

    lastOne: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
