<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_utilisateurs__dashboard"
      />
    </v-row>

    <!-- <userList v-if="currentStructureType.SlugTypeStructure == 'club'"
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentAuthUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
      }"
      :headers="headers" 
      dephResult
      simpleSearch
      :actions="getActions"
    ></userList> -->

    <userListFederaux
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentAuthUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
      }"
      :actions="getActions"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import userListFederaux from "@/components/User/List/User__ListFederaux.vue";

export default {
  components: { SubBar, userListFederaux },

  data: () => ({
    // headers: [
    //   { text: "Prénom", value: "CT_Prenom" },
    //   { text: "Nom", value: "CT_Nom" },
    //   { text: "Numéro de licence", value: "LicenceNumero" },
    //   { text: "Licence type", value: "ID_LicenceType" },
    //   { text: "Saison", value: "ID_LicenceSaison" },
    //   // { text: 'Role Federal', value: 'EST_RoleFederal' },
    //   // { text: 'Role structure', value: 'ID_Role' },
    //   // { text: 'Statut licence', value: 'ID_EtapeLicence' },
    //   { text: "Actions", value: "actions" },
    // ],
  }),

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "currentStructureType",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["currentUserId"]),

    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },

    getActions() {
      let actions = "show|editRole|addToStructure";
      return actions;
    },

    subBarTitle() {
      let type = this.currentStructure.STR_Structure.LIST_StructureType
        .SlugTypeStructure;
      if (type == "ligue") {
        return "Membres féderaux de la ligue";
      } else if (type == "comite_territorial") {
        return "Membres féderaux du CT";
      } else if (type == "service_federal_ffme") {
        return "Membres féderaux de la FFME";
      } else if (type == "club") {
        return "Membres féderaux de mon club";
      }
      return "Membres féderaux de ma structure";
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
