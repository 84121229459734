export const waitingValidation = 'waiting_validation';
export const validate = 'validate';
export const invalid = 'invalid';


export const waitingDocument = 'waiting_document';
export const loisir = 'loisir';
export const competition = 'competition';
export const qs = 'qs';

export const waitingValidationLabel = 'À vérifier';
export const validateLabel = 'Validé';
export const invalidLabel = 'Non validé';

export const waitingDocumentLabel = 'En attente';
export const loisirLabel = 'Certificat médical loisir';
export const competitionLabel = 'Certificat médical compétition';
export const qsLabel = 'Questionnaire Santé';

export const docStatus = {
  [waitingValidation]: waitingValidationLabel,
  [validate]: validateLabel,
  [invalid]: invalidLabel,
};
