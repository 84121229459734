/**
 * Formatte le prénom ou le nom de famille d'un utilisateur en fonction du type
 *
 * @param {string} value Le prénom ou le nom de famille à formater
 * @param {string} type 'firstname' ou 'lastname'
 * @return {string} Le prénom ou le nom de famille formaté
 */
export const formatName = (value, type = 'firstname') => {
  // S'il n'y a aucune lettre, alors on lance une erreur
  if (!/\p{L}/u.test(value)) {
    throw new Error('Le prénom ou le nom de famille doit contenir des lettres.');
  }

  switch (type) {
    case 'firstname':
      value = value.toLowerCase();
      break;
    case 'lastname':
      value = value.toUpperCase();
      break;
  }

  // Supprime tous les numéros
  value = value.replace(/[0-9]/g, '');

  // Remplace les underscores par des tirets
  value = value.replace(/_/g, '-');

  // Retirer les caractères spéciaux au début et à la fin de la chaîne (sauf lettres accentuées)
  value = value.replace(/^[^\p{L}]+|[^\p{L}]+$/gu, '');

  // Remplacer les caractères non alphanumériques (sauf pour les accents)
  value = value.replace(/[^\p{L}\p{N}_\s'-]/gu, '');

  // Nettoie les espaces autour des apostrophes et remplace les espaces multiples par un seul espace
  value = value.replace(/\s*'\s*/g, "'");
  value = value.replace(/\s+/g, ' ');
  value = value.replace(/\s*-\s*/g, '-');

  // Met en majuscule la lettre suivant les espaces, apostrophes et tirets
  value = value.replace(/([\s'-])(\p{L})/gu, (_, separator, letter) => separator + letter.toUpperCase());

  // Formatte les mots : chaque mot commence par une majuscule, y compris les accents
  value = value.replace(/(\p{L})/u, (char) => char.toUpperCase());

  // Remplace les tirets multiples par un seul tiret
  switch (type) {
    case 'firstname':
      value = value.replace(/-{2,}/g, '-');
      break;
    case 'lastname':
      value = value.replace(/-{2,}/g, '--');
      break;
  }

  return value;
}