<template>
  <v-dialog
    v-model="modalIsOpen"
    fullscreen
    hide-overlay
    persistent
    no-click-animation
    transition="dialog-bottom-transition"
    class="mymoad p-0"
    style="background: #fff !important;"
    @keydown.esc="$emit('onClose')"
  >
    <v-row
      class="flex-nowrap moncompte__layout"
      no-gutters
      style="background: #fff !important;"
    >
      <v-col class="col-lg-3 position-relative col-md-3 col-sm-5 left-layout">
        <div
          class="mt-5 h-100 overflow-auto"
          style="min-width: 380px !important;"
        >
          <!-- inside layout -->
          <v-btn
            @click="$emit('onClose')"
            class="gestionduclub__subbar-back-icon"
            style="width: 100%;"
            text
            exact
          >
            <v-icon left large>mdi-chevron-left</v-icon>
            <span class="">Retour</span>
          </v-btn>

          <Avatar
            :context="{ ID_Utilisateur: user.ID_Utilisateur }"
            size="120"
            upload
            :key="`${user.ID_Utilisateur}-avatar`"
          />

          <BasicInfoUser
            :context="{
              ID_Utilisateur: user.ID_Utilisateur,
              ID_Saison: currentSaison.id,
            }"
            :key="`${user.ID_Utilisateur}-basicinfo`"
            class="mb-3"
            @showMergeDuplicate="setActive('duplicate')"
            :showFusion="showFusion"
          />
          <p
            class="text-center m-0 pt-3"
            v-if="EST_President && EST_UserConnected && EST_UserCognito"
          >
            <v-btn color="btn btn-primary" @click.prevent="sendPassword"
              >Renvoyer le mot de passe</v-btn
            >
          </p>
          <p
            class="text-center m-0 pt-3"
            v-if="EST_President && !EST_UserConnected"
          >
            <v-btn
              color="btn btn-primary"
              class="mb-3"
              @click.prevent="resendEmailActivation"
              >Renvoyer un mot de passe</v-btn
            >
          </p>
        </div>
      </v-col>

      <div v-if="modalInfoOpen" class="w-100 overflow-auto">
        <v-col
          v-if="modalInfoOpen"
          class="col-lg-12 col-md-12 moncompte__content"
        >
          <div class="moncompte__title-section row justify-space-between d-flex">
            <div>
              <div v-if="!areNextPrevDisabled && previewUser" class="navigate previous d-flex align-items-center">
                  <span @click.prevent="prev" class="d-flex align-items-center pr-1">
                    <v-icon left large>mdi-chevron-left</v-icon>
                    <span class="">Précédent</span>
                  </span>
                  <strong>{{ previewUser }}</strong>
                </div>
              </div>
            <div>
              <div
                  v-if="!areNextPrevDisabled"
                  class="navigate next d-flex align-items-center"
              >
                <strong>{{ nextUser }}</strong>
                <span @click.prevent="next" class="d-flex align-items-center pl-1">
                    <span class="">Suivant</span>
                    <v-icon left large>mdi-chevron-right</v-icon>
                </span>
              </div>
            </div>
          </div>
          <div>
            <div class="my-1">
              <div class="d-flex align-center justify-space-around">
                <div
                  class="card-item d-flex flex-column"
                  @click.prevent="setActive('informations-generales')"
                >
                  <span class="cart-text text-center"
                    >Informations générales
                  </span>
                  <div
                    v-if="isItemActive('informations-generales')"
                    class="bottom-layer-2"
                  />
                </div>
                <div class="card-item" @click.prevent="setActive('assurances')">
                  <span class="cart-text text-center"
                    >Licences et assurances</span
                  >
                  <div
                    v-if="isItemActive('assurances')"
                    class="bottom-layer-2"
                  />
                </div>
                <div
                  class="card-item"
                  @click.prevent="setActive('certificat-medical')"
                >
                  <span class="cart-text text-center"
                    >Certificats médicaux</span
                  >
                  <div
                    v-if="isItemActive('certificat-medical')"
                    class="bottom-layer-2"
                  />
                </div>
                <div
                  class="card-item"
                  @click.prevent="setActive('personnes-a-prevenir')"
                >
                  <span class="cart-text text-center"
                    >Personne(s) à prévenir en cas d’accident</span
                  >
                  <div
                    v-if="isItemActive('personnes-a-prevenir')"
                    class="bottom-layer-2"
                  />
                </div>

                <div
                  v-if="user && user.EST_RoleFederal"
                  class="card-item"
                  @click.prevent="setActive('role')"
                >
                  <span class="cart-text text-center" href="#role"
                    >Rôle au sein de la structure</span
                  >
                  <div v-if="isItemActive('role')" class="bottom-layer-2" />
                </div>
                <div
                  class="card-item"
                  v-if="user && user.EST_Salarier"
                  @click.prevent="setActive('poste')"
                >
                  <span class="cart-text text-center" href="#poste"
                    >Poste au sein de la structure</span
                  >
                  <div v-if="isItemActive('poste')" class="bottom-layer-2" />
                </div>
              </div>
            </div>
            <div class="bottom-layer mb-10" />
          </div>

          <div
            class="moncompte__content-content-view user-details__form-container align-self-stretch flex-grow-1"
          >
            <v-row no-gutters class="height-100">
              <v-col class="pb-8">
                <UserDetailsForm
                  :context="{
                    ...context,
                    ID_Utilisateur: user.ID_Utilisateur,
                    ID_Structure: user.ID_Structure,
                  }"
                  :key="user.ID_Utilisateur"
                  adminMode
                  @dataChanged="onDataChanged"
                  v-if="isActive('informations-generales')"
                  @setTitle="setTitle"
                />

                <UserPersonneAPrevenirForm
                  :context="{ ...context, ID_Utilisateur: user.ID_Utilisateur }"
                  edit
                  v-if="isActive('personnes-a-prevenir')"
                  :key="`${previewUser}-${user.ID_Utilisateur}-personnes-a-prevenir`"
                  @setTitle="setTitle"
                />

                <UserCertificatMedicalForm
                  :context="{ ...context, ID_Utilisateur: user.ID_Utilisateur }"
                  v-if="isActive('certificat-medical')"
                  @setTitle="setTitle"
                  :key="`${previewUser}-${user.ID_Utilisateur}-certificat-medical`"
                />
                <OptionsAssurances
                  :context="{
                    user,
                    ...context,
                    ID_Utilisateur: user.ID_Utilisateur,
                    ID_Saison: currentSaison.id,
                    ID_Structure: user.ID_Structure,
                  }"
                  @onUpdate="onUpdateAssu"
                  :key="`${previewUser}-${user.ID_Utilisateur}` + customKey"
                  v-if="isActive('assurances')"
                  @setTitle="setTitle"
                  ref="optionAssu"
                />

                <HistoriqueOptionAssurance
                  :context="{
                    ...context,
                    ID_Utilisateur: user.ID_Utilisateur,
                    ID_Structure: user.ID_Structure,
                    ID_Saison: null,
                    all: true,
                  }"
                  ref="historiqueAssu"
                  v-if="isActive('assurances')"
                  :key="`${previewUser}-${user.ID_Utilisateur}-assurances`"
                  @setTitle="setTitle"
                />

                <RoleModule
                  :user="user"
                  :context="context"
                  ref="role"
                  v-if="isActive('role')"
                  @setTitle="setTitle"
                  :key="`${previewUser}-${user.ID_Utilisateur}-role`"
                />
                <UserPosteModule
                  :user="user"
                  :context="context"
                  ref="role"
                  v-if="isActive('poste')"
                  @setTitle="setTitle"
                  :key="`${previewUser}-${user.ID_Utilisateur}-poste`"
                />
              </v-col>
            </v-row>
          </div>
        </v-col>
      </div>
      <div v-if="modalDuplicateOpen" class="w-100 overflow-auto">
        <v-row style="height: 64px;" no-gutters>
          <v-app-bar flat class="subbar">
            <v-row>
              <v-col cols="2">
                <v-btn
                  @click.prevent="returnModal"
                  class="gestionduclub__subbar-back-icon"
                  text
                  exact
                >
                  <v-icon left large>mdi-chevron-left</v-icon> Retour
                </v-btn>
              </v-col>

              <v-col class="d-flex justify-space-around">
                <v-toolbar-title
                  class="text-uppercase subtitle-1 font-weight-bold font-family-Roboto"
                  >{{ subBarTitle }}</v-toolbar-title
                >
              </v-col>
              <v-col cols="2" class="d-flex justify-end" />
            </v-row>
          </v-app-bar>
        </v-row>
        <v-row class="m-0">
          <v-col cols="12" class="p-0 position-relative">
            <UserMergeDuplicate
              v-if="isActive('duplicate')"
              class="m-0"
              :currentUser="user"
              :modalDuplicateOpen="modalDuplicateOpen"
              @onClose="$emit('onClose')"
            />
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-dialog>
</template>

<script>
import Avatar from '@/components/Common/Common__Avatar.vue';
import UserPersonneAPrevenirForm from '@/components/Pap/PAP__List.vue';
import UserDetailsForm from '@/components/User/Form/User__FormModificationCoordonnees.vue';
import UserCertificatMedicalForm from '@/components/User/Form/User__FormCertificatMedical.vue';
import UserPosteModule from '@/components/User/User__PosteModule.vue';
import HistoriqueOptionAssurance from '@/components/Assurances/Assurances__Historique.vue';
import BasicInfoUser from '@/components/User/User__BasicInfo.vue';
import { resend_email_user } from '@/plugins/utils';
import RoleModule from '@/components/utils/modals/Modules/Modules.vue';
import UserMergeDuplicate from '@/components/User/User__MergeDuplicate.vue';

import OptionsAssurances from '@/components/Assurances/Assurances__List.vue';
import {
  success_notification,
  error_notification,
} from '@/plugins/notifications';
import { mapGetters } from 'vuex';

export default {
  components: {
    Avatar,
    UserDetailsForm,
    UserPersonneAPrevenirForm,
    UserCertificatMedicalForm,
    OptionsAssurances,
    HistoriqueOptionAssurance,
    BasicInfoUser,
    RoleModule,
    UserPosteModule,
    UserMergeDuplicate,
  },

  data: () => ({
    title_section: 'Informations générales',
    activeTab: 'informations-generales',
    customKey: 0,
    activeItem: 'informations-generales',
    modalInfoOpen: true,
    modalDuplicateOpen: false,
    previewUser: null,
    nextUser: null,
  }),

  computed: {
    ...mapGetters('saisons', ['currentSaison']),
    ...mapGetters('user', ['getRole', 'currentUser']),
    ...mapGetters('structure', ['currentStructure']),

    subBarTitle() {
      return 'Fusionner un doublon';
    },

    EST_UserCognito() {
      console.log('EST_UserCognito', this.user.UTI_Utilisateur.LOG_Login);
      if (this.user.UTI_Utilisateur.LOG_Login !== null) {
        return true;
      }
      return false;
    },

    EST_UserConnected() {
      console.log(
        'Z_DatePremiereConnexion',
        this.user.UTI_Utilisateur.Z_DatePremiereConnexion
      );
      if (this.user.UTI_Utilisateur?.Z_DatePremiereConnexion) {
        return true;
      } else {
        return false;
      }
    },

    Has_LogLogin() {
      console.log(
        'this.user.UTI_Utilisateur.LOG_Login',
        this.user.UTI_Utilisateur.LOG_Login
      );
      if (this.user.UTI_Utilisateur.LOG_Login !== null) {
        return true;
      }

      return false;
    },

    EST_President() {
      console.log('this.getRole', this.getRole);
      if (
        this.getRole.SlugRole === 'club_president' ||
        this.getRole.SlugRole === 'ct_president' ||
        this.getRole.SlugRole === 'ligue_president' ||
        this.getRole.SlugRole === 'super_administrateur' ||
        this.getRole.SlugRole === 'club_administrateur' ||
        this.getRole.SlugRole === 'ligue_administrateur' ||
        this.getRole.SlugRole === 'ct_administrateur' ||
        this.getRole.SlugRole === 'ea_administrateur'
      )
        return true;
      return false;
    },

    User_LOG_LOGIN() {
      if (
        this.user &&
        this.user.UTI_Utilisateur &&
        this.user.UTI_Utilisateur.LOG_Login
      )
        return true;
      return false;
    },
  },
  watch: {
    user: {
      handler() {
        const indexOfUser = this.allUser.findIndex(
          (user) => user.id === this.user.id
        );
        const previewUser = this.allUser[indexOfUser - 1];
        this.previewUser = previewUser
          ? `${previewUser?.UTI_Utilisateur?.CT_Nom} ${previewUser?.UTI_Utilisateur?.CT_Prenom}`
          : null;
        const nextUser = this.allUser[indexOfUser + 1];
        this.nextUser = nextUser
          ? `${nextUser?.UTI_Utilisateur?.CT_Nom} ${nextUser?.UTI_Utilisateur?.CT_Prenom}`
          : null;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    testShow() {
      console.log('testShow testShow');
    },
    isItemActive(item) {
      if (this.activeItem === item) return true;
      return false;
    },

    sendPassword() {
      let username = null;
      let newEmail = localStorage.getItem('changeNewEmail');
      if (!newEmail) {
        username = this.user.UTI_Utilisateur.LOG_Login;
      } else {
        username = newEmail;
      }
      // console.log("LOG_Login", this.user.UTI_Utilisateur.LOG_Login);
      const fetch = require('node-fetch');
      (async () => {
        this.loading = true;
        const response = await fetch(
          process.env.VUE_APP_FFME_BACK_URL + '/api/users/forget-password',
          {
            method: 'post',
            body: JSON.stringify({
              username: username,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const json = await response.json();

        if (response.status == 200) {
          success_notification(
            'Le lien de réinitialisation du mot de passe a été envoyé à ' +
              this.user.UTI_Utilisateur.CT_Prenom +
              ' ' +
              this.user.UTI_Utilisateur.CT_Nom +
              " à l'adresse " +
              this.user.UTI_Utilisateur.CT_Email
          );
        } else {
          error_notification(json.message);
        }
        this.loading = false;
      })();
    },

    // envoyer mail d'activation compte
    async resendEmailActivation() {
      console.log('username resendEmailActivation', this.user.UTI_Utilisateur.LOG_Login);

      let response = await resend_email_user(this.user.UTI_Utilisateur.LOG_Login);

      if (!response.ok) {
        error_notification('une erreur est survenue');
        return;
      } else {
        success_notification(
          `Un courriel d'activation de compte a été envoyé à ${this.user.UTI_Utilisateur.CT_Prenom} ${this.user.UTI_Utilisateur.CT_Nom}`
        );
        return;
      }
    },

    onUpdateAssu(assu) {
      this.customKey += 1;
    },

    setTitle(title) {
      this.title_section = title;
    },

    onDataChanged(payload) {
      if (payload === false) {
        this.$emit('retour');
      } else {
        success_notification(
          'Le Club recevra une notification de votre demande de changement.'
        );
        this.$emit('retour');
      }
    },

    isActive(menuItem) {
      return this.activeTab === menuItem;
    },

    setActive(menuItem) {
      this.activeTab = menuItem;
      this.activeItem = menuItem;
      if (menuItem == 'duplicate') {
        this.modalInfoOpen = false;
        this.modalDuplicateOpen = true;
      }
    },

    prev() {
      this.$emit('onPrev');
      // this.activeTab = 'informations-generales'
    },

    next() {
      this.$emit('onNext');
      // this.activeTab = 'informations-generales'
    },
    returnModal() {
      this.modalDuplicateOpen = false;
      this.modalInfoOpen = true;
    },
  },

  props: {
    context: {
      type: Object,
    },
    user: {
      type: Object,
      default: () => {
        return {};
      },
    },

    modalIsOpen: {
      type: Boolean,
      default: false,
    },
    areNextPrevDisabled: {
      type: Boolean,
      default: true,
    },
    allUser: {
      type: Array,
      default: () => [],
    },
    showFusion: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog--fullscreen {
  background-color: #fff;
  .row.moncompte__layout.no-gutters {
    height: 100vh;
  }
}

.overflow-scroll {
  overflow: scroll !important;
}
.navigation__back-button {
  background-color: #f5f5f5;
  margin: 0;
  .col {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.navigate__prev,
.navigate__next {
  width: 20px;
  height: 20px;
  background: transparent;
  position: relative;
  cursor: pointer;

  &:before {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -10px;
    content: '';
    transform: scale(2);
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid white;
  }
}
.navigate__next {
  margin-left: 10px;
  &:before {
    left: 10px;
    border-left: 10px solid white;
    border-right: 10px solid transparent;
  }
}
.user-details__form-container {
  .form-user-details__loading-container {
    .ffme__loading {
      margin: -30px;
    }
  }
}

.cardCustom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 0px;

  position: static;
  width: 200px;
  height: 200px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border: 0.5px solid #dfe5f9;
  box-sizing: border-box;
  /* Shadow/Royal */

  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 11px;
}

.cardText {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  /* or 143% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Primary/Dark */

  color: #000000;
}

a {
  color: #000;
}
a:hover {
  text-decoration: none;
}
// car tex
.card-item {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 26%;
  height: 170px;
  background: #ffffff;
  border: 0.5px solid #dfe5f9;
  box-sizing: border-box;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  margin: 0px 11px;
}
.cart-text {
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000000;
  text-transform: uppercase !important;
}
.bottom-layer {
  background: #b8cefa;
  height: 6px;
  width: 100%;
  transition: 0.3ms ease;
}
.bottom-layer-2 {
  position: absolute;
  bottom: -6px;
  left: 0px;
  right: 0px;
  background: #b8cefa;
  height: 8px;
  width: 100%;
}
.active-item {
  border-bottom: 8px solid #b8cefa;
}
.subbar {
  height: 64px !important;
}
// left layout style
.left-layout {
  display: flex;
  flex-direction: column;
  background: #e7effd;
  min-width: 370px;
}
.layout-left-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;
}
.layout-left-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.layout-left- {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
</style>
