<template>
  <v-btn
    v-on="$listeners"
    v-bind="$attrs"
    class="base-btn"
    :class="`btn-type-${btnType}`"
    :disabled="disabled"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    btnType: {
      type: String,
      default: () => "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-btn {
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  padding: 20px !important;
  text-transform: initial;
  color: #ffffff;
}
.btn-type-primary {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
.btn-type-disabled {
  opacity: 0.6 !important;
}
</style>
