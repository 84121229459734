var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.getClassTable},[(!_vm.mounted)?_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"loading":true,"boilerplate":false,"type":"table"}}):_c('div',[_c('div',{},[(_vm.simpleSearch)?_c('div',{staticClass:"mb-1 d-flex col-9 align-center"},[_c('inputText',{staticClass:"w-75",attrs:{"append-outer-icon":"mdi-magnify","placeholder":"Rechercher : numéro de licence, nom, prénom"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-magnify")]),_c('div',[_c('button',{on:{"click":_vm.refresh}},[_c('v-icon',{staticClass:"icon"},[_vm._v("mdi-refresh")])],1)])],1):_vm._e(),(_vm.actionsContains('editLicenceMultiple'))?_c('div',{staticClass:"py-4 px-4",staticStyle:{"background":"rgba(230, 230, 230, 1)"}},[_c('v-btn',{attrs:{"disabled":!_vm.currentItemsSelected.length || !_vm.hasCodeFederal,"small":"","rounded":"","color":"blue-dark text-white font-weight-bold"},on:{"click":_vm.openEditLicenceModal}},[_vm._v(" Renouveler les licences sélectionnées ")]),(!_vm.usersHasLicence)?_c('span',[_c('v-btn',{attrs:{"small":"","rounded":"","color":"blue-dark text-white font-weight-bold ml-2"},on:{"click":_vm.openCopyLicenceModal}},[_vm._v(" Copier les licences de la saison précedente ")]),(_vm.modalCopyLicenceIsOpen)?_c('modalLicenceCopy',{attrs:{"context":_vm.context,"users":_vm.membres,"modalIsOpen":_vm.modalCopyLicenceIsOpen},on:{"onUpdate":_vm.onUpdateLicence,"onClose":_vm.closeCopyLicenceModal}}):_vm._e()],1):_vm._e(),(_vm.modalEditLicenceIsOpen)?_c('modalLicence',{attrs:{"context":_vm.context,"itemsSelected":_vm.currentItemsSelected,"modalIsOpen":_vm.modalEditLicenceIsOpen,"licenceCount":_vm.currentItemsSelected.length},on:{"onUpdate":_vm.onUpdateLicence,"onClose":_vm.closeEditLicenceModal}}):_vm._e()],1):_vm._e()]),_c('v-data-table',{key:_vm.tableKey,class:{
        'filters-opened': _vm.areFiltersOpened,
        'elevation-1 list__table': _vm.scrollTable,
      },attrs:{"headers":_vm.headers,"fixed-header":"","height":_vm.tableHeight,"loading":_vm.loading || _vm.isLoading,"items":_vm.filteredMembres,"item-key":"id","show-select":_vm.show_select,"singleSelect":false,"custom-sort":_vm.customSort,"footer-props":{
        'items-per-page-options': _vm.perPageOption,
      },"hide-default-footer":_vm.viewFooter,"item-class":_vm.rowClass},on:{"item-selected":_vm.itemSelected,"update:options":_vm.optionUpdated,"input":_vm.itemListUpdated},scopedSlots:_vm._u([{key:"item.Select",fn:function(ref){
      var item = ref.item;
return [(_vm.isInIDFuseState)?_c('div',[_c('v-checkbox',{staticStyle:{"width":"25px !important"},attrs:{"input-value":"false","off-icon":"mdi-plus-thick","on-icon":"mdi-minus-thick","background-color":"#082b6e","color":"white","value":"","dense":""},on:{"change":function (event) { return _vm.banUser(item, event); }}})],1):_c('div',[(_vm.initalCheckbox)?_c('v-checkbox',{attrs:{"input-value":"true","value":_vm.initalCheckbox,"disabled":_vm.isDisabled(item),"dense":""},on:{"change":function($event){return _vm.addUser(item)}}}):_vm._e()],1)]}},{key:"item.LicenceNumero",fn:function(ref){
      var item = ref.item;
return [_c('LicenceNumero',{attrs:{"item":item}})]}},{key:"item.user.licenceNumero",fn:function(ref){
      var item = ref.item;
return [(item.user.licenceNumero)?_c('v-chip',{attrs:{"color":"blue","small":"","dark":""}},[_vm._v(" "+_vm._s(item.user.licenceNumero)+" ")]):_c('v-chip',{attrs:{"color":"gray","x-small":"","dark":""}},[_vm._v("NC")])]}},{key:"item.DN_DateNaissance",fn:function(ref){
      var item = ref.item;
return [_c('DateDeNaissance',{attrs:{"item":item}})]}},{key:"item.user.dnDateNaissance",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.user.dnDateNaissance).format("DD/MM/YYYY"))+" ")]}},{key:"item.structure.nomStructure",fn:function(ref){
      var item = ref.item;
return [_c('NomStructure',{attrs:{"item":item}})]}},{key:"item.CT_Nom",fn:function(ref){
      var item = ref.item;
return [_c('CTNom',{attrs:{"item":item}})]}},{key:"item.CT_Prenom",fn:function(ref){
      var item = ref.item;
return [_c('CTPrenom',{attrs:{"item":item}})]}},{key:"item.CT_Email",fn:function(ref){
      var item = ref.item;
return [(item.UTI_Utilisateur.CT_Email)?_c('div',[_vm._v(" "+_vm._s(item.UTI_Utilisateur.CT_Email)+" ")]):_c('v-chip',{attrs:{"color":"gray","x-small":"","dark":""}},[_vm._v("NC")])]}},{key:"item.FullName",fn:function(ref){
      var item = ref.item;
return [(item.UTI_Utilisateur.CT_Prenom || item.UTI_Utilisateur.CT_Nom)?_c('div',[(item.UTI_Utilisateur.CT_Nom)?_c('span',[_vm._v(" "+_vm._s(item.UTI_Utilisateur.CT_Nom))]):_vm._e(),(item.UTI_Utilisateur.CT_Prenom)?_c('span',[_vm._v(" "+_vm._s(item.UTI_Utilisateur.CT_Prenom))]):_vm._e()]):_c('v-icon',{attrs:{"color":"gray","close":""}},[_vm._v("mdi-close")])]}},{key:"item.EST_RoleFederal",fn:function(ref){
      var item = ref.item;
return [(item.EST_RoleFederal)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"color":"gray","close":""}},[_vm._v("mdi-close")])]}},{key:"item.ID_LicenceSaison",fn:function(ref){
      var item = ref.item;
return [_c('LicenceSaison',{attrs:{"item":item,"context":_vm.context}})]}},{key:"item.ID_Role",fn:function(ref){
      var item = ref.item;
return [(item.ID_Role)?_c('v-chip',{attrs:{"small":"","color":item.ID_Role == 1 ? 'green' : 'blue',"text-color":"white"}},[_vm._v(_vm._s(item.ADM_RoleType.NomRole))]):_c('v-chip',{attrs:{"color":"gray","x-small":"","dark":""}},[_vm._v("NC")])]}},{key:"item.ID_LicenceType",fn:function(ref){
      var item = ref.item;
return [_c('LicenceType',{attrs:{"item":item,"context":_vm.context,"saisonInput":_vm.saisonInput}})]}},{key:"item.Assurance",fn:function(ref){
      var item = ref.item;
return [_c('Assurance',{attrs:{"item":item,"context":_vm.context}})]}},{key:"item.Certificat",fn:function(ref){
      var item = ref.item;
return [_c('Certificat',{attrs:{"item":item,"context":_vm.context}})]}},{key:"item.ID_EtapeLicence",fn:function(ref){
      var item = ref.item;
return [(_vm.StatusLicence(item))?_c('div',[_c('v-chip',{attrs:{"color":_vm.colorStatusLicence(_vm.StatusLicence(item)),"small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.StatusLicence(item).EtapeValidationLicence.NomEtapeLicence)+" ")])],1):_c('v-chip',{attrs:{"color":"gray","x-small":"","dark":""}},[_vm._v("Aucune licence cette saison")])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[(_vm.actionsContains('show') && _vm.hasCodeFederal)?_c('ActionShowUser',{attrs:{"users":_vm.filteredMembres,"user":item,"allUser":_vm.membres,"context":_vm.context}}):_vm._e(),(_vm.showFusion)?_c('div',[_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","x-small":"","outlined":"","color":"blue"},on:{"click":function($event){return _vm.openComponent(item)}}},on),[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1)]}}],null,true)},[_c('span',[_vm._v("Sélectionner cet adhérent pour fusion")])])],1)]):_vm._e(),(_vm.actionsContains('addToStructure') && _vm.hasCodeFederal)?_c('ActionAddToStructure',{attrs:{"user":item,"context":_vm.context}}):_vm._e(),(
              (_vm.actionsContains('editRole') &&
                item.EST_RoleFederal &&
                _vm.isFFME) ||
              (_vm.actionsContains('editRole') &&
                item.EST_RoleFederal &&
                _vm.sameStructure(item))
            )?_c('ActionEditRoleModule',{attrs:{"user":item,"context":_vm.context},on:{"onUpdate":_vm.onUpdateRole}}):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [(
                  (!_vm.isConsultant &&
                    _vm.actionsContains('editRole') &&
                    item.EST_RoleFederal &&
                    _vm.isFFME) ||
                  (!_vm.isConsultant &&
                    _vm.actionsContains('editRole') &&
                    item.EST_RoleFederal &&
                    _vm.sameStructure(item))
                )?_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","x-small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.openConfirmationDelete(item)}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Supprimer le rôle fédéral")])]),(_vm.actionsContains('selectUser') && _vm.hasCodeFederal)?_c('ActionSelectUser',{attrs:{"user":item,"context":_vm.context},on:{"onSelected":_vm.onUserSelected}}):_vm._e(),(
              _vm.actionsContains('editLicence') &&
              _vm.hasCodeFederal &&
              !_vm.setItem(item)
            )?_c('ActionEditLicenceRow',{attrs:{"user":item,"context":_vm.context},on:{"onUpdate":_vm.onUpdateLicence}}):_vm._e(),(_vm.actionsContains('editLicenceSiege') && _vm.hasCodeFederal)?_c('ActionEditLicenceSiegeRow',{attrs:{"user":item,"context":_vm.context},on:{"onUpdate":_vm.onUpdateLicence}}):_vm._e()],1)]}}]),model:{value:(_vm.currentItemsSelected),callback:function ($$v) {_vm.currentItemsSelected=$$v},expression:"currentItemsSelected"}},[[_c('div',[_vm._v("type de licence")])]],2)],1),_c('YesNo',{attrs:{"showDialog":_vm.openModal,"title":"CONFIRMATION","text":'Souhaitez-vous supprimer le rattachement/rôle de ' +
      _vm.userFullName +
      ' de la structure ?'},on:{"answerYes":_vm.deleteUserFromStructure,"answerNo":function($event){_vm.openModal = false},"close":function($event){_vm.openModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }