<template>
  <div
    id="print-attestation"
    ref="print-attestation"
    style="
      z-index: -3;
      position: absolute;
      display: none;
      width: 1000px;
      padding: 2em;
    "
  >
    <div style="position: relative; width: 1000px; min-height: 1500px;">
      <img
        src="~@/assets/FFME_EN_TETE.jpg"
        alt=""
        style="position: absolute; top: -35px; width: 100%;"
      />
      <div
        style="
          font-family: Roboto !important;
          font-size: 16px !important;
          position: absolute;
          top: 100px;
          right: 40px;
          display: flex;
          flex-direction: column;
          align-content: center;
          width: 100% !important;
        "
      >
        <div class="d-flex flex-column align-center text-uppercase">
          <h4>Attestation de licence</h4>
          <h4>Saison {{ print.ID_Saison - 1 }}-{{ print.ID_Saison }}</h4>
        </div>
        <div style="width: 100%; margin-top: 150px !important;">
          <div class="user-infos d-flex justify-content-center">
            <div class="labels" style="width: 25%;">
              <p>Nom :</p>
              <p>Prénom :</p>
              <p>{{ civilite }}</p>
              <p>Club :</p>
              <p>Numéro :</p>
            </div>
            <div class="values" style="width: 35%;" v-if="print.user">
              <p class="font-weight-bold">{{ print.user.CT_Nom }}</p>
              <p class="font-weight-bold">{{ print.user.CT_Prenom }}</p>
              <p class="font-weight-bold">
                {{ $moment(print.user.DN_DateNaissance).format('DD/MM/YYYY') }}
              </p>
              <p class="font-weight-bold">
                <span class="font-weight-bold">
                  {{
                    print.licence &&
                    print.licence.structure &&
                    (
                      (print.licence.structure.idTypeStructure === 5 || print.licence.structure.idTypeStructure === 4) && print.licence.product.slug.includes('hors_club') ?
                      'Licence HORS CLUB' :
                      print.licence.structure.nomStructure
                    )
                  }}
                </span>
              </p>
              <p class="font-weight-bold">
                <span>{{ print.user.LicenceNumero }}</span>
              </p>
            </div>
          </div>
          <div class="certificat d-flex justify-content-center my-3">
            <p style="width: 25%;">
              Type de certificat médical :
            </p>
            <span class="font-weight-bold" style="width: 35%;">{{
              certifatsMedicaux
            }}</span>
          </div>
          <div class="assurance d-flex flex-column my-3">
            <div class="d-flex justify-content-center">
              <div class="labels" style="width: 25%;">
                <p>Assurance :</p>
                <p>Option(s) :</p>
              </div>
              <div class="values" style="width: 35%;">
                <p>
                  {{
                    (print.assurance &&
                    print.assurance.option &&
                    print.assurance.option.label) || '&nbsp;'
                  }}
                </p>
                <p>
                  {{ optionAssurances || '&nbsp;' }}
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-center my-3">
              <span style="width: 80%; margin-left: 200px;">
                {{ validDate }}
              </span>
            </div>
            <div class="d-flex justify-content-center my-3">
              <span style="width: 60%;">
                Attestation imprimée le {{ $moment().format('DD/MM/YYYY') }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex';
import { waitingDocument, loisir, competition, qs } from "@/constants/licenceStatus";
export default {
  data: () => ({
    printDisabled: false,
  }),
  props: {
    print: {
      type: Object,
    },
  },

  computed: {
    ...mapGetters('structure', ['currentStructure']),
    civilite() {
      if (!this.print.user) return '';
      return this.print.user?.CT_EST_Civilite === 1 ? 'Né le' : 'Née le';
    },
    certifatsMedicaux() {
      if (![waitingDocument, loisir, competition, qs].includes(this.print.cm)) {
        return this.oldCertificatsMedicaux;
      }

      switch (this.print.cm) {
        case qs:
          return 'Questionnaire de santé';
        case loisir:
          return 'Loisir';
        case competition:
          return 'Compétition';
        default:
          return ' - ';
      }
    },
    oldCertificatsMedicaux() {
      if (!this.print?.cm?.length) {
        return '-';
      }

      let certificats = '';

      const certificatMedicalTemp = this.print.cm.filter((cm) =>
        cm.LIST_DocumentType?.SlugTypeDocument.includes('certificat_medical')
      );

      const certificatMedical = certificatMedicalTemp.map((d) => {
        if (d.LIST_DocumentType.SlugTypeDocument === 'certificat_medical_competition') {
          const newLIST_DocumentType = {
            ...d.LIST_DocumentType,
            SlugTypeDocument: 'certificat_medical_compétition',
          };

          return {
            ...d,
            LIST_DocumentType: newLIST_DocumentType
          };
        }
        return d;
      });

      certificatMedical.forEach((element, i) => {
        if (element?.LIST_DocumentType?.SlugTypeDocument == 'certificat_medical_sport_sante') {
          certificats += 'Prescription sport-santé';
        } else {
          certificats += element?.LIST_DocumentType?.SlugTypeDocument.split('certificat_medical_').join('').split('_').join('-');
        }

        if (i !== certificatMedical.length - 1) {
          certificats += ' et ';
        }
      });

      if (certificats === 'Sport-sante') {
        certificats = 'Prescription sport-santé';
      }

      return certificats;
    },
    optionAssurances() {
      if (!this.print?.options?.length && !this.print?.complementaire?.length) {
        return null;
      }

      const options = [...this.print.options, ...this.print.complementaire];

      const optionName = options.map(
        (option) => option.option?.label
      );

      return optionName.join(', ');
    },
    validDate() {
      return `Valable depuis le ${this.$moment(
        this.print.licence?.dateStart
      ).format('DD/MM/YYYY')} jusqu'au ${this.$moment(
        this.print.licence?.dateEnd
      ).format('DD/MM/YYYY')} (sauf modification en cours de saison)`;
    },
  },
  methods: {
    printAttestation(name) {
      console.log('this.print rerer :>> ', this.print);
      // if(this.printDisabled) return error_notification("Il manque des informations à votre licence pour pouvoir l'imprimer.")
      this.$nextTick(async () => {
        this.$refs['print-attestation'].style.display = 'block';
        let source = document.querySelector('#print-attestation');
        await html2canvas(source, {
          letterRendering: 1,
          scale: 2,
          logging: true,
          useCORS: true,
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/jpeg');
          const pdf = new jsPDF({
            orientation: 'portrait',
          });
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
          pdf.save(name);
          this.$store.commit(
            'licences/SET_IS_PRINTING_ATTESTATION_LICENCE',
            false
          );
          this.$refs['print-attestation'].style.display = 'none';
        });
      });
    },
  },
};
</script>
//
<style lang="scss">
// // css new
// #print-attestation {
//   z-index: -3;
//   position: absolute;
//   display: none;
//   width: 1000px;
//   padding: 2em;
//   .habillage-container {
//     position: relative;
//     width: 1000px;
//     min-height: 1500px;
//     .habillage {
//       width: 100%;
//     }
//   }
// }
//
</style>
