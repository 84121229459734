<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_utilisateurs__dashboard"
      />
    </v-row>

    <demandesModifList
      :key="refreshTable"
      :context="{
        ID_Utilisateur: currentUserId,
        ID_UtilisateurDemande: currentAuthUserId,
        ID_Structure: currentStructureId,
        ID_Saison: selectedSaison.id,
        hasCodeFederal,
      }"
      :headers="headers"
      :demandesModifs="demandesModifs"
      :isFFME="isFFME"
      @onUpdated="updateDemandeModif"
    ></demandesModifList>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import demandesModifList from "@/components/User/List/User__ListDemandesModifications.vue";

export default {
  components: { SubBar, demandesModifList },

  data: () => ({
    headers: [
      { text: "Structure", value: "STR_Structure.NomStructure" },
      { text: "Numéro de licence", value: "UTI_Utilisateur.LicenceNumero" },
      { text: "Prénom", value: "UTI_Utilisateur.CT_Prenom" },
      { text: "Modif prénom", value: "Modif_CT_Prenom" },
      { text: "Nom", value: "UTI_Utilisateur.CT_Nom" },
      { text: "Modif nom", value: "Modif_CT_Nom" },
      { text: "Date de naissance", value: "UTI_Utilisateur.DN_DateNaissance" },
      { text: "Modif date de naissance", value: "Modif_DN_DateNaissance" },
      { text: "Sexe", value: "UTI_Utilisateur.CT_EST_Civilite" },
      { text: "Modif sexe", value: "Modif_CT_EST_Civilite" },
      { text: "Fiche utilisateur", value: "show" },
    ],
    demandesModifs: [],
    isFFME: false,
    refreshTable: 0,
  }),
  async created() {
    if (this.currentStructure.STR_Structure.ID_TypeStructure === 7)
      this.isFFME = true;
    this.demandesModifs = await this.getAllDemandesModif({
      ID_Structure: this.currentStructureId,
      isFFME: this.isFFME,
    });
    console.log("this.currentStructureId", this.currentStructureId);
    console.log(
      "🚀 ~ file: DemandesDeModification.vue ~ line 72 ~ this.demandesModifs",
      this.demandesModifs
    );
  },
  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
    ...mapGetters("saisons", ["selectedSaison"]),
    ...mapGetters("user", ["currentUserId"]),
    currentAuthUserId() {
      return this.$store.getters["auth/currentUserID"];
    },

    subBarTitle() {
      return "Gestion des demandes de modifications";
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getAllDemandesModif"]),
    async updateDemandeModif() {
      this.demandesModifs = await this.getAllDemandesModif({
        ID_Structure: this.currentStructureId,
        isFFME: this.isFFME,
      });

      this.refreshTable++;
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
