<template>
  <v-dialog
    v-if="modalIsOpen"
    v-model="modalIsOpen"
    @click:outside="closeModal"
    max-width="700px"
  >
    <v-tabs background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>

      <v-tab style="display: flex; flex-direction: column">
        <span style="font-size: 1.2rem">
          {{ modalTitle }}
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="closeModal">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <p class="m-0 px-2 pt-2 text-center">
          <span v-if="context.fromModal">
            Les modifications demandées seront validées par le siège national de
            la FFME avant affichage sur myFFME.
          </span>
          <span v-else>
            En cas de coquille ou d'erreur vous pouvez demander à modifier ces
            informations. Après vérification votre demande sera validée sous 2 à
            3 jours ouvrés par la FFME.
          </span>
        </p>

        <v-card flat>
          <div class="form-coordonnees-update__container">
            <ValidateForm
              @validated="submit"
              :class="{ 'w-100': true, 'height-100': true }"
            >
              <SimpleSelect
                v-model="editedInfos.Modif_CT_EST_Civilite"
                :items="listCivilite"
                label="Sexe"
                rules="required"
                @onChange="CiviliteChange"
              />
              <InputText
                v-model="editedInfos.Modif_CT_Nom"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>

              <InputText
                v-model="editedInfos.Modif_CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              ></InputText>
              <datePicker
                v-model="editedInfos.Modif_DN_DateNaissance"
                rules="date|required"
                label="Date de naissance"
                withIcon="false"
                @onUpdate="dateUpdate"
              />
              <div class="form-coordonnees-update__action">
                <v-btn
                  type="submit"
                  :loading="loading"
                  class="btn btn-primary mt-5"
                  :disabled="isDisabled()"
                  >Demande de modification</v-btn
                >
              </div>
            </ValidateForm>
          </div>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import InputText from '@/components/utils/input.vue';
import SimpleSelect from '@/components/utils/select.vue';
import ValidateForm from '@/components/utils/form.vue';
import { listDeCivilite } from '@/plugins/utils';
import { mapGetters, mapActions } from 'vuex';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import {
  formatName,
} from "@/plugins/userService";

export default {
  data: () => ({
    loading: false,
    mounted: false,
    listCivilite: [],
    listPays: [],
    editedInfos: {
      Modif_CT_EST_Civilite: null,
      Modif_CT_Nom: null,
      Modif_CT_Prenom: null,
      Modif_DN_DateNaissance: null,
      ID_Utilisateur: null,
      ID_Structure: null,
      ID_UtilisateurDemande: null,
    },
    isSafari: false,
  }),
  components: {
    ValidateForm,
    SimpleSelect,
    InputText,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ '@/components/utils/datePicker.vue'
      ),
  },

  async created() {
    this.setData(this.userInfos);
    this.listCivilite = listDeCivilite();
    this.listPays = await this.getListPays();
    this.mounted = true;
    console.log('this.currentStructure ====>', this.currentStructure);
  },
  computed: {
    modalTitle() {
      if (this.context.fromModal) {
        return 'Modifier les informations';
      }
      return 'Modifier mes  coordonnées';
    },

    isFFME() {
      return this.currentStructure?.STR_Structure.id === 1318;
    },

    ...mapGetters('basics', ['getList']),
    ...mapGetters('structure', ['currentStructure']),
    ...mapGetters('user', ['currentUser', 'currentUserId', 'getRole']),
  },

  methods: {
    test(val) {
      this.safariDate = val;
    },
    update() {
      // console.log(this.editedInfos);
    },
    ...mapActions('basics', ['getListPays']),
    ...mapActions('utilisateurs', ['sendModifDemande', 'updateImmediatlyUser']),
    closeModal() {
      this.$emit('onClose');
    },

    dateUpdate(date) {
      this.editedInfos.Modif_DN_DateNaissance = date;
      // console.log( "onUpdate", this.editedInfos.Modif_DN_DateNaissance)
    },

    isDisabled() {
      if (
        this.editedInfos.Modif_CT_EST_Civilite !== this.userInfos.CT_EST_Civilite ||
        this.editedInfos.Modif_CT_Nom !== this.userInfos.CT_Nom ||
        this.editedInfos.Modif_CT_Prenom !== this.userInfos.CT_Prenom ||
        this.editedInfos.Modif_DN_DateNaissance !== this.userInfos.DN_DateNaissance
      ) {
        return false;
      }

      return true;
    },

    setData(user) {
      let {
        CT_EST_Civilite,
        CT_Nom,
        CT_Prenom,
        DN_DateNaissance,
        id,
      } = user;
      this.editedInfos.Modif_CT_EST_Civilite = CT_EST_Civilite;
      this.editedInfos.Modif_CT_Nom = CT_Nom;
      this.editedInfos.Modif_CT_Prenom = CT_Prenom;
      // La date au format DD/MM/YYYY
      this.editedInfos.Modif_DN_DateNaissance = DN_DateNaissance;
      this.editedInfos.ID_Utilisateur = id;
      this.editedInfos.ID_Structure = this.context.ID_Structure;
      this.editedInfos.ID_UtilisateurDemande = this.context.ID_UtilisateurDemande;

      if (this.adminMode) {
        this.editedInfos.EST_Demande_Validee = true;
        this.editedInfos.ID_UtilisateurExpeFFME = this.context.ID_UtilisateurDemande;
        this.editedInfos.Z_DateConfirmationExpeFFME = new Date();
      }
      // console.log(" this.editedInfos:", this.editedInfos);
    },

    onConfirm() {
      this.$emit('onConfirm');
    },

    async submit() {
      this.loading = true;

      if (this.isDisabled()) {
        this.loading = false;
        return;
      }

      if (this.isFFME) {
        let paylod = {
          CT_EST_Civilite: parseInt(this.editedInfos.Modif_CT_EST_Civilite),
          CT_Nom: this.editedInfos.Modif_CT_Nom,
          CT_Prenom: this.editedInfos.Modif_CT_Prenom,
          DN_DateNaissance: this.editedInfos.Modif_DN_DateNaissance,
        };

        paylod.CT_Nom = formatName(paylod.CT_Nom, 'lastname');
        paylod.CT_Prenom = formatName(paylod.CT_Prenom, 'firstname');

        if (paylod.CT_NomDeNaissance) {
          paylod.CT_NomDeNaissance = formatName(paylod.CT_NomDeNaissance, 'lastname');
        }

        try {
          await this.updateImmediatlyUser({
            data: { ...paylod },
            ID_Utilisateur: this.editedInfos.ID_Utilisateur,
          });
          success_notification('Profil mis à jour');
          this.loading = false;
        } catch (e) {
          error_notification('Oups, une erreur est survenue...');
          console.log(e);
          this.loading = false;
        }
      } else {
        try {
          this.editedInfos.Modif_CT_Nom = formatName(this.editedInfos.Modif_CT_Nom, 'lastname');
          this.editedInfos.Modif_CT_Prenom = formatName(this.editedInfos.Modif_CT_Prenom, 'firstname');

          await this.sendModifDemande({ editedUser: this.editedInfos });

          success_notification('Votre demande de modification sera vérifiée dans les meilleurs délais');
          this.loading = false;
          this.$emit('onClose');
          return;
        } catch (e) {
          error_notification('Oups, une erreur est survenue...');
          console.log(e);
          this.loading = false;
        }
      }
    },
    CiviliteChange(item) {
      this.editedInfos.Modif_CT_EST_Civilite = item.value;
    },
  },

  props: {
    modalIsOpen: {
      type: Boolean,
      default: false,
    },

    context: {
      type: Object,
    },

    userInfos: {
      type: Object,
    },
    adminMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.form-coordonnees-update__container {
  padding: 1em;
  .success-message {
    color: green;
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
  }

  .form-coordonnees-update__action {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
